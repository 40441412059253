import { styled } from '@mui/material/styles';
import { Toolbar } from 'react-admin';

import { appColors } from '../../../themes/variables';

export const StyledToolbar = styled(Toolbar)({
  backgroundColor: appColors.background,
  minHeight: '0 !important',
  marginBottom: '24px',
});
