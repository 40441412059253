import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { appColors } from 'themes/variables';
import { getColumnStyle } from 'utils';

export const StyledDatagrid = styled(Datagrid)({
  '& .RaDatagrid-expandIconCell': {
    borderRight: `1px solid ${appColors.divider}`,
    width: '44px',
    verticalAlign: 'middle',
    textAlign: 'center',
  },
  '& .RaDatagrid-expandedPanel .MuiTableCell-body': {
    backgroundColor: grey[100],
    padding: 0,
  },
  '& thead': {
    display: 'none',
  },
  '& .column-direction': getColumnStyle(200),
  '& .MuiTableCell-root': {
    verticalAlign: 'middle',
  },
});
