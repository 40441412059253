import { RaRecord } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Tooltip } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';

import { StyledTypography } from './TooltipTextField.styled';
import { FunctionField, FunctionFieldProps } from '../FunctionField/FunctionField';

export type TooltipTextFieldProps<T extends RaRecord = RaRecord> = {
  onClick?: (record?: T) => void;
  source: keyof T;
  value?: string;
} & Omit<FunctionFieldProps, 'render' | 'onClick'>;

export const TooltipTextField = <T extends RaRecord = RaRecord>({
  onClick,
  source,
  sx,
  value,
  ...rest
}: TooltipTextFieldProps<T>) => (
  <FunctionField
    render={(record: T) => {
      return (
        <Box onClick={() => onClick?.(record)}>
          <Tooltip title={<Typography fontSize={12}>{value || record[source]}</Typography>}>
            <StyledTypography sx={sx}>{value || record[source]}</StyledTypography>
          </Tooltip>
        </Box>
      );
    }}
    {...rest}
  />
);
