import { useMemo } from 'react';

import { formatInTimeZone } from 'date-fns-tz';
import { useGetOne, useRecordContext } from 'react-admin';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { Currency, Order, OrderStatus } from 'types';

export const useGetOrderCopyMainData = () => {
  const record = useRecordContext<Order>();

  const { data: currency } = useGetOne<Currency>(
    'currencies',
    { id: record?.currency as string },
    { enabled: !!record },
  );
  const { data: direction } = useGetOne(
    'directions',
    { id: record?.direction },
    { enabled: !!record },
  );
  const { data: status } = useGetOne<OrderStatus>(
    'new/order_statuses',
    { id: record?.status },
    { enabled: !!record },
  );

  const valuesMap: Record<string, { label: string; value: Order[keyof Order] | null | undefined }> =
    useMemo(() => {
      return {
        originId: {
          label: 'ID Kubera',
          value: record.originId,
        },
        merchantReference: {
          label: 'ID Мерчанта',
          value: record.merchantReference,
        },
        providerReferenceId: {
          label: 'ID провайдера',
          value: record.providerReferenceId,
        },
        createdAt: {
          label: 'Время создания',
          value: record?.createdAt
            ? formatInTimeZone(record.createdAt, DEFAULT_TIMEZONE, 'dd/MM/yyyy HH:mm')
            : null,
        },
        status: {
          label: 'Статус',
          value: status?.description,
        },
        amount: {
          label: 'Сумма',
          value: record?.amount,
        },
        currency: {
          label: 'Валюта',
          value: currency?.alphaCode,
        },
        direction: {
          label: 'Направление',
          value: direction?.description,
        },
      };
    }, [currency, direction, status, record]);

  const copyData = record
    ? Object.values(valuesMap).reduce((acc, { value, label }) => {
        return value ? acc + `${label}: ${value}\n` : acc;
      }, '')
    : '';

  return { copyData };
};
