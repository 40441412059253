import { useFilters } from 'hooks/useFilters';
import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { Filter, FilterFieldGroup, FilterRow } from 'shared/mui/FilterNew';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';

type MethodsLogosFilterValues = { name: string };

export const MethodLogosFilters: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<MethodsLogosFilterValues>({
    name: '',
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Filter>
          <FilterRow>
            <FilterFieldGroup>
              <TextInputShrink
                fullWidth
                helperText={false}
                key="name"
                label="Название"
                name="name"
                source="name"
                sx={{ marginBottom: 0 }}
                variant="outlined"
              />
            </FilterFieldGroup>
            <FilterActions sx={{ marginTop: '2px' }}>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};
