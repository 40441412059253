import { usePermissions } from 'hooks/usePermissions';
import { CreateButton } from 'shared/mui/Buttons';
import { ExportButton } from 'shared/react-admin/Buttons';
import { ListPage } from 'shared/react-admin/Pages';
import { Permission } from 'types';

import { UsersListContent } from './UsersListContent';

export const UsersListNew: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('users', [Permission.Full, Permission.Create]) && (
          <CreateButton key="create-button" />
        ),
        <ExportButton key="export-button" />,
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Пользователи',
      }}
      listBoxProps={{ sx: { maxHeight: '100%' } }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <UsersListContent />
    </ListPage>
  );
};
