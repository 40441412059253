import { createContext, useContext } from 'react';

type DatagridActionsContextData = {
  onCloseMenu: VoidFunction;
};

export const DatagridActionsContext = createContext<DatagridActionsContextData>({
  onCloseMenu: () => undefined,
});
export const DatagridActionsContextProvider = DatagridActionsContext.Provider;

export function useDatagridActionsContext(): DatagridActionsContextData {
  const context = useContext(DatagridActionsContext);

  if (!context) {
    throw new Error(
      'Can not `useDatagridActionsContext` outside of the `DatagridActionsContextProvider`',
    );
  }

  return context;
}
