import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { Stack } from 'shared/mui/Stack/Stack';
import { appColors } from 'themes/variables';

type Props = {
  periodFrom: number;
  periodTo: number;
  timezone?: string;
};

export const CumulativeReportPeriodDateField: React.FC<Props> = ({
  periodTo,
  periodFrom,
  timezone,
}) => {
  return (
    <Stack alignItems="start" direction="column" flexWrap="nowrap">
      <span>
        {formatInTimeZone(fromUnixTime(periodFrom), timezone || DEFAULT_TIMEZONE, 'dd/MM/yyyy')}
      </span>
      <span style={{ whiteSpace: 'nowrap', color: appColors.text.disabled }}>
        {formatInTimeZone(fromUnixTime(periodFrom), timezone || DEFAULT_TIMEZONE, 'HH:mm') +
          ' — ' +
          formatInTimeZone(fromUnixTime(periodTo), timezone || DEFAULT_TIMEZONE, 'HH:mm')}
      </span>
    </Stack>
  );
};
