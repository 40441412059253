import { LinearProgress } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useGetOne } from 'react-admin';
import { Typography } from 'shared/mui/Typography';
import { Currency, Order } from 'types';
import { OrderAdjustments } from 'types/orderAdjustments';
import { formatCurrency } from 'utils';

type Props = {
  record: OrderAdjustments;
};

export const AdjustmentAmountFieldContent: React.FC<Props> = ({ record }) => {
  const { data: order, isLoading: isLoadingOrder } = useGetOne<Order>('orders', {
    id: record.order,
  });
  const { data: currency, isLoading: isLoadingCurrency } = useGetOne<Currency>(
    'currencies',
    { id: order?.currency || '' },
    {
      enabled: Boolean(order?.currency),
    },
  );

  if (isLoadingOrder || isLoadingCurrency) {
    return <LinearProgress />;
  }

  if (!record.newAmount && record.oldAmount) {
    return (
      <Typography variant="body2">
        {formatCurrency(record.oldAmount ?? 0)}{' '}
        <span style={{ color: blue[700] }}>{currency?.alphaCode}</span>
      </Typography>
    );
  }
  return (
    <div>
      <Typography color="green" variant="body2">
        {formatCurrency(record.newAmount ?? 0)} {currency?.alphaCode}
      </Typography>
      <Typography color="secondary" sx={{ textDecoration: 'line-through' }} variant="body2">
        {formatCurrency(record.oldAmount ?? 0)}{' '}
        <span style={{ color: blue[700] }}>{currency?.alphaCode}</span>
      </Typography>
    </div>
  );
};
