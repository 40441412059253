import { styled } from '@mui/material/styles';
import { DatagridConfigurableWithStickyActions } from 'shared/react-admin/Datagrids';

import { getColumnStyle } from '../../utils';

const defaultStyle120 = getColumnStyle(120);
const defaultStyle168 = getColumnStyle(168);
const defaultStyle264 = getColumnStyle(264);
const defaultStyle280 = getColumnStyle(280);

export const DatagridStyled = styled(DatagridConfigurableWithStickyActions)({
  '& .column-originId': getColumnStyle(88),
  '& .column-adjustmentType': defaultStyle264,
  '& .column-impactToBalance': defaultStyle264,
  '& .column-adjustmentStatus': getColumnStyle(146),
  '& .column-order': defaultStyle168,
  '& .direction': getColumnStyle(132),
  '& .column-rejectReason': defaultStyle280,
  '& .column-change-status': defaultStyle280,
  '& .column-user': defaultStyle280,
  '& .column-merchant': defaultStyle168,
  '& .column-provider': defaultStyle168,
  '& .column-gateway': defaultStyle168,
  '& .column-method': defaultStyle168,
  '& .column-createdAt': defaultStyle120,
  '& .column-updatedAt': defaultStyle120,
  '& .column-amount': { ...getColumnStyle(172), textAlign: 'right' },
});
