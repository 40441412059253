import { useShowController } from 'react-admin';
import { TypographyTitle } from 'shared/mui/Typography';
import { FunctionField, OrderStatusChipField } from 'shared/react-admin/Fields';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';
import { Currency, Order } from 'types';
import { formatCurrency } from 'utils';

import { CardHeaderStyled, StyledBox } from './OrderShowCommonInfoStateCardHeader.styled';
import { OrderShowCommonInfoStateCardHeaderActions } from './OrderShowCommonInfoStateCardHeaderActions';

export const OrderShowCommonInfoStateCardHeader = (): JSX.Element => {
  const { record: order } = useShowController<Order>();
  return (
    <CardHeaderStyled>
      <StyledBox>
        <ReferenceField link={false} reference="currencies" source="currency">
          <FunctionField
            render={(record: Currency) => (
              <TypographyTitle size="small" sx={{ fontWeight: 700, mr: '8px' }}>
                {formatCurrency(order?.amount || 0)} {record?.alphaCode}
              </TypographyTitle>
            )}
          />
        </ReferenceField>
        <ReferenceField link={false} reference="new/order_statuses" source="status">
          <OrderStatusChipField source="description" />
        </ReferenceField>
      </StyledBox>
      <OrderShowCommonInfoStateCardHeaderActions />
    </CardHeaderStyled>
  );
};
