import { formatInTimeZone } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField } from 'shared/react-admin/Fields';
import { Order } from 'types';

import { EMPTY_FIELD } from '../../../../../constants';

export const OrderShowCommonInfoStateDateTimeField = (): JSX.Element => {
  return (
    <FunctionField
      render={(record: Order) => {
        if (!record.updatedAt) {
          return EMPTY_FIELD;
        }
        const date = new Date(record.updatedAt);
        return (
          <Stack direction="row" gap="8px">
            <Typography variant="body2">{`${formatInTimeZone(date, DEFAULT_TIMEZONE, 'dd.MM.yyyy')}`}</Typography>
            <Typography variant="body2">
              {`${formatInTimeZone(date, DEFAULT_TIMEZONE, 'HH:mm:ss')}`}
            </Typography>
          </Stack>
        );
      }}
    />
  );
};
