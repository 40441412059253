import { useQuery } from 'react-query';

import { getCommissionsAvailableActions } from '../../../config/requests';

export const useCommissionsAvailableActions = (id: number | undefined) => {
  const { data, isLoading } = useQuery(
    ['commissions/available_actions', id],
    () => getCommissionsAvailableActions(id!),
    {
      enabled: Boolean(id),
    },
  );

  return { data, isLoading };
};
