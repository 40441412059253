export const EXPORT_FIELDS_ADJUSTMENTS_REPORT = [
  {
    index: '0',
    source: 'id',
    label: 'ID',
  },
  {
    index: '1',
    source: 'order',
    label: 'Заказ',
  },
  {
    index: '2',
    source: 'adjustmentType',
    label: 'Тип корректировки',
  },
  {
    index: '3',
    source: 'adjustmentStatus',
    label: 'Статус',
  },
  {
    index: '4',
    source: 'impactToBalance',
    label: 'Движение по балансу',
  },
  {
    index: '5',
    source: 'comment',
    label: 'Комментарий',
  },
  {
    index: '6',
    source: 'rejectReason',
    label: 'Причина отказа',
  },
  {
    index: '7',
    source: 'oldAmount',
    label: 'Старая сумма',
  },
  {
    index: '8',
    source: 'newAmount',
    label: 'Новая сумма',
  },
  {
    index: '9',
    source: 'oldStatus',
    label: 'Старый статус',
  },

  {
    index: '10',
    source: 'newStatus',
    label: 'Новый статус',
  },
  {
    index: '11',
    source: 'user',
    label: 'Автор',
  },
  {
    index: '12',
    source: 'createdAt',
    label: 'Дата проведения',
  },
  {
    index: '13',
    source: 'updatedAt',
    label: 'Дата обновления',
  },
  {
    index: '14',
    source: 'customerId',
    label: 'Клиент',
  },
  {
    index: '15',
    source: 'direction',
    label: 'Направление',
  },
  {
    index: '16',
    source: 'merchant',
    label: 'Мерчант',
  },
  {
    index: '17',
    source: 'provider',
    label: 'Провайдер',
  },
  {
    index: '18',
    source: 'gateway',
    label: 'Шлюз',
  },
  {
    index: '19',
    source: 'method',
    label: 'Метод',
  },
];
