import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ButtonProps } from '@mui/material';
import { Button } from 'shared/mui/Buttons';

export const FilterReferenceButton: React.FC<ButtonProps> = (props) => (
  <Button
    {...props}
    color="secondary"
    label="справка"
    size="small"
    startIcon={<InfoOutlinedIcon />}
    variant="text"
  />
);
