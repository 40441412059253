import { styled } from '@mui/material/styles';
import {
  SelectColumnsButton as RaSelectColumnsButton,
  SelectColumnsButtonProps as RaSelectColumnsButtonProps,
} from 'react-admin';
import { appColors } from 'themes/variables';

export type SelectColumnsButtonProps = RaSelectColumnsButtonProps;
export const SelectColumnsButton = styled(RaSelectColumnsButton)({
  '&.MuiButtonBase-root': {
    color: appColors.secondary.main,
  },
});
