import { RecordContextProvider } from 'react-admin';
import { CardHeader } from 'shared/mui/Card';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { StyledBox } from './CompanyShowDialog.styled';
import { CompanyShowDialogTable } from './CompanyShowDialogTable';
import { Company } from '../../../types';

type CompanyShowDialogProps = {
  company: Company;
  onClose: VoidFunction;
} & DialogProps;

export const CompanyShowDialog = ({
  open,
  company,
  onClose,
}: CompanyShowDialogProps): JSX.Element => (
  <Dialog maxWidth="md" onClose={onClose} open={open}>
    <StyledBox>
      <CardHeader
        actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
        title="Просмотр компании"
      />
      <RecordContextProvider value={company}>
        <CompanyShowDialogTable
          onClose={onClose}
          sx={{ marginBottom: '48px', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
        />
      </RecordContextProvider>
    </StyledBox>
  </Dialog>
);
