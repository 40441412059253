import * as yup from 'yup';

const oneOfNot: yup.TestFunction = (_, context) => {
  return (
    !!context.parent.max || !!context.parent.min || !!context.parent.percent || !!context.parent.fix
  );
};

const percentField = yup
  .number()
  .typeError('Введите число')
  .when('zeroed', {
    is: false,
    then: (schema) =>
      schema
        .min(0)
        .max(100)
        .nullable()
        .test('one-of-not', 'Одно из значений должно быть заполнено', oneOfNot),
    otherwise: (schema) => schema.nullable(),
  });

const currencyField = yup
  .number()
  .typeError('Введите число')
  .when('zeroed', {
    is: false,
    then: (schema) =>
      schema
        .min(0)
        .nullable()
        .test('one-of-not', 'Одно из значений должно быть заполнено', oneOfNot),
    otherwise: (schema) => schema.nullable(),
  });

const minCurrencyField = yup
  .number()
  .typeError('Введите число')
  .when('zeroed', {
    is: false,
    then: (schema) =>
      schema
        .min(0)
        .when('max', {
          is: (value: number) => !!value,
          then: (schema) =>
            schema
              .lessThan(
                yup.ref<number>('max'),
                'Значение "MIN" не может быть больше значения "MAX"',
              )
              .notOneOf(
                [yup.ref<number>('max')],
                'Значение "MIN" не может быть равно значению "MAX"',
              ),
        })
        .nullable()
        .test('one-of-not', 'Одно из значений должно быть заполнено', oneOfNot),
    otherwise: (schema) => schema.nullable(),
  });

const startDateField = yup
  .number()
  .when('endDate', {
    is: (value: number) => !!value,
    then: (schema) =>
      schema.lessThan(
        yup.ref<number>('endDate'),
        'Значение "Период активности от" не может быть больше значения "Период активности до"',
      ),
  })
  .nullable();

export const COMMISSIONS_SCHEMA = yup.object().shape({
  max: currencyField,
  min: minCurrencyField,
  startDate: startDateField,
  percent: percentField,
  fix: currencyField,
  merchant: yup.string().required('Обязательное поле'),
  zeroed: yup.boolean(),
  name: yup.string().required('Обязательное поле'),
  provider: yup.string().required('Обязательное поле'),
  transactionTypes: yup.array().of(yup.string()).min(1, 'Обязательное поле').required(),
  currency: yup.string().required('Обязательное поле'),
  originId: yup.number().notRequired(),
});
