import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

export const StyledDatagrid = styled(Datagrid)({
  border: 'none',
  '& .MuiTableCell-root': { padding: '8px 16px', verticalAlign: 'middle' },
  '& .MuiTableCell-head': {
    verticalAlign: 'middle',
    padding: '4px 16px',
    backgroundColor: grey[50],
  },
  '& .column-originId': getColumnStyle(120),
  '& .column-createdAt': getColumnStyle(120),
  '& .column-type': getColumnStyle(136),
  '& .column-amount': { ...getColumnStyle(136), textAlign: 'right' },
  '& .column-currency': getColumnStyle(88),
  '& .column-commissions': getColumnStyle(240),
  '& .column-commissionValueByOrder': { ...getColumnStyle(168), textAlign: 'right' },
  '& .column-commissionValueByRule': { ...getColumnStyle(168), textAlign: 'right' },
});
