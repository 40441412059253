import { EMPTY_FIELD } from 'constants/emptyField';

import * as React from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { RaRecord, useRecordContext } from 'react-admin';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import {
  BooleanField,
  ChipField,
  DateTimeField,
  FunctionField,
  NumberField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import {
  ReferenceArrayField,
  ReferenceField,
  ReferenceOneField,
} from 'shared/react-admin/ReferenceFields';

import {
  FileHeaderStyled,
  TableCellStyled,
  TableRowCommentsStyled,
  TableRowStyled,
} from './CommissionsShowTable.styled';
import { CommissionsListComment } from '../../../components/Commissions/CommissionsListNew/CommissionsListComment/CommissionsListComment';
import { Commission } from '../../../types';
import { CommissionStatusChipField } from '../../react-admin/Fields/CommissionStatusChipField/CommissionStatusChipField';
import { FileField } from '../../react-admin/Fields/FileField/FileField';

const DATE_SX = { padding: '0 16px' };
const HEADER_SX = { fontWeight: 'initial', fontSize: '16px', paddingTop: '24px' };

export const CommissionsShowTable = () => {
  const { hasResourceAccess } = usePermissions();
  const record = useRecordContext<Commission>();

  return (
    <CardTable
      sx={{
        tableLayout: 'fixed',
      }}
    >
      <TableBody>
        <TableRowWrapper headerStyle={HEADER_SX} title="Основные настройки:" />
        <TableRowWrapper title="ID">
          <TextField label="Id" source="originId" />
        </TableRowWrapper>
        <TableRowWrapper title="Название">
          <TextField label="Название" source="name" />
        </TableRowWrapper>
        <TableRowWrapper title="Статус">
          <ReferenceField
            emptyText={EMPTY_FIELD}
            link={false}
            reference="commission_statuses"
            source="status"
          >
            <CommissionStatusChipField source="description" />
          </ReferenceField>
        </TableRowWrapper>
        {hasResourceAccess('merchants') && (
          <TableRowWrapper title="Мерчант">
            <ReferenceOneField
              emptyText={EMPTY_FIELD}
              link="show"
              reference="merchants"
              source="merchant"
              target="id"
            >
              <TextField source="name" />
            </ReferenceOneField>
          </TableRowWrapper>
        )}
        {hasResourceAccess('providers') && (
          <TableRowWrapper title="Провайдер">
            <ReferenceOneField
              label="Провайдер"
              reference="providers"
              source="provider"
              target="id"
            >
              <TextField source="name" />
            </ReferenceOneField>
          </TableRowWrapper>
        )}
        {hasResourceAccess('currencies') && (
          <TableRowWrapper title="Валюта">
            <ReferenceOneField label="Валюта" reference="currencies" source="currency" target="id">
              <FunctionField render={(record: RaRecord | undefined) => record?.alphaCode} />
            </ReferenceOneField>
          </TableRowWrapper>
        )}
        {hasResourceAccess('transaction_types') && (
          <TableRowWrapper title="Типы транзакций">
            <ReferenceArrayField
              label="Типы транзакций"
              reference="transaction_types"
              source="transactionTypes"
            >
              <SingleFieldList linkType={false}>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          </TableRowWrapper>
        )}
        <TableRowWrapper headerStyle={HEADER_SX} title="Условия комиссии:" />
        <TableRowWrapper title="Нулевая комиссия">
          <BooleanField label="Нулевая комиссия" source="zeroed" />
        </TableRowWrapper>
        <TableRowWrapper title="Процент">
          <NumberField emptyText={EMPTY_FIELD} label="Процент" source="percent" />
        </TableRowWrapper>
        <TableRowWrapper title="Фиксированная">
          <NumberField emptyText={EMPTY_FIELD} label="Фиксированная" source="fix" />
        </TableRowWrapper>
        <TableRowWrapper title="Максимальная">
          <NumberField emptyText={EMPTY_FIELD} label="Максимальная" source="max" />
        </TableRowWrapper>
        <TableRowWrapper title="Минимальная">
          <NumberField emptyText={EMPTY_FIELD} label="Минимальная" source="min" />
        </TableRowWrapper>
        <TableRowWrapper headerStyle={HEADER_SX} title="Дополнительно:" />
        <TableRowWrapper contentStyle={DATE_SX} title="Период активности (от)">
          <DateTimeField source="startDate" />
        </TableRowWrapper>
        <TableRowWrapper contentStyle={DATE_SX} title="Период активности (до)">
          <DateTimeField source="endDate" />
        </TableRowWrapper>
        {record?.filePath && (
          <TableRowStyled>
            <TableCellStyled>
              <FileHeaderStyled>Вложения</FileHeaderStyled>
              <FileField />
            </TableCellStyled>
          </TableRowStyled>
        )}
        {record.comments.length > 0 && (
          <TableRowWrapper headerStyle={HEADER_SX} title="Комментарии:" />
        )}
        {record.comments.length > 0 && (
          <TableRowCommentsStyled>
            <TableCellStyled colSpan={2}>
              <ReferenceArrayField
                reference="commission_comments"
                sortable={false}
                source="comments"
              >
                <SingleFieldList linkType={false}>
                  <CommissionsListComment />
                </SingleFieldList>
              </ReferenceArrayField>
            </TableCellStyled>
          </TableRowCommentsStyled>
        )}
      </TableBody>
    </CardTable>
  );
};
