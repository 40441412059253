import { useCreatePath, useRecordContext, useResourceContext, CreatePathType } from 'react-admin';
import { Link } from 'shared/mui/Link/Link';
import { LinkProps } from 'shared/react-admin/Link/Link';

export type ReferenceLinkProps = Omit<LinkProps, 'to'> & {
  type: CreatePathType;
};

export const ReferenceLink = ({
  type,
  children,
  target = '_blank',
  underline = 'none',
}: ReferenceLinkProps) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const createPath = useCreatePath();

  const path = createPath({ resource, id: record.id, type: type });

  return (
    <Link href={path} target={target} underline={underline}>
      {children}
    </Link>
  );
};
