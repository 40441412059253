import { OrderAdjustments } from 'types/orderAdjustments';

const RED_COLOR = '#FEEBEE';

const ColorConfig: Record<string, string> = {
  '/admin/adjustment_statuses/rejected': RED_COLOR,
};

export const getRowStyle = (record: OrderAdjustments) =>
  record?.adjustmentStatus && ColorConfig[record.adjustmentStatus]
    ? { background: ColorConfig[record.adjustmentStatus] }
    : { background: 'white' };
