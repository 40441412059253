import { Box, styled } from '@mui/material';
import { appColors } from 'themes/variables';

export const CardHeaderStyled = styled(Box)({
  width: '100%',
  minHeight: '64px',
  padding: '16px',
  borderBottom: `1px solid ${appColors.divider}`,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '6px',
});

export const StyledBox = styled(Box)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mr: '8px',
});
