import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

import { IconButton, IconButtonProps } from '..';

export type ExpandIconButtonProps = Omit<IconButtonProps, 'icon'> & { expanded: boolean };

export function ExpandIconButton({ expanded, ...restProps }: ExpandIconButtonProps): JSX.Element {
  return (
    <IconButton {...restProps}>
      {expanded ? <KeyboardArrowUp color="secondary" /> : <KeyboardArrowDown color="secondary" />}
    </IconButton>
  );
}
