import { ExportTransportTypes } from 'constants/exportFormats';
import { NOTIFICATIONS_REFETCH_INTERVAL, NotificationTypes } from 'constants/notifications';

import { NotificationsResponse, getNotifications } from 'config/requests';
import { getUnixTime, subDays } from 'date-fns';
import { useQuery } from 'react-query';

type UseGetNotificationsReturnValue = {
  notifications?: NotificationsResponse;
  isLoading: boolean;
  refetch: () => void;
};

const DEFAULT_NOTIFICATIONS_PAGINATION = {
  page: 1,
  perPage: 10000,
};

export function useGetNotifications(isEnabled: boolean): UseGetNotificationsReturnValue {
  const requestParams = {
    createdAt: { gte: getUnixTime(subDays(new Date(), 2)), lte: getUnixTime(new Date()) },
    'transports.transport': ExportTransportTypes.Admin,
    notificationType: [NotificationTypes.Report, NotificationTypes.OrderAdjustment],
    order: { id: 'DESC' },
    ...DEFAULT_NOTIFICATIONS_PAGINATION,
  };

  const {
    data: notifications,
    isLoading,
    refetch,
  } = useQuery<NotificationsResponse>(['notifications'], () => getNotifications(requestParams), {
    enabled: isEnabled,
    refetchInterval: NOTIFICATIONS_REFETCH_INTERVAL,
  });

  return { notifications, isLoading, refetch };
}
