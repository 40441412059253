import { EMPTY_FIELD } from 'constants/emptyField';

import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

type Colors = 'red' | 'yellow' | 'green';
type Field = 'icon' | 'text';

const colorsMap: Record<Colors, Record<Field, string>> = {
  green: { icon: appColors.success.light, text: appColors.success.main },
  yellow: { icon: appColors.warning.light, text: appColors.warning.main },
  red: { icon: appColors.error.light, text: appColors.error.main },
};

type Props = {
  activityUpdatedAtColor: Colors;
  activityUpdatedAtMessage: string;
};

const getColor = (field: Field, color?: Colors) =>
  color ? colorsMap?.[color]?.[field] : appColors.text.disabled;

export const ApiClientsLastActivityActive: React.FC<Props> = ({
  activityUpdatedAtColor,
  activityUpdatedAtMessage,
}) => {
  return (
    <Stack alignItems="start" direction="row" gap={1}>
      <MonitorHeartOutlinedIcon htmlColor={getColor('icon', activityUpdatedAtColor)} />
      <Typography color={getColor('text', activityUpdatedAtColor)} component="span">
        {activityUpdatedAtMessage || EMPTY_FIELD}
      </Typography>
    </Stack>
  );
};
