import { useRecordContext } from 'react-admin';
import {
  FilterAutocompleteChip,
  FilterTextChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency, Merchant } from 'types';

export const MerchantShowAccountsFiltersChips = () => {
  const record = useRecordContext<Merchant>();

  return (
    <>
      <FilterTextChip label="Название" name="name">
        <TextInputShrink
          helperText={false}
          label="Название"
          resettable
          source="name"
          variant="outlined"
        />
      </FilterTextChip>
      <FilterAutocompleteChip label="Провайдер" name="provider" resource="providers">
        <ReferenceInput
          filter={{ merchantAccounts: { merchants: record?.id } }}
          perPage={100}
          reference="providers"
          source="provider"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            helperText={false}
            label="Провайдер"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterAutocompleteChip label="Шлюз" name="gateways" resource="gateways">
        <ReferenceInput perPage={100} reference="gateways" source="gateways">
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            helperText={false}
            label="Шлюз"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterAutocompleteChip label="Валюта" name="currency" resource="currencies">
        <ReferenceInput perPage={100} reference="currencies" source="currency">
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ currency: searchText })}
            fullWidth
            helperText={false}
            label="Валюта"
            optionText={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
    </>
  );
};
