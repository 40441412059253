import { EMPTY_FIELD } from 'constants/emptyField';

import { RaRecord } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';

import { OrderChangeStatusesFieldChips } from './OrderChangeStatusesFieldChips/OrderChangeStatusesFieldChips';

type Props = Omit<FunctionFieldProps, 'render'>;

export const OrderChangeStatusesField: React.FC<Props> = (props) => {
  return (
    <FunctionField
      render={(record: RaRecord) => {
        if (!record?.statusChanges?.length) {
          return <div>{EMPTY_FIELD}</div>;
        }

        return (
          <Box>
            {(record.statusChanges as string[][]).map((idsArray, index) => (
              <OrderChangeStatusesFieldChips
                key={`${idsArray[0]}${index}}`}
                statusesIds={idsArray}
              />
            ))}
          </Box>
        );
      }}
      {...props}
    />
  );
};
