import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { Card, CardHeader } from 'shared/mui/Card';
import { appColors } from 'themes/variables';

export const StyledCard = styled(Card)({
  border: `1px solid ${appColors.divider}`,
  borderRadius: '4px',
});

export const StyledCardHeader = styled(CardHeader)({
  padding: '16px',
  borderBottom: `1px solid ${appColors.divider}`,
});

export const StyledLeftColumnBox = styled(Box)({
  width: '368px',
  minWidth: '368px',
  paddingRight: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const StyledRightColumnBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  minWidth: '0',
  gap: '16px',
});
