import { GATEWAY_STATUSES, GatewayStatus } from 'constants/gatewayStatuses';

import { Check, DoNotDisturbAlt, Title } from '@mui/icons-material';
import { useCreateContext } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText } from 'shared/mui/FormHelperText/FormHelperText';
import { InputLabel } from 'shared/mui/Labels/InputLabel/InputLabel';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { Select } from 'shared/mui/Select/Select';
import { appColors } from 'themes/variables';

import { FormControlStyled, menuStyles } from './GatewayStatusSelectInput.styled';
import { ReactComponent as PilotIcon } from '../../../../images/pilotFilled.svg';

export const GatewayStatusSelectInput = () => {
  const { resource: createResource } = useCreateContext();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name="status"
      render={({ field }) => (
        <FormControlStyled error={!!errors.status} fullWidth>
          <InputLabel required>Статус</InputLabel>
          <Select
            MenuProps={{ sx: menuStyles }}
            fullWidth
            label="Статус"
            onChange={(value) => {
              field.onChange(value);
            }}
            required
            size="medium"
            value={field.value || ''}
            variant="outlined"
          >
            <MenuItem value={GATEWAY_STATUSES[GatewayStatus.Active].id}>
              <Check htmlColor={appColors.success.light} />
              <span>{GATEWAY_STATUSES[GatewayStatus.Active].name}</span>
            </MenuItem>
            <MenuItem value={GATEWAY_STATUSES[GatewayStatus.Disabled].id}>
              <DoNotDisturbAlt htmlColor={appColors.error.light} />
              <span>{GATEWAY_STATUSES[GatewayStatus.Disabled].name}</span>
            </MenuItem>
            <MenuItem value={GATEWAY_STATUSES[GatewayStatus.Test].id}>
              <Title htmlColor={appColors.warning.light} />
              <span>{GATEWAY_STATUSES[GatewayStatus.Test].name}</span>
            </MenuItem>
            <MenuItem disabled={!!createResource} value={GATEWAY_STATUSES[GatewayStatus.Pilot].id}>
              <PilotIcon style={{ marginRight: '8px' }} />
              <span>{`${GATEWAY_STATUSES[GatewayStatus.Pilot].name} ${
                createResource ? '(доступен после создания шлюза)' : ''
              }`}</span>
            </MenuItem>
          </Select>
          {errors.status?.message && (
            <FormHelperText>{errors.status?.message as string}</FormHelperText>
          )}
        </FormControlStyled>
      )}
    />
  );
};
