import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { Typography } from 'shared/mui/Typography';

type Props = {
  onClick: VoidFunction;
};

export const OrderAdjustmentsFormAlert: React.FC<Props> = ({ onClick }) => {
  return (
    <Alert
      action={
        <Button
          color="primary"
          label="Поиск по ID"
          onClick={onClick}
          sx={{ width: '106px', padding: '6px' }}
          variant="contained"
        />
      }
      severity="info"
      sx={{ mt: '24px' }}
    >
      <Typography fontWeight={500} sx={{ mb: '4px' }}>
        Предварительный просмотр
      </Typography>
      <Typography variant="body2">
        Для предварительного просмотра, нужно выполнить поиск по одному или списку необходимых
        &quot;Заказов&quot;. В случае, если необходимо сформировать список &quot;Заказов&quot; по
        более широким параметрам, то воспользуйтесь разделом &quot;Заказы&quot;.
      </Typography>
    </Alert>
  );
};
