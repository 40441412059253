import { ReactNode } from 'react';

import { BoxStyled } from './TooltipOverflow.styled';
import { Tooltip } from '../Tooltip/Tooltip';

export interface OverflowTypograpyProps {
  children?: ReactNode;
  tooltipTitle?: ReactNode;
}

export const TooltipOverflow: React.FC<OverflowTypograpyProps> = ({ children, tooltipTitle }) => {
  return (
    <Tooltip PopperProps={{ placement: 'bottom' }} arrow={true} title={tooltipTitle || children}>
      <BoxStyled>{children}</BoxStyled>
    </Tooltip>
  );
};
