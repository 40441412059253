import {
  EXPORT_FORMAT_NAMES,
  ExportFormats,
  ExportJobTypes,
  ExportTransportTypes,
  ReportNames,
} from 'constants/exportFormats';

import React, { useEffect, useMemo, useState } from 'react';

import { getUserInfo } from 'config/requests';
import { useReportExport } from 'hooks/queries/useReportExport';
import { useListController, SortPayload, useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import { ExportFormatsButton } from 'shared/mui/Buttons/ExportFormatsButton/ExportFormatsButton';
import { NotifyDialog } from 'shared/mui/Dialogs';
import { Typography } from 'shared/mui/Typography';
import { ExportEditButton } from 'shared/react-admin/Export/ExportEditButton/ExportEditButton';
import { ListPage } from 'shared/react-admin/Pages';
import { ReportExportDialogMessage } from 'shared/widgets/ReportExport/ReportExportDialogMessage';
import { Order, ExportFormat } from 'types';

import { OrdersReportListContent } from './components/OrdersReportListContent';
import { EXPORT_FIELDS_ORDERS_REPORT } from '../constants/exportFields';

export const OrdersReportListNew: React.FC = () => {
  const {
    filterValues,
    isLoading: isListLoading,
    refetch,
  } = useListController<Order>({
    resource: 'orders/report',
    sort: {} as SortPayload,
    queryOptions: { enabled: false },
  });

  const [isOpenNotifyDialog, setIsOpenNotifyDialog] = useState(false);
  const [isSendReportOnEmail, setIsSendReportOnEmail] = useState(false);
  const notify = useNotify();
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormats.Excel);

  const { data: user } = useQuery('me', getUserInfo);

  const isFiltersApplied = useMemo(() => Object.keys(filterValues).length > 0, [filterValues]);

  const { mutate: exportMutate, isLoading: isExportLoading } = useReportExport({
    filters: {
      ...filterValues,
      exportType: exportFormat,
      jobType: ExportJobTypes.Success_transaction,
      ...(isSendReportOnEmail && { notificationTransport: ExportTransportTypes.Email }),
    },
    preferenceKey: ReportNames.Orders_report,
    onSuccess: () => {
      notify(
        `Файл экспорта исполненных транзакций формируется ${isSendReportOnEmail ? 'и будет отправлен вам на почту' : ''}`,
        {
          type: 'info',
        },
      );
      setIsOpenNotifyDialog(false);
    },
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  useEffect(() => {
    if (isFiltersApplied) {
      refetch()?.then(() => setIsShowAlert(true));
    }
  }, [filterValues]);

  return (
    <ListPage
      actions={[
        <ExportFormatsButton
          exportFormat={exportFormat}
          key="export-button"
          onChangeFormat={setExportFormat}
          onClick={() => setIsOpenNotifyDialog(true)}
        />,
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Отчет по исполненным транзакциям',
        tooltipText:
          'Отчет по исполненным транзакциям формирует сведения только по исполненным / успешным заказам (со статусом успех). Для получения информации по не исполненным транзакциям, перейдите в раздел “Заказы”',
      }}
      listBoxProps={{ sx: { maxHeight: '100%' } }}
      pagination={<></>}
      queryOptions={{ enabled: isFiltersApplied }}
      resource="orders/report"
      sort={{} as SortPayload}
    >
      <OrdersReportListContent
        isFiltersApplied={isFiltersApplied}
        isShowAlert={isShowAlert}
        setIsShowAlert={setIsShowAlert}
      />
      <NotifyDialog
        isLoading={isListLoading || isExportLoading}
        message={
          <ReportExportDialogMessage
            exportFormat={EXPORT_FORMAT_NAMES[exportFormat]}
            isSendReportOnEmail={isSendReportOnEmail}
            onChange={() => setIsSendReportOnEmail((prev) => !prev)}
            userEmail={user?.corporateEmail || user?.email || 'почта неизвестна'}
          />
        }
        onClose={() => setIsOpenNotifyDialog(false)}
        onSubmit={exportMutate}
        open={isOpenNotifyDialog}
        title={
          <>
            <Typography component="span" sx={{ verticalAlign: 'middle' }} variant="inherit">
              Экспорт отчета
            </Typography>
            <ExportEditButton
              fields={EXPORT_FIELDS_ORDERS_REPORT}
              preferenceKey={ReportNames.Orders_report}
            />
          </>
        }
      />
    </ListPage>
  );
};
