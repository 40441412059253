import ArchiveIcon from '@mui/icons-material/Archive';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';

import { useDatagridActionsContext } from '../context/datagridActionsContext';
import { ListItemTextStyled } from '../DatagridActions.styled';

export type DatagridActionsUnarchiveButtonProps = {
  onClick: VoidFunction;
};

export const DatagridActionsUnarchiveButton: React.FC<DatagridActionsUnarchiveButtonProps> = ({
  onClick,
}) => {
  const { onCloseMenu } = useDatagridActionsContext();
  const handleClick = () => {
    onClick();
    onCloseMenu();
  };

  return (
    <MenuItem onClick={handleClick} sx={{ width: '100%' }}>
      <ListItemIcon>
        <ArchiveIcon fontSize="small" sx={{ marginTop: '1px', transform: 'rotate(180deg)' }} />
      </ListItemIcon>
      <ListItemTextStyled primary="Разархивировать" />
    </MenuItem>
  );
};
