import {
  updateGatewayConversionSettings,
  UpdateGatewayConversionSettingsParams,
} from 'config/requests';
import { useNotify } from 'react-admin';
import { useMutation } from 'react-query';

export function useUpdateGatewayConversionSettings() {
  const notify = useNotify();

  const mutation = useMutation(
    ({ id, data }: { id: number; data: UpdateGatewayConversionSettingsParams }) =>
      updateGatewayConversionSettings(id, data),
    {
      onSuccess: async () => {
        notify('Настройки успешно отредактированы', { type: 'success' });
      },
      onError: (e) => {
        throw e;
      },
    },
  );

  return { updateGatewayConversionSettings: mutation };
}
