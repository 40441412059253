import { useState } from 'react';

import ContentAdd from '@mui/icons-material/Add';
import {
  MerchantShowAccessFilterValues,
  MerchantShowAccessListFilter,
} from 'components/Merchants/MerchantsShow/MerchantShowAccess/MerchantShowAccessListFilter';
import { usePermissions } from 'hooks/usePermissions';
import { ReactComponent as DisableAccess } from 'images/disableAccess.svg';
import {
  ListContextProvider,
  ReferenceArrayField,
  SingleFieldList,
  useListController,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { UserRoleChip } from 'shared/mui/Chips';
import { IconButton } from 'shared/mui/IconButtons';
import {
  DateTimeField,
  FunctionField,
  TextField,
  UserProfileField,
} from 'shared/react-admin/Fields';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { MerchantAccess, Permission } from 'types';
import { getId } from 'utils';

import { MerchantShowAccessBulkDisableButton } from './components/MerchantShowAccessBulkDisableButton';
import { MerchantShowAccessCreateDialog } from './components/MerchantShowAccessCreateDialog/MerchantShowAccessCreateDialog';
import { MerchantShowAccessDisableDialog } from './components/MerchantShowAccessDisableDialog/MerchantShowAccessDisableDialog';
import {
  StyledCardHeader,
  StyledDatagrid,
  StyledLoaderCircle,
} from './MerchantShowAccessList.styled';

type Props = {
  merchantId?: string;
  merchantName?: string;
};

const DEFAULT_FILTER_VALUES = {
  user: {
    firstName: '',
    lastName: '',
    roleEntities: [],
  },
};

export const MerchantsShowAccessList: React.FC<Props> = ({ merchantId, merchantName }) => {
  const { hasResourcePermissions } = usePermissions();

  const listContext = useListController({
    resource: `merchants/${getId(merchantId)}/merchant_accesses`,
    disableSyncWithLocation: true,
  });

  const form = useForm<MerchantShowAccessFilterValues>({ defaultValues: DEFAULT_FILTER_VALUES });

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDisableDialog, setOpenDisableDialog] = useState(false);
  const [selectedAccesses, setSelectedAccesses] = useState<MerchantAccess[]>([]);

  const onReset = () => {
    listContext.setFilters(DEFAULT_FILTER_VALUES, {});
    form.reset(DEFAULT_FILTER_VALUES, { keepDirty: true });
  };

  const onSubmit = () => {
    listContext.setFilters(form.getValues(), listContext.displayedFilters);
  };

  const isShowAlert =
    (listContext?.data?.length <= 0 || !listContext?.data) && !form.formState.isDirty;

  if (listContext?.isLoading) {
    return <StyledLoaderCircle />;
  }

  const hasEditPermission = hasResourcePermissions('merchant_accesses', [
    Permission.Full,
    Permission.Delete,
  ]);

  return (
    <ListContextProvider value={listContext}>
      <StyledCardHeader
        actions={[
          hasResourcePermissions('merchant_accesses', [Permission.Full, Permission.Create]) && (
            <Button
              key="create"
              label="Добавить"
              onClick={() => setOpenCreateDialog(true)}
              startIcon={<ContentAdd />}
              sx={{ marginTop: '8px', padding: '3px 16px' }}
              variant="contained"
            />
          ),
        ]}
        title="Настройка доступов мерчанта"
        titleSize="small"
      />
      {isShowAlert ? (
        <Alert severity="info">Мерчанту {merchantName} не добавлены доступы.</Alert>
      ) : (
        <>
          <MerchantShowAccessListFilter form={form} onReset={onReset} onSubmit={onSubmit} />
          <StyledDatagrid
            bulkActionButtons={
              hasEditPermission ? (
                <MerchantShowAccessBulkDisableButton
                  setOpenDisableDialog={setOpenDisableDialog}
                  setSelectedAccesses={setSelectedAccesses}
                />
              ) : (
                false
              )
            }
          >
            <TextField label="ID" sortBy="id" source="originId" />
            <ReferenceField label="Пользователь" link={false} reference="users" source="user">
              <FunctionField cellClassName="userInfo" render={() => <UserProfileField />} />
            </ReferenceField>
            <ReferenceField label="Роль" link={false} reference="users" source="user">
              <ReferenceArrayField reference="roles" source="roleEntities">
                <SingleFieldList linkType={false}>
                  <UserRoleChip />
                </SingleFieldList>
              </ReferenceArrayField>
            </ReferenceField>
            <DateTimeField label="Дата назначения" source="createdAt" />
            <ActionsSlots
              render={(record: MerchantAccess) => (
                <>
                  {hasEditPermission && (
                    <IconButton
                      onClick={() => {
                        setOpenDisableDialog(true);
                        setSelectedAccesses([record]);
                      }}
                    >
                      <DisableAccess />
                    </IconButton>
                  )}
                </>
              )}
            />
          </StyledDatagrid>
        </>
      )}
      <MerchantShowAccessCreateDialog
        merchantId={merchantId}
        onClose={() => setOpenCreateDialog(false)}
        open={openCreateDialog}
      />
      <MerchantShowAccessDisableDialog
        merchantId={merchantId}
        merchantName={merchantName}
        onClose={() => setOpenDisableDialog(false)}
        open={openDisableDialog}
        selectedAccesses={selectedAccesses}
      />
    </ListContextProvider>
  );
};
