import { createOrderAdjustments } from 'config/requests';
import { useNotify } from 'react-admin';
import { useMutation } from 'react-query';
import { CreateOrderAdjustmentResponse, ErrorData } from 'types';

import { OrderAdjustmentsFormValues } from './useOrderAdjustmentsForm';

export function useCreateOrderAdjustments(
  onSuccess: (result: CreateOrderAdjustmentResponse) => void,
) {
  const notify = useNotify();

  const mutation = useMutation((data: OrderAdjustmentsFormValues) => createOrderAdjustments(data), {
    onSuccess: async (result) => {
      onSuccess(result);
      notify('Запрос на корректировку успешно создан', { type: 'success' });
    },
    onError: (e: ErrorData) => {
      notify(`Ошибка: ${e.violations?.map((e) => e.message).join('\n')}`, {
        type: 'error',
        multiLine: true,
      });
    },
  });

  return { createOrderAdjustments: mutation };
}
