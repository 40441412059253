import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

export type DatagridFieldLabelProps = {
  primaryText: string;
  secondaryText: string;
};

export const DatagridFieldLabel = ({
  primaryText,
  secondaryText,
}: DatagridFieldLabelProps): JSX.Element => (
  <Stack>
    <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{primaryText}</Typography>
    <Typography sx={{ fontSize: '14px', color: appColors.text.disabled }}>
      {secondaryText}
    </Typography>
  </Stack>
);
