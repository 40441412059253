import { CommissionStatuses } from 'constants/commissionStatuses';

import * as React from 'react';

import { ChipFieldProps, useRecordContext } from 'react-admin';

import {
  ChipFieldStyled,
  ChipFieldWrapperStyled,
  StatusIconStyled,
} from './CommissionStatusChipField.styled';

export type CommissionStatusChipFieldProps = ChipFieldProps;

export const CommissionStatusChipField: React.FC<CommissionStatusChipFieldProps> = ({
  children,
  ...restProps
}) => {
  const record = useRecordContext();

  return (
    <ChipFieldWrapperStyled>
      <ChipFieldStyled
        icon={<StatusIconStyled status={record?.value as CommissionStatuses} />}
        {...restProps}
      />
      {children}
    </ChipFieldWrapperStyled>
  );
};
