import { RecordContextProvider } from 'react-admin';
import { FunctionField, FunctionFieldProps, TextField } from 'shared/react-admin/Fields';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';

import { Gateway, Order } from '../../../types';

type Props = Omit<FunctionFieldProps, 'render'>;

export const OrderAdjustmentsProviderField: React.FC<Props> = (props) => {
  return (
    <FunctionField
      render={(record: Order) => {
        return (
          <RecordContextProvider value={record}>
            <ReferenceField link={false} reference="gateways" source="gateway">
              <FunctionField
                render={(record: Gateway) => {
                  return (
                    <RecordContextProvider value={record}>
                      <ReferenceField link={false} reference="providers" source="provider">
                        <TextField source="name" />
                      </ReferenceField>
                    </RecordContextProvider>
                  );
                }}
                source="provider"
              />
            </ReferenceField>
          </RecordContextProvider>
        );
      }}
      {...props}
    />
  );
};
