import { useRecordContext } from 'react-admin';
import { AutocompleteArrayInputShrink, AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency, Merchant } from 'types';

export const MerchantShowAccountsFiltersTable = () => {
  const record = useRecordContext<Merchant>();

  return (
    <>
      <TextInputShrink
        InputLabelProps={{ shrink: true }}
        helperText={false}
        key="name"
        label="Название"
        name="name"
        source="name"
        variant="outlined"
      />
      <ReferenceInput
        filter={{ merchantAccounts: { merchants: record?.id } }}
        isFilter={true}
        name="provider"
        perPage={200}
        reference="providers"
        source="provider"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Провайдер"
          name="provider"
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        isFilter={true}
        name="gateways"
        perPage={200}
        reference="gateways"
        source="gateways"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Шлюз"
          name="gateways"
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        isFilter={true}
        name="currency"
        perPage={1000}
        reference="currencies"
        sort={{ field: 'id', order: 'ASC' }}
        source="currency"
      >
        <AutocompleteArrayInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ currency: searchText })}
          helperText={false}
          label="Валюта"
          name="currency"
          optionText={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
          variant="outlined"
        />
      </ReferenceArrayInput>
    </>
  );
};
