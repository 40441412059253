import * as React from 'react';

import { RecordContextProvider } from 'react-admin';
import { Card, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { SingleFieldList } from '../../../../shared/react-admin/Fields';
import { ReferenceArrayField } from '../../../../shared/react-admin/ReferenceFields';
import { Commission } from '../../../../types';
import { truncateString } from '../../../../utils/truncateString';
import { CommissionsListComment } from '../CommissionsListComment/CommissionsListComment';

type Props = DialogProps & {
  record: Commission | undefined;
};

export const CommissionsListCommentDialog: React.FC<Props> = ({ record, ...restProps }) => {
  return (
    <Dialog {...restProps} fullWidth>
      <RecordContextProvider value={record}>
        <Card>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={restProps.onClose} />]}
            sx={{ height: 'auto' }}
            title={
              <>
                Комментарии к комиссии <br /> ${truncateString(record?.name || '', 50)}
              </>
            }
          />
          <CardContent sx={{ padding: '0px 24px 24px 24px!important' }}>
            <ReferenceArrayField reference="commission_comments" sortable={false} source="comments">
              <SingleFieldList linkType={false}>
                <CommissionsListComment />
              </SingleFieldList>
            </ReferenceArrayField>
          </CardContent>
        </Card>
      </RecordContextProvider>
    </Dialog>
  );
};
