import { RESPONSE_STATUSES } from 'constants/responseStatuses';

import { Chip } from 'shared/mui/Chips';
import { Tooltip } from 'shared/mui/Tooltip';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { OrderLog } from 'types';

type Props = Omit<FunctionFieldProps, 'render' | 'source'>;

export const OrderShowLogsStatusField: React.FC<Props> = (props) => {
  return (
    <FunctionField
      render={(record: OrderLog) => {
        const status = record?.responseData.statusCode;
        const statusDetails = RESPONSE_STATUSES[record?.responseData.statusCode];

        return (
          <Tooltip placement="bottom" title={statusDetails?.description}>
            <Chip
              label={`${status} ${statusDetails?.name}`}
              sx={{ backgroundColor: statusDetails?.color, '& .MuiChip-label': { color: '#fff' } }}
            />
          </Tooltip>
        );
      }}
      {...props}
    />
  );
};
