import { styled } from '@mui/material/styles';
import { DatagridConfigurableWithStickyActions } from 'shared/react-admin/Datagrids/DatagridConfigurableWithStickyActions/DatagridConfigurableWithStickyActions';
import { ChipField } from 'shared/react-admin/Fields';
import { getColumnStyle } from 'utils';

const commonStyle = getColumnStyle();

const columnStyle = {
  width: '20%',
  maxWidth: '200px',
  minWidth: '200px',
  textWrap: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const StyledDatagrid = styled(DatagridConfigurableWithStickyActions)({
  '& .column-name': columnStyle,
  '& .column-alias': columnStyle,
  '& .column-archive': getColumnStyle(160),
  '& .column-createdAt': commonStyle,
  '& .column-updatedAt': commonStyle,
  '& .column-partner': getColumnStyle(120),
  '& .column-platform': {
    width: '20%',
    maxWidth: '160px',
    minWidth: '160px',
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .column-notificationKey': getColumnStyle(160),
  '& .column-companies': {
    width: '20%',
    maxWidth: '200px',
    minWidth: '200px',
  },
});

export const ChipFieldStyled = styled(ChipField)({
  '.MuiChip-label': {
    color: '#FFF',
    cursor: 'pointer',
    lineHeight: '12px',
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '184px',
  },
});
