import { styled } from '@mui/material/styles';
import { Datagrid } from 'react-admin';

export const StyledDatagrid = styled(Datagrid)({
  minWidth: '36px',
  '.MuiButton-startIcon': {
    marginRight: '4px',
    '.MuiSvgIcon-root': {
      fontSize: '18px',
    },
  },
  padding: 0,
  '& .RaDatagrid-headerCell': {
    verticalAlign: 'top',
    padding: '13px 16px',
    lineHeight: '21px',
    backgroundColor: '#FAFAFA',
  },
  '& .RaDatagrid-rowCell': {
    verticalAlign: 'center',
    padding: '12px 16px',
  },
  '& th.MuiTableCell-paddingCheckbox': {
    paddingTop: '13px !important',
    verticalAlign: 'top',
  },
  '& td.MuiTableCell-paddingCheckbox': {
    paddingTop: '10px !important',
    verticalAlign: 'top',
  },
  '& .MuiTableCell-root': {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    verticalAlign: 'top',
    overflow: 'hidden',
    textAlign: 'left',
  },
  '& .column-originId': {
    width: 0,
  },
  '& .RaBulkActionsToolbar-toolbar:first-of-type': {
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
  },
  '& .RaBulkActionsToolbar-collapsed:first-of-type': {
    height: '0',
    display: 'flex',
    alignItems: 'start',
    paddingTop: '0',
  },
  h6: {
    color: 'black',
  },
  '& .MuiTableSortLabel-root': {
    alignItems: 'flex-start',
  },
});
