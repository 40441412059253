import { EMPTY_FIELD } from 'constants/emptyField';

import { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { JsonEditor, JsonEditorProps } from 'json-edit-react';
import { FieldProps, useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { IconButton } from 'shared/mui/IconButtons';
import { Tooltip } from 'shared/mui/Tooltip';

export type JsonCopyToClipboardFieldProps = FieldProps &
  Partial<JsonEditorProps> & { src?: Record<string, any> };

const jsonValueColor = 'rgba(25, 118, 210, 1)';

const theme = [
  'monoLight',
  {
    container: {
      backgroundColor: '#f6f6f6',
      fontFamily: 'Roboto, monospace',
      fontSize: '16px',
    },
    string: jsonValueColor,
    number: jsonValueColor,
    boolean: { color: jsonValueColor, fontStyle: 'normal' },
    null: { color: jsonValueColor, fontVariant: 'normal', fontWeight: 'normal' },
    iconCollection: 'rgb(0, 43, 54)',
    iconEdit: 'edit',
    iconDelete: 'rgb(203, 75, 22)',
    iconAdd: 'green',
    iconCopy: 'rgb(38, 139, 210)',
    iconOk: 'green',
    iconCancel: 'rgb(203, 75, 22)',
  },
] as JsonEditorProps['theme'];

export const JsonCopyToClipboardField = (props: JsonCopyToClipboardFieldProps) => {
  const { source, src } = props;
  const [tooltipText, setTooltipText] = useState('');
  const record = useRecordContext();

  if (!(source && record[source]) && !src) {
    return <span>{EMPTY_FIELD}</span>;
  }

  const jsonValue = src || record[source as string];
  const isEmptyData = !Object.keys(jsonValue).length;
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(jsonValue, null, 2));
      setTooltipText('Cкопировано');
      setTimeout(() => setTooltipText(''), 2000);
    } catch (e: unknown) {
      setTooltipText('Копирование не удалось');
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <JsonEditor
        data={jsonValue}
        restrictAdd={true}
        restrictDelete={true}
        restrictEdit={true}
        theme={theme}
        {...props}
      />
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={!!tooltipText}
        sx={{ position: 'absolute', top: '8px', right: '24px' }}
        title={tooltipText}
      >
        <IconButton disabled={isEmptyData} onClick={handleCopy}>
          <ContentCopyIcon color={isEmptyData ? 'secondary' : 'primary'} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
