import { Identifier, SimpleForm, useListContext } from 'react-admin';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { TextInput } from 'shared/react-admin/Inputs';

import { StyledBox, StyledDialog } from './OrderAdjustmentsGroupCommentDialog.styled';
import { OrderAdjustmentsFormValues } from '../hooks/useOrderAdjustmentsForm';

export const OrderAdjustmentsGroupCommentDialog = ({ open, onClose }: DialogProps) => {
  const { selectedIds } = useListContext();
  const { getValues, setValue } = useFormContext<OrderAdjustmentsFormValues>();

  const handleSubmit: SubmitHandler<FieldValues> = ({ comment }) => {
    const orderAdjustments = getValues('orderAdjustments');
    selectedIds.forEach((orderId: Identifier) => {
      const index = orderAdjustments.findIndex((order) => order.orderId === orderId);

      if (index !== -1) {
        setValue(`orderAdjustments.${index}.comment`, comment);
      }
    });
    onClose();
  };

  return (
    <StyledDialog maxWidth="md" onClose={onClose} open={open}>
      <SimpleForm onSubmit={handleSubmit} toolbar={<></>}>
        <StyledBox>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Комментарий для группы заказов"
          />
          <CardContent>
            <TextInput
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="Комментарий"
              multiline
              required
              size="medium"
              source="comment"
              variant="outlined"
            />
          </CardContent>
          <CardActionsButtons
            rightActionsSlot={[
              <Button key="save-button" label="Сохранить" type="submit" variant="contained" />,
              <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
            ]}
          />
        </StyledBox>
      </SimpleForm>
    </StyledDialog>
  );
};
