import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { RecordContextProvider, useGetList } from 'react-admin';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { getId } from 'utils';

import { StyledBox } from './OrderChangeStatusesFieldChips.styled';
import { OrderStatusChipField } from '../../OrderStatusChipField/OrderStatusChipField';

// временный фикс для поддержания статусов старых заказов
const OLD_STATUSES_CORRELATION = {
  '/admin/order_statuses/1': '/admin/order_statuses/created',
  '/admin/order_statuses/2': '/admin/order_statuses/pending',
  '/admin/order_statuses/3': '/admin/order_statuses/failure',
  '/admin/order_statuses/4': '/admin/order_statuses/success',
  '/admin/order_statuses/5': '/admin/order_statuses/refund',
  '/admin/order_statuses/6': '/admin/order_statuses/expired',
  '/admin/order_statuses/7': '/admin/order_statuses/chargeback',
  '/admin/order_statuses/11': '/admin/order_statuses/prepared',
  '/admin/order_statuses/13': '/admin/order_statuses/cancelled',
  '/admin/order_statuses/15': '/admin/order_statuses/waiting_confirmation',
};

type Props = {
  statusesIds: string[];
};

export const OrderChangeStatusesFieldChips: React.FC<Props> = ({ statusesIds }) => {
  const { data: orderStatuses, isLoading } = useGetList('new/order_statuses');

  if (isLoading) {
    return <LinearProgress />;
  }

  const formattedStatusesIds = Number(getId(statusesIds[0]))
    ? statusesIds.map(
        (oldStatus) => OLD_STATUSES_CORRELATION[oldStatus as keyof typeof OLD_STATUSES_CORRELATION],
      )
    : statusesIds;

  const statusesList = formattedStatusesIds.map((id) =>
    orderStatuses?.find((status) => status.value === getId(id)),
  );

  const [first, second] = statusesList;

  return (
    <StyledBox>
      <RecordContextProvider value={first}>
        <OrderStatusChipField source="description" />
      </RecordContextProvider>

      {second && (
        <>
          <ChevronRightIcon color="secondary" />
          <RecordContextProvider value={second}>
            <OrderStatusChipField source="description" />
          </RecordContextProvider>
        </>
      )}
    </StyledBox>
  );
};
