import { CONVERSION_RATE_TYPES_VALUES, ConversionRateTypes } from 'constants/conversionTypes';

import { differenceInDays } from 'date-fns';
import { RaRecord, required } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';
import { DateTimeRangeTimezonePicker } from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import { useFilterContext } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterMultiselectChip,
  FilterTextChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { FilterDateTimeRangeTimezoneChip } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips/FilterDateTimeRangeTimezoneChip/FilterDateTimeRangeTimezoneChip';
import { AutocompleteInputShrink, AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

export const BalanceReportListFilterChips: React.FC = () => {
  const { resetField, setValue, watch, control } = useFormContext();
  const { setAppliedFilter, appliedFilters, onReset } = useFilterContext();
  const [companies, merchants, providers] = watch(['companies', 'merchants', 'providers']);

  return (
    <>
      <FilterDateTimeRangeTimezoneChip label="Период отчета" name="appliedDate" onDelete={onReset}>
        <Controller
          control={control}
          name="appliedDate"
          render={({ field: { onChange, onBlur, value }, fieldState, formState: { errors } }) => (
            <DateTimeRangeTimezonePicker
              error={!!errors.appliedDate}
              helperText={fieldState?.error?.message}
              label="Период отчета от-до"
              maxDate={new Date()}
              onBlur={onBlur}
              onChange={onChange}
              required
              value={value}
            />
          )}
          rules={{
            validate: {
              required: (value) => (!!value[0] && !!value[1]) || 'Обязательно для заполнения',
              periodError: (value) => {
                const [from, to] = value;
                const intervalInDays = differenceInDays(to, from);
                return intervalInDays <= 1825;
              },
            },
          }}
        />
      </FilterDateTimeRangeTimezoneChip>
      <FilterTextChip
        getName={(filterValue: string) =>
          CONVERSION_RATE_TYPES_VALUES.find((v) => filterValue === v.id)?.label || ''
        }
        label="Курс конвертации"
        name="conversionRateType"
        onDelete={onReset}
      >
        <SelectInputShrink
          choices={CONVERSION_RATE_TYPES_VALUES}
          defaultValue={ConversionRateTypes.Actual}
          fullWidth
          helperText={false}
          id="conversionRateType"
          label="Курс конвертации"
          name="conversionRateType"
          optionText="label"
          source="conversionRateType"
          validate={required()}
          variant="outlined"
        />
      </FilterTextChip>
      <FilterTextChip label="Валюта" name="currency" onDelete={onReset}>
        <ReferenceInput
          filter={{ active: true }}
          name="currency"
          perPage={1000}
          reference="currencies"
          resettable
          resource="currencies"
          sort={{ field: 'id', order: 'ASC' }}
          source="currency"
        >
          <AutocompleteInputShrink
            clearOnBlur={false}
            filterToQuery={(searchText: string) => ({ currency: searchText })}
            helperText={false}
            label="Валюта отчета"
            name="currency"
            optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
            optionValue="alphaCode"
            validate={required()}
            variant="outlined"
          />
        </ReferenceInput>
      </FilterTextChip>
      <FilterMultiselectChip
        label="Компания"
        limitTags={1}
        name="companies"
        onApply={() => {
          setAppliedFilter('merchants', []);
          setAppliedFilter('providers', []);
          setAppliedFilter('merchantAccounts', []);

          resetField('merchants', { keepDirty: true });
          resetField('providers', { keepDirty: true });
          resetField('merchantAccounts', { keepDirty: true });
        }}
        onDelete={() => {
          resetField('merchants', { keepDirty: true });
          resetField('providers', { keepDirty: true });
          resetField('merchantAccounts', { keepDirty: true });

          setAppliedFilter('merchants', []);
          setAppliedFilter('providers', []);
          setAppliedFilter('merchantAccounts', []);
        }}
        onReset={() => {
          setValue('merchants', appliedFilters?.['merchants']);
          setValue('providers', appliedFilters?.['providers']);
          setValue('merchantAccounts', appliedFilters?.['merchantAccounts']);

          setAppliedFilter('merchants', appliedFilters?.['merchants']);
          setAppliedFilter('providers', appliedFilters?.['providers']);
          setAppliedFilter('merchantAccounts', appliedFilters?.['merchantAccounts']);
        }}
        resource="companies"
      >
        <ReferenceArrayInput
          filter={{ archive: false }}
          perPage={100}
          reference="companies"
          source="companies"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Компания"
            limitTags={1}
            name="companies"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip
        label="Мерчант"
        limitTags={1}
        name="merchants"
        onApply={() => {
          setAppliedFilter('providers', []);
          setAppliedFilter('merchantAccounts', []);

          resetField('providers', { keepDirty: true });
          resetField('merchantAccounts', { keepDirty: true });
        }}
        onDelete={() => {
          resetField('providers', { keepDirty: true });
          resetField('merchantAccounts', { keepDirty: true });
          setAppliedFilter('providers', []);
          setAppliedFilter('merchantAccounts', []);
        }}
        onReset={() => {
          setValue('providers', appliedFilters?.['providers']);
          setValue('merchantAccounts', appliedFilters?.['merchantAccounts']);

          setAppliedFilter('providers', appliedFilters?.['providers']);
          setAppliedFilter('merchantAccounts', appliedFilters?.['merchantAccounts']);
        }}
        resource="merchants"
      >
        <ReferenceArrayInput
          enableGetChoices={() => !!companies?.length}
          filter={{ companies, exists: { merchantAccounts: true }, archive: false }}
          perPage={100}
          reference="merchants"
          source="merchants"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Мерчант"
            limitTags={1}
            name="merchants"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip
        label="Провайдер"
        limitTags={1}
        name="providers"
        onApply={() => {
          setAppliedFilter('merchantAccounts', []);

          resetField('merchantAccounts', { keepDirty: true });
        }}
        onDelete={() => {
          resetField('merchantAccounts', { keepDirty: true });

          setAppliedFilter('merchantAccounts', []);
        }}
        onReset={() => {
          setValue('merchantAccounts', appliedFilters?.['merchantAccounts']);

          setAppliedFilter('merchantAccounts', appliedFilters?.['merchantAccounts']);
        }}
        resource="providers"
      >
        <ReferenceArrayInput
          enableGetChoices={() => !!merchants?.length}
          filter={{ merchantAccounts: { merchants } }}
          name="providers"
          perPage={100}
          reference="providers"
          source="providers"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Провайдер"
            limitTags={1}
            name="providers"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip
        label="Счет мерчанта"
        limitTags={1}
        name="merchantAccounts"
        resource="merchant_accounts"
      >
        <ReferenceArrayInput
          enableGetChoices={() => !!providers?.length}
          filter={{ merchants, provider: providers }}
          name="merchantAccounts"
          perPage={100}
          reference="merchant_accounts"
          source="merchantAccounts"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Счет мерчанта"
            limitTags={1}
            name="merchantAccounts"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
    </>
  );
};
