import { blue } from '@mui/material/colors';
import { queryClient } from 'App';
import { usePermissions } from 'hooks/usePermissions';
import { useNotify } from 'react-admin';
import { Link } from 'react-router-dom';
import { Stack } from 'shared/mui/Stack/Stack';
import { Tooltip } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { GatewayRuleActiveSwitch } from 'shared/widgets/GatewayRuleActiveSwitch/GatewayRuleActiveSwitch';
import { GatewayRuleRecord, Permission } from 'types';

type Props = Omit<FunctionFieldProps, 'render'> & { queryKey: string };

export const MerchantShowRulesNameField: React.FC<Props> = ({ label, source, queryKey }) => {
  const { hasResourcePermissions } = usePermissions();
  const notify = useNotify();

  const onSuccess = async () => {
    await queryClient.invalidateQueries([queryKey]);
    notify('Активность успешна изменена.', { type: 'success' });
  };

  return (
    <FunctionField
      label={label}
      render={(record: GatewayRuleRecord) => {
        return (
          <Stack alignItems="center" direction="row">
            {hasResourcePermissions('gateway_rules', [Permission.Full, Permission.Update]) && (
              <GatewayRuleActiveSwitch onSuccess={onSuccess} size="small" />
            )}
            <Tooltip title={<Typography fontSize={12}>{record.name}</Typography>}>
              <Link
                style={{ color: blue[700] }}
                target="_blank"
                to={{ pathname: `/gateway_rules/${encodeURIComponent(record.id)}/show` }}
              >
                {record?.name}
              </Link>
            </Tooltip>
          </Stack>
        );
      }}
      sortable={false}
      source={source}
    />
  );
};
