import { queryClient } from 'App';
import { orderAccept, orderDecline } from 'config/requests';
import { useRecordModal } from 'hooks/useRecordModal';
import { useGetOne, useNotify, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { BaseDialog } from 'shared/mui/Dialogs';
import { Typography } from 'shared/mui/Typography';
import { Order } from 'types';

import { StyledBox, StyledWarningAmberIcon } from './OrderShowCommonInfoConfirmation.styled';

const INVALIDATE_RESOURCES = ['orders', 'order_status_histories', 'transactions'];

export const OrderShowCommonInfoConfirmation = () => {
  const { originId, gateway: gatewayId } = useRecordContext<Order>();
  const notify = useNotify();

  const {
    openRecordModal: openAcceptDialog,
    handleOpenRecordModal: handleOpenAcceptDialog,
    handleCloseRecordModal: handleCloseAcceptDialog,
  } = useRecordModal();
  const {
    openRecordModal: openDeclineDialog,
    handleOpenRecordModal: handleOpenDeclineDialog,
    handleCloseRecordModal: handleCloseDeclineDialog,
  } = useRecordModal();
  const {
    openRecordModal: openRedirectAcceptDialog,
    handleOpenRecordModal: handleOpenRedirectAcceptDialog,
    handleCloseRecordModal: handleCloseRedirectAcceptDialog,
  } = useRecordModal();
  const {
    openRecordModal: openRedirectDeclineDialog,
    handleOpenRecordModal: handleOpenRedirectDeclineDialog,
    handleCloseRecordModal: handleCloseRedirectDeclineDialog,
  } = useRecordModal();

  const navigate = useNavigate();

  const { data: gateway } = useGetOne('gateways', { id: gatewayId });

  const handleOrderAccept = () => {
    handleCloseAcceptDialog();
    orderAccept(originId)
      .then(() => {
        notify('Заказ успешно подтвержден.', { type: 'success' });
        handleOpenRedirectAcceptDialog();
      })
      .catch((response) => {
        const error = response.data.errors?.[0]?.title || 'Не удалось подтвердить заказ.';
        notify(`Ошибка: ${error}`, { type: 'error' });
      });
  };

  const handleOrderDecline = () => {
    handleCloseDeclineDialog();
    orderDecline(originId)
      .then(() => {
        notify('Заказ успешно отклонен.', { type: 'success' });
        handleOpenRedirectDeclineDialog();
      })
      .catch((response) => {
        const error = response.data.errors?.[0]?.title || 'Не удалось отклонить заказ.';
        notify(`Ошибка: ${error}`, { type: 'error' });
      });
  };

  return (
    <StyledBox>
      <StyledWarningAmberIcon />
      <Box style={{ marginLeft: '12px', marginRight: '16px', flex: 1 }}>
        <Typography sx={{ fontWeight: 500, marginBottom: '6px' }} variant="body1">
          Проверка заказа
        </Typography>
        <Typography variant="body2">
          Заказ <b>#{originId}</b> был создан через шлюз <b>{gateway?.name}</b> который работает в
          тестовом режиме. Необходимо выполнить проверку заказа и подтвердить или отклонить его.
        </Typography>
      </Box>
      <Box
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '8px' }}
      >
        <Button
          color="success"
          label="подтвердить"
          onClick={handleOpenAcceptDialog}
          size="small"
          variant="contained"
        />
        <Button
          color="error"
          label="отклонить"
          onClick={handleOpenDeclineDialog}
          size="small"
          variant="contained"
        />
      </Box>
      <BaseDialog
        leftActionsSlot={[
          <Button
            color="success"
            key="submit-btn"
            label="подтвердить"
            onClick={handleOrderAccept}
            variant="contained"
          />,
          <Button
            key="cancel-btn"
            label="отменить"
            onClick={handleCloseAcceptDialog}
            variant="outlined"
          />,
        ]}
        message={`Заказ #${originId} был создан через шлюз ${gateway?.name} работающий в тестовом режиме.
          Подтверждение заказа должно выполняться после его полной и успешной проверки.`}
        onClose={handleCloseAcceptDialog}
        open={openAcceptDialog}
        title="Подтверждение заказа"
      />
      <BaseDialog
        leftActionsSlot={[
          <Button
            color="error"
            key="decline-btn"
            label="отклонить"
            onClick={handleOrderDecline}
            variant="contained"
          />,
          <Button
            key="cancel-btn"
            label="отменить"
            onClick={handleCloseDeclineDialog}
            variant="outlined"
          />,
        ]}
        message={`Заказ #${originId} был создан через шлюз ${gateway?.name} работающий в тестовом режиме.
        Отклонение заказа должно выполняться после его полной проверки, по итогам которой были найдены ошибки.`}
        onClose={handleCloseDeclineDialog}
        open={openDeclineDialog}
        title="Отклонение заказа"
      />
      <BaseDialog
        leftActionsSlot={
          <Button
            label="перейти к настройкам шлюза"
            onClick={() => {
              navigate(`/gateways/${encodeURIComponent(gateway ? gateway.id : '')}`);
            }}
            variant="outlined"
          />
        }
        message={`Заказ #${originId} успешно подтверждён.
        Вы можете перейти к настройкам шлюза ${gateway?.name} чтобы перевести его в стандартный режим работы.`}
        onClose={async () => {
          await queryClient.refetchQueries(INVALIDATE_RESOURCES);
          handleCloseRedirectAcceptDialog();
        }}
        open={openRedirectAcceptDialog}
        title="Заказ подвержден"
      />
      <BaseDialog
        leftActionsSlot={
          <Button
            label="перейти к настройкам шлюза"
            onClick={() => {
              navigate(`/gateways/${encodeURIComponent(gateway ? gateway.id : '')}`);
            }}
            variant="outlined"
          />
        }
        message={`Заказ #${originId} отклонён.
        Вы можете перейти к настройкам шлюза ${gateway?.name} чтобы изменить их.`}
        onClose={async () => {
          await queryClient.refetchQueries(INVALIDATE_RESOURCES);
          handleCloseRedirectDeclineDialog();
        }}
        open={openRedirectDeclineDialog}
        title="Заказ отклонен"
      />
    </StyledBox>
  );
};
