import { styled } from '@mui/material/styles';

import { appColors } from '../../../../../themes/variables';
import { ListItem } from '../../../../mui/List';

export const NotificationPopperListItem = styled(ListItem)({
  alignItems: 'flex-start',
  padding: '16px 24px 16px 16px',
  borderBottom: `1px solid ${appColors.divider}`,
});
