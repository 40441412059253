import { BoxProps } from 'shared/mui/Box';

import { ActionsStyled, WrapperStyled } from './FilterToolbar.styled';

export type FilterToolbarProps = {
  leftActionsSlot?: JSX.Element | false | (JSX.Element | false)[];
  rightActionsSlot?: JSX.Element | false | (JSX.Element | false)[];
} & BoxProps;

export const FilterToolbar: React.FC<FilterToolbarProps> = ({
  leftActionsSlot,
  rightActionsSlot,
  ...restProps
}) => {
  return (
    <WrapperStyled isOnlyRightSlot={!leftActionsSlot} {...restProps}>
      {leftActionsSlot && <ActionsStyled>{leftActionsSlot}</ActionsStyled>}
      {rightActionsSlot && <ActionsStyled>{rightActionsSlot}</ActionsStyled>}
    </WrapperStyled>
  );
};
