import { useCallback, useRef, useState } from 'react';

import DragIcon from '@mui/icons-material/DragIndicator';
import { FieldTitle, useResourceContext } from 'ra-core';
import { Switch } from 'shared/mui/Switch';
import { Typography } from 'shared/mui/Typography';

import { StyledLi } from './FieldToggle.styled';

export type FieldToggleProps = {
  selected?: boolean;
  label?: string;
  onToggle?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onMove?: (arr: string[]) => void;
  serviceRow?: boolean;
  source?: string;
  index: string;
};

export const FieldToggle: React.FC<FieldToggleProps> = ({
  selected,
  label,
  onToggle,
  onMove,
  serviceRow,
  source,
  index,
}) => {
  const [initialCoors, setInitialCoor] = useState<number | null>(null);
  const resource = useResourceContext();
  const dropIndex = useRef<number | null>(null);
  const dropIndexes = useRef<number[] | null>(null);
  const x = useRef<number | null>(null);
  const y = useRef<number | null>(null);

  const handleDocumentDragOver = useCallback((event: DragEvent) => {
    x.current = event.clientX;
    y.current = event.clientY;
  }, []);

  const handleDragStart = () => {
    dropIndexes.current = null;
    document.addEventListener('dragover', handleDocumentDragOver);
  };

  const handleDrag = (event: React.DragEvent<HTMLLIElement>) => {
    const selectedItem = event.target as HTMLElement;
    selectedItem.classList.add('drag-active');
    const list = selectedItem.closest('ul');
    if (x.current == null || y.current == null) {
      return;
    }
    const elementAtDragCoordinates = document.elementFromPoint(x.current, y.current);

    if (!initialCoors) {
      setInitialCoor(selectedItem.getBoundingClientRect().y);
    }

    let dropItem =
      elementAtDragCoordinates === null ? selectedItem : elementAtDragCoordinates.closest('li');

    if (!dropItem) {
      return;
    }

    // Проверка, является ли dropItem элементом с serviceRow === true
    if (dropItem && dropItem.getAttribute('data-service-row') === 'true') {
      // Запрещаем перемещение этого столбца.
      return;
    }

    if (dropItem.classList.contains('dragIcon')) {
      dropItem = dropItem.parentNode as HTMLElement;
    }
    if (dropItem === selectedItem) {
      return;
    }
    if (list === (dropItem?.parentNode as HTMLElement)?.closest('ul')) {
      dropIndex.current = Number(dropItem.dataset.index);
      if (dropItem === selectedItem.nextSibling) {
        dropItem = dropItem.nextSibling as HTMLElement;
      }
      list?.insertBefore(selectedItem, dropItem);
    }
  };

  const handleDragEnd = (event: React.DragEvent<HTMLLIElement>) => {
    const selectedItem = event.target as HTMLElement;
    const list = selectedItem.closest('ul');

    const listItems = list?.querySelectorAll('li');
    const indices =
      listItems &&
      (Array.from(listItems).map((item) =>
        (item as HTMLElement).getAttribute('data-index'),
      ) as string[]);

    const elementFromPoint =
      x.current != null && y.current != null
        ? document.elementFromPoint(x.current, y.current)
        : null;

    let dropItem =
      x.current == null || y.current == null || elementFromPoint === null
        ? selectedItem
        : elementFromPoint.closest('li');

    if (y.current !== null && list && !dropItem) {
      const closestUL = selectedItem.closest('ul');
      if (closestUL && y.current > closestUL.getBoundingClientRect().bottom) {
        dropItem = list.lastChild as HTMLElement;
      } else {
        dropItem = list.firstChild as HTMLElement;
      }
    }

    if (
      dropItem &&
      list === dropItem.closest('ul') &&
      selectedItem.getBoundingClientRect().y !== initialCoors &&
      indices
    ) {
      onMove?.(indices);
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
    setInitialCoor(null);
    dropIndexes.current = null;
    selectedItem.classList.remove('drag-active');
    document.removeEventListener('dragover', handleDocumentDragOver);
  };

  const handleDragOver = (event: React.DragEvent<HTMLLIElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  return (
    <StyledLi
      data-index={index}
      data-service-row={serviceRow}
      draggable={onMove ? 'true' : undefined}
      key={source}
      onDrag={onMove ? handleDrag : undefined}
      onDragEnd={onMove ? handleDragEnd : undefined}
      onDragOver={onMove ? handleDragOver : undefined}
      onDragStart={onMove ? handleDragStart : undefined}
    >
      <label htmlFor={`switch_${index}`}>
        <Switch
          checked={selected}
          disabled={serviceRow}
          id={`switch_${index}`}
          name={index}
          onChange={onToggle}
          size="small"
          sx={{ mr: 0.5, ml: -0.5 }}
        />
        <Typography color={serviceRow ? 'secondary' : undefined} component="span" variant="body2">
          <FieldTitle label={label} resource={resource} source={source} />
        </Typography>
      </label>
      {onMove && <DragIcon className="dragIcon" color="disabled" fontSize="small" />}
    </StyledLi>
  );
};
