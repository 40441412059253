import { EMPTY_FIELD } from 'constants/emptyField';

import { format } from 'date-fns';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

type Props = {
  activityUpdatedAt: number;
};

export const ApiClientsLastActivityInactive: React.FC<Props> = ({ activityUpdatedAt }) => {
  return activityUpdatedAt ? (
    <Stack>
      <Typography variant="body2">{`${format(activityUpdatedAt, 'dd/MM/yyyy')}`}</Typography>
      <Typography color={appColors.secondary.main} variant="body2">
        {`${format(activityUpdatedAt, 'HH:mm')}`}
      </Typography>
    </Stack>
  ) : (
    EMPTY_FIELD
  );
};
