import { Alert } from '../../../mui/Alert/Alert';
import { ListItem } from '../../../mui/List';

export const NotificationsPopperAlert: React.FC = () => {
  return (
    <ListItem
      sx={{
        padding: '16px',
      }}
    >
      <Alert severity="info" sx={{ width: '100%' }}>
        Пока нет уведомлений.
      </Alert>
    </ListItem>
  );
};
