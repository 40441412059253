import { Box, BoxProps } from 'shared/mui/Box';
import { Typography, TypographyTitle } from 'shared/mui/Typography';
import { TypographySize } from 'shared/mui/Typography/TypographyTitle/TypographyTitle';

import { ActionsStyled, CardHeaderStyled } from './CardHeader.styled';

export type CardHeaderProps = {
  title: React.ReactNode;
  description?: string;
  actions?: (React.ReactElement | false) | (React.ReactElement | false)[] | null;
  titleSize?: TypographySize;
  actionsProps?: BoxProps;
} & Omit<BoxProps, 'title'>;

export const CardHeader: React.FC<CardHeaderProps> = ({
  actions,
  description,
  title,
  titleSize,
  actionsProps,
  ...restProps
}: CardHeaderProps) => (
  <CardHeaderStyled {...restProps}>
    <Box flexDirection="column">
      <TypographyTitle size={titleSize}>{title}</TypographyTitle>
      {description && (
        <Typography fontSize={14} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
          {description}
        </Typography>
      )}
    </Box>
    <ActionsStyled {...actionsProps}>{actions && actions}</ActionsStyled>
  </CardHeaderStyled>
);
