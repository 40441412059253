import { styled } from '@mui/material/styles';
import { DatagridConfigurable } from 'shared/react-admin/Datagrids';
import { ImageField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { getColumnStyle } from 'utils';

export const DatagridConfigurableStyled = styled(DatagridConfigurable)({
  '.RaDatagrid-headerCell': {
    backgroundColor: appColors.tableHead,
  },
  '& .column-originId': getColumnStyle(168),
  '& .column-amount': {
    ...getColumnStyle(132),
    textAlign: 'right',
  },
  '& .column-createdAt': getColumnStyle(120),
  '& .column-completedAt': getColumnStyle(120),
  '& .column-currency': getColumnStyle(88),
  '& .column-commissionValueByCurrencyOrder': {
    ...getColumnStyle(132),
    textAlign: 'right',
  },
  '& .column-commissionValueByCurrencyCommission': {
    ...getColumnStyle(132),
    textAlign: 'right',
  },
  '& .column-commission': {
    minWidth: '184px',
  },
  '& .column-totalAmount': {
    ...getColumnStyle(168),
    textAlign: 'right',
  },
  '& .column-direction': getColumnStyle(136),
  '& .column-merchant': {
    minWidth: '168px',
  },
  '& .column-merchantAccount': {
    minWidth: '184px',
  },
  '& .column-gateway': {
    minWidth: '184px',
  },
  '& .column-method': {
    minWidth: '168px',
  },
  '& .column-merchantReference': getColumnStyle(168),
  '& .column-providerReferenceId': getColumnStyle(168),
});

export const ImageFieldStyled = styled(ImageField)({
  '& .RaImageField-image': {
    margin: 0,
    marginRight: '2px',
    maxWidth: 24,
    maxHeight: 24,
    minWidth: 24,
    minHeight: 24,
    objectFit: 'contain',
  },
});
