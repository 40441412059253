import { TableRowWrapper } from 'shared/mui/Table';
import { getColumnStyle } from 'utils';

type Props = {
  children: React.ReactNode;
  title: string;
};
export const OrderShowCommonInfoTableRowWrapper = ({ children, title }: Props): JSX.Element => (
  <TableRowWrapper
    contentStyle={{
      borderBottom: 'none',
      minHeight: '48px',
      height: '48px',
      padding: '4px 16px',
      ...getColumnStyle(214),
    }}
    headerStyle={{
      borderBottom: 'none',
      minHeight: '48px',
      height: '48px',
      padding: ' 4px 16px',
      lineHeight: '1.4',
      verticalAlign: 'middle',
      ...getColumnStyle(135),
    }}
    title={title}
  >
    {children}
  </TableRowWrapper>
);
