import { styled } from '@mui/material/styles';

import { appColors } from '../../../themes/variables';
import { Box } from '../../mui/Box';
import { TableCell, TableRow } from '../../mui/Table';

export const TableRowStyled = styled(TableRow)({
  height: 'auto',
  borderBottom: `1px solid ${appColors.divider}`,
});

export const TableRowCommentsStyled = styled(TableRow)({
  padding: '16px',
  borderBottom: `1px solid ${appColors.divider}`,
});

export const FileHeaderStyled = styled(Box)({
  padding: '16px 0',
  fontWeight: '500',
});

export const TableCellStyled = styled(TableCell)({
  padding: '0 24px 16px 24px',
  border: 'none',
});
