import { EMPTY_FIELD } from 'constants/emptyField';

import { useGetOne, useRecordContext } from 'react-admin';
import { Chip } from 'shared/mui/Chips';
import { Commission } from 'types';

type Props = {
  label?: string;
  source?: string;
  onClick: (record: Commission) => void;
};

export const OrdersReportListRuleField: React.FC<Props> = ({ onClick }) => {
  const record = useRecordContext();
  const { data: commission } = useGetOne(
    'commissions',
    { id: record?.commission },
    { enabled: !!record },
  );
  const { data: currency } = useGetOne(
    'currencies',
    { id: commission?.currency },
    { enabled: !!commission },
  );

  if (!commission) {
    return EMPTY_FIELD;
  }
  const { fix, percent } = commission;

  return (
    <Chip
      color="primary"
      label={`${currency?.alphaCode} ${fix ? '/ ' + fix : ''} ${percent ? '/ ' + percent + '%' : ''}`}
      onClick={() => onClick(commission)}
      sx={{ cursor: 'pointer' }}
    />
  );
};
