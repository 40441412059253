export const FILTER_RESET_VALUES: any = {
  appliedDate: [null, null, null],
  transactionsAppliedDate: [null, null, null],
  company: null,
  merchant: null,
  provider: null,
  gateway: [],
  method: [],
  direction: null,
  currency: [],
  timezone: null,
};
