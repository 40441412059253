import { useState } from 'react';

import { OrdersReportFilterFormValues } from 'components/Finance/OrdersReport/OrdersReportFilterNew';
import { getOrdersReportSummary } from 'config/requests';
import { useQuery } from 'react-query';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

import { OrdersReportListShortReportAlertLi } from './components/OrdersReportListShortReportAlertLi';
import { OrdersReportListShortReportAlertTypography } from './components/OrdersReportListShortReportAlertTypography';
import { summaryConfig } from './constants/summaryConfig';
import { OrdersReportListShortReportAlertPreview } from './OrdersReportListShortReportAlertPreview';
import { AlertStyled, BoxStyled, UlStyled } from './OrdersReportListShortReportAlertStyled';
import { getOrdersReportListAlertTitle } from './utils/getOrdersReportListAlertTitle';

type Props = {
  filterValues: OrdersReportFilterFormValues;
};

export const OrdersReportListShortReportAlert: React.FC<Props> = ({ filterValues }) => {
  const [isShowAlert, setIsShowAlert] = useState(true);
  const {
    data: summaryData,
    refetch,
    isFetching,
  } = useQuery(
    ['getOrdersReportSummary', filterValues],
    () => getOrdersReportSummary(filterValues),
    {
      enabled: false,
    },
  );

  const dateGte = filterValues?.createdAt?.gte;
  const dateLte = filterValues?.createdAt?.lte;

  if (!isShowAlert || !dateGte || !dateLte) {
    return null;
  }

  if (!summaryData) {
    return <OrdersReportListShortReportAlertPreview getSummary={refetch} isFetching={isFetching} />;
  }

  return (
    <AlertStyled severity="warning" sx={{ marginBottom: '16px' }}>
      <BoxStyled>
        <Typography fontSize={16} fontWeight={500}>
          Сводка по запрошенному периоду создания заказов:
        </Typography>
        <CloseIconButton key="close-button" onClose={() => setIsShowAlert(false)} />
      </BoxStyled>
      <Typography variant="body2">
        {getOrdersReportListAlertTitle({
          dateGte,
          dateLte,
          timezone: filterValues?.timezone,
        })}
      </Typography>
      <UlStyled>
        {summaryConfig.map(({ link, name, status }) => {
          return (
            <OrdersReportListShortReportAlertLi
              filterValues={filterValues}
              key={status}
              link={link}
              name={name}
              status={status}
              value={summaryData[status]}
            />
          );
        })}
      </UlStyled>
      <OrdersReportListShortReportAlertTypography label="Итого: " />
      Исполнено:{' '}
      <OrdersReportListShortReportAlertTypography label={summaryData.executedOrderCount} /> / Не
      исполнено:{' '}
      <OrdersReportListShortReportAlertTypography label={summaryData.notExecutedOrderCount} />
    </AlertStyled>
  );
};
