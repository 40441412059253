import { Role } from 'constants/roles';

import { useState } from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { ReferenceManyField, WithListContext } from 'react-admin';
import { Collapse } from 'shared/mui/Collapse/Collapse';
import { ExpandIconButton } from 'shared/mui/IconButtons';
import { DatagridFieldLabel } from 'shared/mui/Labels/DatagridFieldLabel/DatagridFieldLabel';
import { Typography } from 'shared/mui/Typography';
import {
  ChipField,
  DateTimeField,
  FunctionField,
  NumberField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { CommissionShowDialog } from 'shared/widgets/CommissionShowDialog/CommissionShowDialog';
import { Commission } from 'types';

import { StyledDatagrid } from './OrderShowCommonInfoTransactions.styled';
import { OrderShowCommonInfoTransactionsCommissionChip } from './OrderShowCommonInfoTransactionsCommissionChip/OrderShowCommonInfoTransactionsCommissionChip';
import { EMPTY_FIELD } from '../../../../../constants';
import { OrderShowCommonInfoNoRecordsStub } from '../components/OrderShowCommonInfoNoRecordsStub/OrderShowCommonInfoNoRecordsStub';
import { StyledCard, StyledCardHeader } from '../OrderShowCommonInfo.styled';

export const OrderShowCommonInfoTransactions = (): JSX.Element => {
  const { hasResourceAccess, roles } = usePermissions();

  const { record, handleOpenRecordModal, handleCloseRecordModal, openRecordModal } =
    useRecordModal<Commission>();

  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledCard>
      <StyledCardHeader
        actions={<ExpandIconButton expanded={expanded} onClick={handleExpandClick} size="small" />}
        title="История транзакций:"
        titleSize="small"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ReferenceManyField
          label={false}
          reference="transactions"
          sort={{ field: 'id', order: 'ASC' }}
          target="order"
        >
          <WithListContext
            render={({ data, isLoading }) => {
              if (!data?.length && !isLoading) {
                return <OrderShowCommonInfoNoRecordsStub label="Нет транзакций" />;
              }
              return (
                <StyledDatagrid bulkActionButtons={false}>
                  <TextField label="ID Транзакции" source="originId" />
                  <DateTimeField label="Время создания" source="createdAt" />
                  {hasResourceAccess('transaction_types') && (
                    <ReferenceOneField
                      label="Тип транзакции"
                      reference="transaction_types"
                      source="type"
                      target="id"
                    >
                      <ChipField source="name" />
                    </ReferenceOneField>
                  )}
                  <NumberField
                    label="Сумма транзакции"
                    options={{ minimumFractionDigits: 2 }}
                    source="amount"
                  />
                  {hasResourceAccess('currencies') && (
                    <ReferenceOneField
                      label="Валюта"
                      reference="currencies"
                      source="currency"
                      target="id"
                    >
                      <TextField label="ID Транзакции" source="alphaCode" />
                    </ReferenceOneField>
                  )}
                  {hasResourceAccess('commissions') && (
                    <ReferenceArrayField
                      label="Комиссионное правило"
                      reference="commissions"
                      source="commissions"
                    >
                      <SingleFieldList
                        empty={<Typography>{EMPTY_FIELD}</Typography>}
                        linkType={false}
                      >
                        <FunctionField
                          render={(record: Commission) => (
                            <OrderShowCommonInfoTransactionsCommissionChip
                              onClick={() => handleOpenRecordModal(record)}
                            />
                          )}
                        />
                      </SingleFieldList>
                    </ReferenceArrayField>
                  )}
                  {!roles.includes(Role.Support) && (
                    <TextField
                      label={
                        <DatagridFieldLabel
                          primaryText="Сумма комиссии:"
                          secondaryText="в валюте заказа"
                        />
                      }
                      source="commissionValueByOrder"
                    />
                  )}
                  {!roles.includes(Role.Support) && (
                    <TextField
                      emptyText="Нет"
                      label={
                        <DatagridFieldLabel
                          primaryText="Сумма комиссии:"
                          secondaryText="в валюте правила"
                        />
                      }
                      source="commissionValueByRule"
                    />
                  )}
                </StyledDatagrid>
              );
            }}
          />
        </ReferenceManyField>
      </Collapse>
      {record && (
        <CommissionShowDialog
          commission={record}
          onClose={handleCloseRecordModal}
          open={openRecordModal}
        />
      )}
    </StyledCard>
  );
};
