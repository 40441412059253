import { Alert } from 'shared/mui/Alert/Alert';

type Props = {
  userName: string;
  userLastName: string;
};

export const OrdersReportListFinanceUserAlert: React.FC<Props> = ({ userName, userLastName }) => (
  <Alert severity="warning" sx={{ marginBottom: '8px' }}>
    {`Не удалось сформировать отчет по указанным критериям. 
      Возможно, для пользователя ${userName} ${userLastName} не настроена "Рабочая группа" и "Личные кабинеты", обратитесь к руководителю вашего подразделения для настроек доступа.`}
  </Alert>
);
