import { BOOLEAN_CHOICES } from 'constants/booleanChoices';

import { useGetPlatforms } from 'components/Merchants/hooks/useGetPlatforms';
import {
  FilterMultiselectChip,
  FilterTextChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

import { CONDITION_VALUES } from './constants/conditionValues';

export const MerchantsListFilterChips: React.FC = () => {
  const platforms = useGetPlatforms();

  return (
    <>
      <FilterTextChip label="Название" name="name">
        <TextInputShrink
          fullWidth={true}
          helperText={false}
          key="name"
          label="Название"
          resettable
          source="name"
          variant="outlined"
        />
      </FilterTextChip>
      <FilterTextChip
        getName={(filterValue: boolean) =>
          CONDITION_VALUES.find((value) => value.id === filterValue)?.name as string
        }
        isNotIgnoreFalseValue
        label="Состояние"
        name="archive"
      >
        <AutocompleteInputShrink
          choices={CONDITION_VALUES}
          helperText={false}
          label="Состояние"
          name="archive"
          source="archive"
          variant="outlined"
        />
      </FilterTextChip>
      <FilterMultiselectChip label="Компания" limitTags={1} name="companies" resource="companies">
        <ReferenceArrayInput
          isFilter={true}
          name="companies"
          perPage={200}
          reference="companies"
          source="companies"
        >
          <AutocompleteMultiselectInputShrink
            helperText={false}
            label="Компания"
            name="companies"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterTextChip
        getName={(filterValue: string) =>
          platforms.find((value) => value.id === filterValue)?.label as string
        }
        label="Платформа"
        name="platform"
      >
        <SelectInputShrink
          choices={platforms}
          fullWidth
          helperText={false}
          label="Платформа"
          name="platform"
          optionText="label"
          size="small"
          source="platform"
          variant="outlined"
        />
      </FilterTextChip>
      <FilterTextChip
        getName={(filterValue: boolean) =>
          BOOLEAN_CHOICES.find((value) => value.id === filterValue)?.name as string
        }
        isNotIgnoreFalseValue
        label="Партнерский"
        name="partner"
      >
        <AutocompleteInputShrink
          choices={BOOLEAN_CHOICES}
          helperText={false}
          label="Партнерский"
          name="partner"
          source="partner"
          variant="outlined"
        />
      </FilterTextChip>
    </>
  );
};
