import { styled } from '@mui/material';
import { ListItemText } from 'shared/mui/List';
import { Menu } from 'shared/mui/Menu/Menu';

export const MenuStyled = styled(Menu)({
  borderRadius: '12px',
  '.MuiPopover-paper': {
    borderRadius: '8px!important',
  },
  '.MuiList-root': {
    padding: '0!important',
  },
  '.MuiMenuItem-root': {
    margin: '0!important',
  },
});

export const ListItemTextStyled = styled(ListItemText)({
  '.MuiListItemText-primary': {
    fontSize: '14px',
  },
});
