import * as React from 'react';

import { Card } from '@mui/material';
import { useNotify } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs/Dialog/Dialog';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

import { queryClient } from '../../../../App';
import { CommissionStatuses } from '../../../../constants/commissionStatuses';
import { Commission } from '../../../../types';
import { getId } from '../../../../utils';
import { truncateString } from '../../../../utils/truncateString';
import { useUpdateCommissionsStatus } from '../../hooks/useUpdateCommissionsStatus';

type Props = {
  record: Commission | undefined;
} & DialogProps;

export const CommissionsShowImplementDialog: React.FC<Props> = ({ record, ...restProps }) => {
  const form = useForm();
  const notify = useNotify();

  const { mutate: updateCommissionsStatus } = useUpdateCommissionsStatus();

  const onClose = () => {
    form.reset();
    restProps.onClose();
  };

  const onSubmit = () => {
    updateCommissionsStatus(
      {
        id: getId(record?.id),
        data: {
          status: CommissionStatuses.Implemented,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          onClose();
        },
        onError: (error: any) => notify(error?.data?.errors[0]?.title, { type: 'error' }),
      },
    );
  };

  return (
    <Dialog {...restProps} fullWidth transitionDuration={0}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Card>
            <CardHeader
              actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
              title="Внедрение комиссии"
            />
            <CardContent>
              <Typography>
                После подтверждения комиссия {truncateString(record?.name || '', 50)} будет
                переведена в статус «Внедрена» и начнет применяться при формировании «Заказов» с
                текущего момента.
              </Typography>
            </CardContent>
            <CardActionsButtons
              rightActionsSlot={[
                <CancelButton
                  key="back-button"
                  label="Отменить"
                  onClick={onClose}
                  variant="outlined"
                />,
                <Button key="ok-button" label="Подтвердить" type="submit" variant="contained" />,
              ]}
            />
          </Card>
        </form>
      </FormProvider>
    </Dialog>
  );
};
