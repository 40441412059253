import { styled } from '@mui/material/styles';
import { DatagridWithStickyActions } from 'shared/react-admin/Datagrids/DatagridWithStickyActions/DatagridWithStickyActions';
import { getColumnStyle } from 'utils';

export const StyledDatagrid = styled(DatagridWithStickyActions)({
  '& .column-name': getColumnStyle(200),
  '& .column-filePath': getColumnStyle(90),
  '& .column-undefined': { width: '100%' },
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});
