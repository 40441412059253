import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SimpleForm, useCreate, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreatePage } from 'shared/react-admin/Pages/CreatePage/CreatePage';

import { CommissionsCreateSuccessDialog } from './CommissionsCreateSuccessDialog/CommissionsCreateSuccessDialog';
import { queryClient } from '../../../App';
import { useGoBack } from '../../../hooks/useGoBack';
import { useRecordModal } from '../../../hooks/useRecordModal';
import { Commission } from '../../../types';
import {
  CommissionsForm,
  CommissionsFormValues,
} from '../components/CommissionsForm/CommissionsForm';
import { COMMISSIONS_SCHEMA } from '../schemas/schema';
import { transform } from '../utils/transform';

export const CommissionsCreate: React.FC = () => {
  const { goBack } = useGoBack();
  const [create] = useCreate();
  const notify = useNotify();

  const [errors, setErrors] = useState<string[]>([]);
  const { handleOpenRecordModal, openRecordModal, record, handleCloseRecordModal } =
    useRecordModal<Commission>();

  const onSubmit = async (data: CommissionsFormValues) => {
    const transformedData = transform(data);

    await create(
      'commissions',
      {
        data: {
          ...transformedData,
        },
      },
      {
        onSuccess: async (data: Commission) => {
          await queryClient.invalidateQueries(['commissions']);
          handleOpenRecordModal(data);
        },
        onError: (error: any) => {
          notify(error?.message, { type: 'error' });
          setErrors(error?.message.split('\n'));
        },
      },
    );
  };

  return (
    <CreatePage listName="Комиссии" title="Создание новой комиссии">
      <SimpleForm
        defaultValues={{
          startDate: null,
          endDate: null,
          zeroed: false,
        }}
        onSubmit={onSubmit as any}
        resolver={yupResolver<FieldValues>(COMMISSIONS_SCHEMA)}
        sx={{ '&.MuiCardContent-root': { padding: 0 } }}
        toolbar={false}
      >
        <CardContent sx={{ width: '100%' }}>
          <CommissionsForm errors={errors} />
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButton key="save-button" />,
            <CancelButton key="cancel-button" onClick={goBack} variant="outlined" />,
          ]}
        />
      </SimpleForm>
      <CommissionsCreateSuccessDialog
        onClose={handleCloseRecordModal}
        open={openRecordModal}
        record={record}
      />
    </CreatePage>
  );
};
