import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { format as formatDate } from 'date-fns';
import { ru } from 'date-fns/locale';
import { DateObject } from 'react-multi-date-picker';
import { Autocomplete } from 'shared/mui/Autocomplete/Autocomplete';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { TIMEZONES, TimezoneType } from 'shared/mui/DatePicker/constants/timezones';
import { TextField } from 'shared/mui/Fields';

import {
  PopoverToolbarDatesStyled,
  PopoverToolbarStyled,
} from './DatePickerTimezoneToolbar.styled';

export type DatePickerToolbarProps = {
  title?: string;
  start: DateObject | null;
  end: DateObject | null;
  format?: string;
  timezone: string;
  onChangeTimezone: (v: string | null) => void;
};

export const DatePickerTimezoneToolbar: React.FC<DatePickerToolbarProps> = ({
  title = 'Выберите диапазон дат',
  start,
  end,
  format = 'd MMMM',
  timezone,
  onChangeTimezone,
}) => {
  const [timezoneInput, setTimezoneInput] = useState<TimezoneType | null>(
    TIMEZONES.find((tz) => tz.name === timezone) || null,
  );

  useEffect(() => {
    onChangeTimezone(timezoneInput?.name || DEFAULT_TIMEZONE);
  }, [timezoneInput]);

  useEffect(() => {
    setTimezoneInput(TIMEZONES.find((tz) => tz.name === timezone) || null);
  }, [timezone]);

  return (
    <PopoverToolbarStyled>
      <Typography variant="overline">{title}</Typography>
      <PopoverToolbarDatesStyled>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '275px' }}>
            <Typography component="h6" variant="h6">
              {start ? formatDate(start.valueOf(), format, { locale: ru }) : 'Начало'}
            </Typography>
            <Typography component="h5" variant="h5">
              &nbsp;–&nbsp;
            </Typography>
            <Typography component="h6" variant="h6">
              {end ? formatDate(end.valueOf(), format, { locale: ru }) : 'Конец'}
            </Typography>
          </Box>
          <Autocomplete
            getOptionLabel={(option) => `${option.name} (${option.offset})`}
            isOptionEqualToValue={(option, value) => option?.name === value?.name}
            onChange={(_e, value) => setTimezoneInput(value)}
            options={TIMEZONES}
            renderInput={(params) => (
              <TextField {...params} label="Часовой пояс" variant="outlined" />
            )}
            sx={{ width: '250px' }}
            value={timezoneInput}
          />
        </Box>
      </PopoverToolbarDatesStyled>
    </PopoverToolbarStyled>
  );
};
