import { NUMBER_BOOLEAN_CHOICES } from 'constants/numberBooleanChoices';

import { Controller, useFormContext } from 'react-hook-form';
import { DateTimeRangePicker } from 'shared/mui/DatePicker/DateTimeRangePicker/DateTimeRangePicker';
import {
  FilterDateTimeRangeChip,
  FilterMultiselectChip,
  FilterTextChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

export const UsersListFilterChips: React.FC = () => {
  const { control } = useFormContext();

  return (
    <>
      <FilterDateTimeRangeChip label="Последняя активность" name="appliedDate">
        <Controller
          control={control}
          name="appliedDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <DateTimeRangePicker
              label="Последняя активность / период"
              maxDate={new Date()}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </FilterDateTimeRangeChip>
      <FilterTextChip label="Фамилия" name="lastName">
        <TextInputShrink
          fullWidth
          helperText={false}
          key="lastName"
          label="Фамилия"
          name="lastName"
          source="lastName"
          variant="outlined"
        />
      </FilterTextChip>
      <FilterTextChip label="Имя" name="firstName">
        <TextInputShrink
          fullWidth
          helperText={false}
          key="firstName"
          label="Имя"
          name="firstName"
          source="firstName"
          variant="outlined"
        />
      </FilterTextChip>

      <FilterMultiselectChip label="Роль" limitTags={1} name="roleEntities" resource="roles">
        <ReferenceArrayInput
          isFilter={true}
          name="roleEntities"
          perPage={100}
          reference="roles"
          source="roleEntities"
        >
          <AutocompleteMultiselectInputShrink
            helperText={false}
            label="Роль"
            limitTags={1}
            name="roleEntities"
            optionText="name"
            size="small"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterTextChip
        getName={(filterValue: string) =>
          NUMBER_BOOLEAN_CHOICES.find((v) => filterValue === v.id)?.label || ''
        }
        label="Активность"
        name="enabled"
      >
        <AutocompleteInputShrink
          choices={NUMBER_BOOLEAN_CHOICES}
          fullWidth
          helperText={false}
          key="enabled"
          label="Активность"
          name="enabled"
          optionText="label"
          source="enabled"
          variant="outlined"
        />
      </FilterTextChip>
      <FilterTextChip label="Reddy" name="reddyNumber">
        <TextInputShrink
          fullWidth
          helperText={false}
          key="reddyNumber"
          label="Reddy"
          name="reddyNumber"
          source="reddyNumber"
          variant="outlined"
        />
      </FilterTextChip>
      <FilterTextChip label="E-mail" name="email">
        <TextInputShrink
          fullWidth
          helperText={false}
          key="email"
          label="E-mail"
          name="email"
          source="email"
          variant="outlined"
        />
      </FilterTextChip>
      <FilterTextChip
        getName={(filterValue: string) =>
          NUMBER_BOOLEAN_CHOICES.find((v) => filterValue === v.id)?.label || ''
        }
        label="2FA"
        name="twoFactor"
      >
        <AutocompleteInputShrink
          choices={NUMBER_BOOLEAN_CHOICES}
          fullWidth
          helperText={false}
          key="twoFactor"
          label="2FA"
          name="twoFactor"
          optionText="label"
          source="twoFactor"
          variant="outlined"
        />
      </FilterTextChip>
    </>
  );
};
