import { updateNotificationsViewedStatus } from 'config/requests';
import { useMutation } from 'react-query';

type UseChangeViewedStatusProps = {
  ids?: string[];
  onError?: (error: any) => void;
  onSuccess?: () => void;
};

type UseChangeViewedStatusReturnValue = {
  changeViewedStatus: () => void;
};

export function useChangeViewedStatus({
  ids,
  onSuccess,
  onError,
}: UseChangeViewedStatusProps): UseChangeViewedStatusReturnValue {
  const { mutate: changeViewedStatus } = useMutation(
    ['notificationIsViewed'],
    () => updateNotificationsViewedStatus(ids),
    {
      retry: false,
      onSuccess,
      onError,
    },
  );

  return { changeViewedStatus };
}
