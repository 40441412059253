import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { ListContextProvider, useListController } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { CreateButton } from 'shared/mui/Buttons';
import { PageHeader } from 'shared/mui/PageHeader';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsEditButton,
  DatagridActionsShowButton,
  DatagridActionsUserActionsButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import { DateTimeField, TextField } from 'shared/react-admin/Fields';
import { Pagination } from 'shared/react-admin/Pagination/Pagination';
import { Toolbar } from 'shared/react-admin/Toolbars/Toolbar';
import { GatewayRuleRecord, Permission } from 'types';

import { MerchantShowRulesDeleteDialog } from './components/MerchantShowRulesDeleteDialog';
import { MerchantShowRulesNameField } from './components/MerchantShowRulesNameField';
import { MerchantShowRulesListFilter } from './MerchantShowRulesListFilter';
import { StyledDatagrid } from './MerchantShowRulesListStyled.styled';

type Props = {
  merchantId?: string;
  merchantName?: string;
};

export const MerchantShowRulesList: React.FC<Props> = ({ merchantId, merchantName }) => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const resource = `merchants/${merchantId}/gateway_rules`;
  const listContext = useListController({
    resource: `merchants/${merchantId}/gateway_rules`,
    sort: { field: 'id', order: 'DESC' },
    disableSyncWithLocation: true,
  });

  const {
    record: recordDeleteDialog,
    handleCloseRecordModal: handleCloseDeleteDialog,
    openRecordModal: openDeleteDialog,
    handleOpenRecordModal: handleOpenDeleteDialog,
  } = useRecordModal<GatewayRuleRecord>();

  return (
    <ListContextProvider value={listContext}>
      <PageHeader
        actions={[
          hasResourcePermissions('gateway_rules', [Permission.Full, Permission.Create]) && (
            <CreateButton
              key="create-rules"
              resource="gateway_rules"
              sx={{ marginTop: '8px', padding: '3px 16px' }}
              target="_blank"
            />
          ),
        ]}
        headerTitle={{
          titleText: 'Список правил мерчанта',
          titleSize: 'small',
        }}
      />
      <MerchantShowRulesListFilter />
      {listContext.data?.length ||
      listContext.isLoading ||
      (!listContext.data?.length && !!Object.keys(listContext.filterValues).length) ? (
        <>
          <StyledDatagrid>
            <TextField label="ID" sortable={false} source="originId" />
            <MerchantShowRulesNameField label="Название" queryKey={resource} source="name" />
            <DateTimeField label="Дата создания" source="createdAt" />
            <DateTimeField label="Дата редактир." source="updatedAt" />
            <DatagridActions
              render={(record: GatewayRuleRecord) => (
                <>
                  {hasResourceAccess('user_actions') && (
                    <DatagridActionsUserActionsButton
                      entityId={record?.originId}
                      entityName="gateway_rule"
                      key="user-action-link"
                      target="_blank"
                    />
                  )}
                  <DatagridActionsShowButton resource="gateway_rules" target="_blank" />
                  {hasResourcePermissions('gateway_rules', [
                    Permission.Full,
                    Permission.Update,
                  ]) && <DatagridActionsEditButton resource="gateway_rules" target="_blank" />}
                  {hasResourcePermissions('gateway_rules', [
                    Permission.Full,
                    Permission.Delete,
                  ]) && (
                    <DatagridActionsDeleteButton onClick={() => handleOpenDeleteDialog(record)} />
                  )}
                </>
              )}
            />
          </StyledDatagrid>
          <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Pagination sx={{ width: '100%' }} />
          </Toolbar>
        </>
      ) : (
        <Alert severity="info">{`Мерчанту ${merchantName} не добавлены правила.`}</Alert>
      )}
      <MerchantShowRulesDeleteDialog
        onClose={handleCloseDeleteDialog}
        open={openDeleteDialog}
        queryKey={resource}
        record={recordDeleteDialog}
      />
    </ListContextProvider>
  );
};
