import { useClearLSTemporary } from 'hooks/useClearLSTemporary';
import { SortPayload, useListController } from 'react-admin';
import { Order } from 'types';

import { OrdersListContent } from './OrderListContent';
import { AppList } from '../../Common/List';

// ToDo удалить вместе с хуком useClearLSTemporary через 1-3 мес (инициировано декабрь 2024)
// Удалим старые ключи из LS, которые сейчас уже не используются
const temporaryFnRemoveUnnusualKeysFromLS = () => {
  localStorage.removeItem('isNotFirstOrdersReportExportRender');
  localStorage.removeItem('isNotFirstOrdersReportListRender');
};

export const OrdersListNew = (): JSX.Element => {
  const { filterValues } = useListController<Order>({
    resource: 'orders',
    queryOptions: { enabled: false },
  });
  const isListFilterExist = Object.keys(filterValues).length > 0;

  // ToDo Спустя какое-то время (1-3 мес.) ее можно удалить, тк за этот период все пользователи уже посетят данный раздел.
  // (инициировано декабрь 2024)
  useClearLSTemporary(
    'isNotFirstOrdersListRender',
    'orders.datagrid',
    temporaryFnRemoveUnnusualKeysFromLS,
  );

  return (
    <AppList
      empty={false}
      pagination={<></>}
      queryOptions={{ enabled: isListFilterExist }}
      sort={{} as SortPayload}
    >
      <OrdersListContent />
    </AppList>
  );
};
