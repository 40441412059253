import { blue } from '@mui/material/colors';
import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';
import { User } from 'types';

import { StyledAvatar, StyledBox, StyledProfileBox } from './UserProfileField.styled';

export const UserProfileField: React.FC<{ showLink?: boolean }> = ({ showLink }) => {
  const { firstName, lastName, email, id } = useRecordContext<User>();

  return (
    <StyledBox>
      <StyledAvatar title={`${firstName} ${lastName}`}>
        {`${firstName[0]}${lastName[0]}`.toUpperCase()}
      </StyledAvatar>
      <StyledProfileBox>
        {showLink ? (
          <Link
            style={{ color: blue[700], fontSize: 14 }}
            to={{
              pathname: `/users/${encodeURIComponent(id)}/show`,
            }}
          >
            {firstName} {lastName}
          </Link>
        ) : (
          <Typography fontSize={14}>
            {firstName} {lastName}
          </Typography>
        )}
        <Typography fontSize={13} sx={{ color: appColors.secondary.main }}>
          {email}
        </Typography>
      </StyledProfileBox>
    </StyledBox>
  );
};
