import { styled } from '@mui/material/styles';
import { Box, BoxProps } from 'shared/mui/Box';

export type Props = { columnsCount?: number } & BoxProps;

export const BoxStyled = styled(Box)<Props>(({ columnsCount }) => ({
  alignItems: 'center',
  display: 'grid',
  gap: '16px ',
  gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
}));
