import { useState } from 'react';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import { useRecordContext } from 'react-admin';
import { IconButton } from 'shared/mui/IconButtons';
import { Stack } from 'shared/mui/Stack/Stack';
import { Tooltip } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';
import { OrderLog } from 'types';

type Props = {
  source: 'requestData' | 'responseData';
  label: string;
};
export const OrderShowLogsExpandCopyButton: React.FC<Props> = ({ source, label }) => {
  const [tooltipText, setTooltipText] = useState('');
  const record = useRecordContext<OrderLog>();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(record[source], null, 2));
      setTooltipText('Cкопировано');
      setTimeout(() => setTooltipText(''), 1000);
    } catch (e: unknown) {
      setTooltipText('Копирование не удалось');
    }
  };

  return (
    <Stack alignItems="center" direction="row" gap="8px">
      <Typography sx={{ fontWeight: 500 }} variant="body2">
        {label}
      </Typography>
      <Tooltip disableHoverListener open={!!tooltipText} title={tooltipText}>
        <IconButton onClick={handleCopy} size="small">
          <CopyAllIcon sx={{ fontSize: 'inherit' }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
