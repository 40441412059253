import { ImageFieldStyled } from 'components/Orders/OrdersListNew/OrdersListLocalDatagrid/OrdersListLocalDatagrid.styled';
import { usePermissions } from 'hooks/usePermissions';
import { RecordContextProvider, useGetOne, useShowController } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Table, TableBody } from 'shared/mui/Table';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, TextField } from 'shared/react-admin/Fields';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { appColors } from 'themes/variables';
import { Method, Order, OrderStatus } from 'types';

import { OrderShowCommonInfoStateAdjustmentsField } from './OrderShowCommonInfoStateAdjustmentsField';
import { OrderShowCommonInfoStateCardHeader } from './OrderShowCommonInfoStateCardHeader/OrderShowCommonInfoStateCardHeader';
import { OrderShowCommonInfoStateDateTimeField } from './OrderShowCommonInfoStateDateTimeField';
import { OrderShowCommonInfoStateTableRowWrapper } from './OrderShowCommonInfoStateTableRowWrapper';
import { EMPTY_FIELD } from '../../../../../constants';
import { StyledCard } from '../OrderShowCommonInfo.styled';

const colors = appColors.orderStatusBackground;

export const OrderShowCommonInfoState = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();
  const { record: order } = useShowController<Order>();

  const { data: status } = useGetOne<OrderStatus>(
    'new/order_statuses',
    { id: order?.status || '' },
    { enabled: !!order },
  );

  return (
    <RecordContextProvider value={order}>
      <StyledCard
        sx={{
          backgroundColor: status ? colors[status?.value as keyof typeof colors] : 'initial',
        }}
      >
        <OrderShowCommonInfoStateCardHeader />
        <Box sx={{ padding: '8px 0' }}>
          <Table>
            <TableBody>
              <OrderShowCommonInfoStateTableRowWrapper title="Корректировка суммы:">
                <OrderShowCommonInfoStateAdjustmentsField />
              </OrderShowCommonInfoStateTableRowWrapper>
              <OrderShowCommonInfoStateTableRowWrapper title="Направление:">
                <ReferenceField label="Направление" reference="directions" source="direction">
                  <TextField source="description" />
                </ReferenceField>
              </OrderShowCommonInfoStateTableRowWrapper>
              <OrderShowCommonInfoStateTableRowWrapper title="Метод:">
                <ReferenceOneField
                  emptyText={EMPTY_FIELD}
                  link={false}
                  reference="methods"
                  resource="methods"
                  sortable={false}
                  source="method"
                  target="id"
                >
                  <FunctionField
                    render={(record: Method) => (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {hasResourceAccess('method_logos') && (
                          <ReferenceOneField reference="method_logos" source="logo" target="id">
                            <ImageFieldStyled source="filePath" />
                          </ReferenceOneField>
                        )}
                        <Typography variant="body2">{record?.name}</Typography>
                      </Box>
                    )}
                  />
                </ReferenceOneField>
              </OrderShowCommonInfoStateTableRowWrapper>
              <OrderShowCommonInfoStateTableRowWrapper title="Обновлен:">
                <OrderShowCommonInfoStateDateTimeField />
              </OrderShowCommonInfoStateTableRowWrapper>
            </TableBody>
          </Table>
        </Box>
      </StyledCard>
    </RecordContextProvider>
  );
};
