import { Children } from 'react';

import { BoxProps } from 'shared/mui/Box';

import { BoxStyled } from './FilterFieldGroup.styled';

export type FilterFieldGroupProps = BoxProps;

export const FilterFieldGroup = ({ children, ...restProps }: FilterFieldGroupProps) => {
  const childrenCount = Children.count(children);

  return (
    <BoxStyled {...restProps} columnsCount={childrenCount}>
      {children}
    </BoxStyled>
  );
};
