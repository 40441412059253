import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { BulkActionProps } from 'react-admin';
import { Link } from 'react-router-dom';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { BaseDialog } from 'shared/mui/Dialogs';
import { getId } from 'utils';
import { createFilterQueryString } from 'utils/createFilterQueryString';

export const OrdersListBulkActionsNew: React.FC<BulkActionProps> = ({ selectedIds }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ids = selectedIds?.map((order) => getId(order));

  return (
    <>
      <Button
        label="Корректировка заказов"
        onClick={() => setIsOpen(true)}
        startIcon={<EditIcon />}
      />
      <BaseDialog
        message={`Подтвердите переход к разделу "Массовая корректировка заказов" для ${selectedIds?.length} заказов.`}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        rightActionsSlot={[
          <CancelButton
            key="cancel-button"
            label="Отменить"
            onClick={() => setIsOpen(false)}
            variant="outlined"
          />,
          <Button
            component={Link}
            key="ok-button"
            label="Подтвердить"
            onClick={() => setIsOpen(false)}
            target="_blank"
            to={{
              pathname: '/order_adjustments',
              search: createFilterQueryString({
                id: ids,
              }),
            }}
            variant="contained"
          />,
        ]}
        title="Перейти к корректировке заказов"
      />
    </>
  );
};
