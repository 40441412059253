import { useEffect, useState } from 'react';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import { RaRecord, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { Box } from 'shared/mui/Box';
import { appColors } from 'themes/variables';

import { TypographyStyled } from './CopyToClipboardLinkField.styled';
import { EMPTY_FIELD } from '../../../../constants';
import { IconButton } from '../../../mui/IconButtons';
import { Tooltip } from '../../../mui/Tooltip';
import { FunctionField, FunctionFieldProps } from '../FunctionField/FunctionField';

export type CopyToClipboardLinkFieldProps = {
  source?: string;
  value?: string;
  isTrimValue?: boolean;
  getLink: (value: RaRecord) => string;
  getValue?: (value: string) => string;
  target?: string;
} & Omit<FunctionFieldProps, 'render'>;

export const CopyToClipboardLinkField: React.FC<CopyToClipboardLinkFieldProps> = ({
  onClick,
  source,
  sx,
  value,
  isTrimValue,
  getValue,
  getLink,
  target = '_self',

  ...rest
}) => {
  const record = useRecordContext();
  const recordValue = value || record[source as string];
  const [tooltipText, setTooltipText] = useState('');
  const [currentValue, setCurrentValue] = useState(recordValue);

  useEffect(() => {
    if (isTrimValue && source && record[source as string]?.length > 12) {
      setCurrentValue(`${currentValue.slice(0, 6)}...${currentValue.slice(-4)}`);
    }
  }, []);

  if (!currentValue) {
    return <span>{EMPTY_FIELD}</span>;
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(recordValue);
      setTooltipText('Cкопировано');
      setTimeout(() => setTooltipText(''), 1000);
    } catch (e: unknown) {
      setTooltipText('Копирование не удалось');
    }
  };

  const renderLink = () => (
    <Link
      style={{ color: appColors.primary.main }}
      target={target}
      to={{ pathname: getLink(record) }}
    >
      <TypographyStyled sx={sx} variant="body2">
        {getValue ? getValue(currentValue) : currentValue}
      </TypographyStyled>
    </Link>
  );

  return (
    <FunctionField
      render={() => (
        <Box
          onClick={onClick}
          sx={{ position: 'relative', gap: '4px', display: 'flex', alignItems: 'center' }}
        >
          {currentValue === recordValue ? (
            renderLink()
          ) : (
            <Tooltip arrow title={recordValue}>
              {renderLink()}
            </Tooltip>
          )}
          <Tooltip TransitionProps={{ timeout: 0 }} open={!!tooltipText} title={tooltipText}>
            <IconButton onClick={handleCopy} size="small">
              <CopyAllIcon sx={{ fontSize: 'inherit' }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {...rest}
    />
  );
};
