import * as React from 'react';

import { useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Typography } from 'shared/mui/Typography';
import { CommissionStatusChipField } from 'shared/react-admin/Fields/CommissionStatusChipField/CommissionStatusChipField';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';

import { AvatarWrapperStyled, BoxStyled } from './CommissionsListComment.styled';
import { CommissionsListCommentUser } from './CommissionsListCommentUser/CommissionsListCommentUser';
import { EMPTY_FIELD } from '../../../../constants';

export const CommissionsListComment: React.FC = () => {
  const record = useRecordContext();

  return (
    <BoxStyled>
      <AvatarWrapperStyled>
        <ReferenceField link={false} reference="users" sortable={false} source="user">
          <CommissionsListCommentUser createdAt={record?.createdAt} />
        </ReferenceField>
        <ReferenceField
          emptyText={EMPTY_FIELD}
          label="Статус"
          link={false}
          reference="commission_statuses"
          source="updatedStatus"
        >
          <CommissionStatusChipField source="description" />
        </ReferenceField>
      </AvatarWrapperStyled>
      <Box sx={{ overflowX: 'auto' }}>
        <Typography>{record?.text}</Typography>
      </Box>
    </BoxStyled>
  );
};
