import { EMPTY_FIELD } from 'constants/emptyField';

import { JsonEditor, JsonEditorProps } from 'json-edit-react';
import { FieldProps, useRecordContext } from 'react-admin';
import { appColors } from 'themes/variables';

export type JsonFieldProps = FieldProps & Partial<JsonEditorProps> & { src?: Record<string, any> };

const jsonValueColor = 'rgba(25, 118, 210, 1)';

const theme = [
  'monoLight',
  {
    container: {
      backgroundColor: appColors.background,
      fontFamily: 'Roboto, monospace',
      fontSize: '14px',
      borderRadius: 0,
    },
    string: jsonValueColor,
    number: jsonValueColor,
    boolean: { color: jsonValueColor, fontStyle: 'normal' },
    null: { color: jsonValueColor, fontVariant: 'normal', fontWeight: 'normal' },
    iconCollection: 'rgb(0, 43, 54)',
    iconEdit: 'edit',
    iconDelete: 'rgb(203, 75, 22)',
    iconAdd: 'green',
    iconCopy: 'rgb(38, 139, 210)',
    iconOk: 'green',
    iconCancel: 'rgb(203, 75, 22)',
  },
] as JsonEditorProps['theme'];

export const JsonField = ({ source, src, ...restProps }: JsonFieldProps) => {
  const record = useRecordContext();

  if (!(source && record[source]) && !src) {
    return <span>{EMPTY_FIELD}</span>;
  }

  const jsonValue = src || record[source as string];

  return (
    <JsonEditor
      data={jsonValue}
      restrictAdd={true}
      restrictDelete={true}
      restrictEdit={true}
      theme={theme}
      {...restProps}
    />
  );
};
