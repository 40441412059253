import LockIcon from '@mui/icons-material/Lock';
import { useRecordContext } from 'react-admin';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';

import { ReactComponent as UnlockIcon } from '../../../../../images/unlockFilledPerson.svg';
import { useDatagridActionsContext } from '../context/datagridActionsContext';
import { ListItemTextStyled } from '../DatagridActions.styled';

export type DatagridActionsUserEntityPermissionsDialogButtonProps = {
  label?: string;
  onClick: VoidFunction;
};

export const DatagridActionsUserEntityPermissionsDialogButton: React.FC<
  DatagridActionsUserEntityPermissionsDialogButtonProps
> = ({ label, onClick }) => {
  const record = useRecordContext();
  const { onCloseMenu } = useDatagridActionsContext();
  const handleClick = () => {
    onClick();
    onCloseMenu();
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        {record?.permissionsCount ? <UnlockIcon fontSize="small" /> : <LockIcon fontSize="small" />}
      </ListItemIcon>
      <ListItemTextStyled
        primary={label || record?.permissionsCount ? 'Редактирование доступа' : 'Включение доступа'}
      />
    </MenuItem>
  );
};
