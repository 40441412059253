import { getUnixTime } from 'date-fns';

import { CommissionsFormValues } from '../components/CommissionsForm/CommissionsForm';

export const transform = (data: CommissionsFormValues): CommissionsFormValues => {
  return {
    ...data,
    min: data.min ? data.min + '' : null,
    max: data.max ? data.max + '' : null,
    fix: data.fix ? data.fix + '' : null,
    percent: data.percent ? data.percent + '' : null,
    startDate: data?.startDate ? getUnixTime(new Date(data?.startDate)) : null,
    endDate: data?.endDate ? getUnixTime(new Date(data?.endDate)) : null,
  };
};
