import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { useDatagridActionsContext } from 'shared/react-admin/Datagrids/DatagridActions/context/datagridActionsContext';
import { ListItemTextStyled } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActions.styled';

export type DatagridActionsLinksButtonProps = {
  onClick: VoidFunction;
};

export const DatagridActionsLinksButton: React.FC<DatagridActionsLinksButtonProps> = ({
  onClick,
}) => {
  const { onCloseMenu } = useDatagridActionsContext();
  const handleClick = () => {
    onClick();
    onCloseMenu();
  };

  return (
    <MenuItem onClick={handleClick} sx={{ width: '100%' }}>
      <ListItemIcon>
        <InsertLinkIcon color="secondary" />
      </ListItemIcon>
      <ListItemTextStyled primary="Поиск связей" />
    </MenuItem>
  );
};
