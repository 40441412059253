import MessageIcon from '@mui/icons-material/Message';
import { Identifier, useListContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { IconButton, IconButtonProps } from 'shared/mui/IconButtons';

type Props = IconButtonProps & { orderId: Identifier };

export const OrderAdjustmentsCommentButton: React.FC<Props> = ({ orderId, ...restProps }) => {
  const { data: orders } = useListContext();
  const { watch } = useFormContext();
  const recordIndex = orders?.findIndex((order) => order.id === orderId);
  const comment = watch(`orderAdjustments.${recordIndex}.comment`);

  return (
    <IconButton color={comment ? 'primary' : 'secondary'} size="small" {...restProps}>
      <MessageIcon fontSize="inherit" />
    </IconButton>
  );
};
