import { Controller, useFormContext } from 'react-hook-form';
import { DateTimeRangeTimezonePicker } from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import { useFilterContext } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterAutocompleteChip,
  FilterEnumAutocompleteChip,
  FilterMultiselectChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { FilterDateTimeRangeTimezoneChip } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips/FilterDateTimeRangeTimezoneChip/FilterDateTimeRangeTimezoneChip';
import {
  AutocompleteInputShrink,
  AutocompleteMultiselectInput,
  EnumAutocompleteInputShrink,
} from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency } from 'types';

export const OrdersReportFilterChips: React.FC = () => {
  const { control, resetField, setValue, watch } = useFormContext();
  const { setAppliedFilter, appliedFilters } = useFilterContext();
  const [company, merchant, provider, gateway] = watch([
    'company',
    'merchant',
    'provider',
    'gateway',
  ]);

  return (
    <>
      <FilterDateTimeRangeTimezoneChip label="Дата создания" name="appliedDate">
        <Controller
          control={control}
          name="appliedDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <DateTimeRangeTimezonePicker
              label="Дата создания от-до"
              maxDate={new Date()}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </FilterDateTimeRangeTimezoneChip>
      <FilterDateTimeRangeTimezoneChip label="Дата исполнения" name="transactionsAppliedDate">
        <Controller
          control={control}
          name="transactionsAppliedDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <DateTimeRangeTimezonePicker
              label="Дата исполнения от-до"
              maxDate={new Date()}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </FilterDateTimeRangeTimezoneChip>
      <FilterAutocompleteChip
        label="Компания"
        name="company"
        onApply={() => {
          setAppliedFilter('merchant', null);
          setAppliedFilter('provider', null);
          setAppliedFilter('gateway', []);
          setAppliedFilter('merchantAccount', []);
          setAppliedFilter('method', []);
        }}
        onDelete={() => {
          resetField('merchant', { keepDirty: true });
          resetField('provider', { keepDirty: true });
          resetField('gateway', { keepDirty: true });
          resetField('merchantAccount', { keepDirty: true });
          resetField('method', { keepDirty: true });

          setAppliedFilter('merchant', null);
          setAppliedFilter('provider', null);
          setAppliedFilter('gateway', []);
          setAppliedFilter('merchantAccount', []);
          setAppliedFilter('method', []);
        }}
        onReset={() => {
          setValue('merchant', appliedFilters?.['merchant']);
          setValue('provider', appliedFilters?.['provider']);
          setValue('gateway', appliedFilters?.['gateway']);
          setValue('merchantAccount', appliedFilters?.['merchantAccount']);
          setValue('method', appliedFilters?.['method']);

          setAppliedFilter('merchant', appliedFilters?.['merchant']);
          setAppliedFilter('provider', appliedFilters?.['provider']);
          setAppliedFilter('gateway', appliedFilters?.['gateway']);
          setAppliedFilter('merchantAccount', appliedFilters?.['merchantAccount']);
          setAppliedFilter('method', appliedFilters?.['method']);
        }}
        resource="companies"
      >
        <ReferenceInput
          filter={{ exists: { merchants: true }, archive: false }}
          perPage={100}
          reference="companies"
          source="company"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            helperText={false}
            label="Компания"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterAutocompleteChip
        label="Мерчант"
        name="merchant"
        onApply={() => {
          setAppliedFilter('provider', null);
          setAppliedFilter('gateway', []);
          setAppliedFilter('merchantAccount', []);
          setAppliedFilter('method', []);
        }}
        onDelete={() => {
          resetField('provider', { keepDirty: true });
          resetField('gateway', { keepDirty: true });
          resetField('merchantAccount', { keepDirty: true });
          resetField('method', { keepDirty: true });

          setAppliedFilter('provider', null);
          setAppliedFilter('gateway', []);
          setAppliedFilter('merchantAccount', []);
          setAppliedFilter('method', []);
        }}
        onReset={() => {
          setValue('provider', appliedFilters?.['provider']);
          setValue('gateway', appliedFilters?.['gateway']);
          setValue('merchantAccount', appliedFilters?.['merchantAccount']);
          setValue('method', appliedFilters?.['method']);

          setAppliedFilter('provider', appliedFilters?.['provider']);
          setAppliedFilter('gateway', appliedFilters?.['gateway']);
          setAppliedFilter('merchantAccount', appliedFilters?.['merchantAccount']);
          setAppliedFilter('method', appliedFilters?.['method']);
        }}
        resource="merchants"
      >
        <ReferenceInput
          enableGetChoices={() => !!company}
          filter={{
            companies: company,
            exists: { merchantAccounts: true },
            archive: false,
          }}
          perPage={100}
          reference="merchants"
          source="merchant"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            helperText={false}
            label="Мерчант"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterAutocompleteChip
        label="Провайдер"
        name="provider"
        onApply={() => {
          setAppliedFilter('gateway', []);
          setAppliedFilter('merchantAccount', []);
          setAppliedFilter('method', []);
        }}
        onDelete={() => {
          resetField('gateway', { keepDirty: true });
          resetField('merchantAccount', { keepDirty: true });
          resetField('method', { keepDirty: true });

          setAppliedFilter('gateway', []);
          setAppliedFilter('merchantAccount', []);
          setAppliedFilter('method', []);
        }}
        onReset={() => {
          setValue('gateway', appliedFilters?.['gateway']);
          setValue('merchantAccount', appliedFilters?.['merchantAccount']);
          setValue('method', appliedFilters?.['method']);

          setAppliedFilter('gateway', appliedFilters?.['gateway']);
          setAppliedFilter('merchantAccount', appliedFilters?.['merchantAccount']);
          setAppliedFilter('method', appliedFilters?.['method']);
        }}
        resource="providers"
      >
        <ReferenceInput
          enableGetChoices={() => !!merchant}
          filter={{ merchantAccounts: { merchants: merchant } }}
          perPage={100}
          reference="providers"
          source="provider"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            helperText={false}
            label="Провайдер"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterMultiselectChip
        label="Счет мерчанта"
        limitTags={1}
        name="merchantAccount"
        resource="merchant_accounts"
      >
        <ReferenceArrayInput
          enableGetChoices={() => !!merchant && !!provider}
          filter={{
            merchants: merchant,
            provider,
          }}
          name="merchantAccount"
          perPage={100}
          reference="merchant_accounts"
          source="merchantAccount"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Счет мерчанта"
            limitTags={1}
            name="merchantAccount"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip
        label="Шлюзы"
        limitTags={1}
        name="gateway"
        onApply={() => {
          setAppliedFilter('method', []);
        }}
        onDelete={() => {
          resetField('method', { keepDirty: true });
          setAppliedFilter('method', []);
        }}
        onReset={() => {
          setValue('method', appliedFilters?.['method']);

          setAppliedFilter('method', appliedFilters?.['method']);
        }}
        resource="gateways"
      >
        <ReferenceArrayInput
          enableGetChoices={() => !!merchant && !!provider}
          filter={{
            merchantAccounts: { merchants: merchant },
            provider,
          }}
          name="gateway"
          perPage={100}
          reference="gateways"
          source="gateway"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Шлюзы"
            limitTags={1}
            name="gateway"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip label="Методы" limitTags={1} name="method" resource="methods">
        <ReferenceArrayInput
          filter={{
            gateway,
          }}
          name="method"
          perPage={100}
          reference="methods"
          source="method"
        >
          <AutocompleteMultiselectInputShrink
            disabled={!gateway?.length}
            helperText={false}
            label="Методы"
            limitTags={1}
            name="method"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterEnumAutocompleteChip label="Направление" name="direction" resource="directions">
        <EnumAutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ description: searchText })}
          fullWidth
          helperText={false}
          label="Направление"
          name="direction"
          optionText="description"
          optionValue="value"
          resource="directions"
          source="direction"
          variant="outlined"
        />
      </FilterEnumAutocompleteChip>
      <FilterMultiselectChip label="Валюта" limitTags={1} name="currency" resource="currencies">
        <ReferenceArrayInput name="currency" perPage={50} reference="currencies" source="currency">
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ currency: searchText })}
            helperText={false}
            label="Валюта"
            limitTags={1}
            name="currency"
            optionText="name"
            renderChipLabel={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
    </>
  );
};
