import { useFilters } from 'hooks/useFilters';
import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBaseApplyButton } from 'shared/mui/Filter/FilterButtons/FilterBaseApplyButton/FilterBaseApplyButton';
import { FilterBaseResetButton } from 'shared/mui/Filter/FilterButtons/FilterBaseResetButton/FilterBaseResetButton';
import { Filter, FilterFieldGroup, FilterRow } from 'shared/mui/FilterNew';
import { TextInput } from 'shared/react-admin/Inputs';

export type MerchantShowRulesFilterValues = {
  name?: string | null;
};

const INITIAL_VALUES = {
  name: null,
};

export const MerchantShowRulesListFilter = () => {
  const { form, resetFilter, onSubmit } = useFilters<MerchantShowRulesFilterValues>(
    INITIAL_VALUES,
    INITIAL_VALUES,
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Filter>
          <FilterRow>
            <FilterFieldGroup>
              <TextInput
                InputLabelProps={{ shrink: true }}
                helperText={false}
                label="Название"
                source="name"
                sx={{ marginTop: '8px !important', marginBottom: '8px' }}
                variant="outlined"
              />
            </FilterFieldGroup>
            <FilterActions sx={{ marginTop: 0 }}>
              <FilterBaseApplyButton key="apply-button" type="submit" />
              <FilterBaseResetButton key="reset-button" onClick={resetFilter} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};
