import { useGetList } from 'react-admin';
import { FunctionField } from 'shared/react-admin/Fields';
import { Merchant, MerchantPlatform } from 'types';

type Props = {
  label?: string;
  source?: string;
};

export const MerchantPlatformField: React.FC<Props> = ({ label }) => {
  const { data } = useGetList<MerchantPlatform>('merchant_platforms');

  return (
    <FunctionField
      label={label}
      render={(record: Merchant) => {
        const platformName = data?.find(({ id }) => id === record.platform)?.name;

        return platformName;
      }}
    />
  );
};
