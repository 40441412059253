import VisibilityIcon from '@mui/icons-material/Visibility';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';

import { useDatagridActionsContext } from '../context/datagridActionsContext';
import { ListItemTextStyled } from '../DatagridActions.styled';

export type DatagridActionsShowDialogButtonProps = {
  onClick: VoidFunction;
};

export const DatagridActionsShowDialogButton: React.FC<DatagridActionsShowDialogButtonProps> = ({
  onClick,
}) => {
  const { onCloseMenu } = useDatagridActionsContext();
  const handleClick = () => {
    onClick();
    onCloseMenu();
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <VisibilityIcon fontSize="small" />
      </ListItemIcon>
      <ListItemTextStyled primary="Просмотр" />
    </MenuItem>
  );
};
