import { required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { StyledBox, StyledDialog } from './OrderAdjustmentsDialog.styled';
import { OrderAdjustmentsTypes } from '../../../constants/orderAdjustmentTypes';
import { getId } from '../../../utils';
import {
  ORDER_ADJUSTMENTS_ALERT_MESSAGE,
  ORDER_IMPACT_TO_BALANCE_ALERT_MESSAGE,
} from '../constants/orderAdjustementsAlertMessage';
import { OrderAdjustmentsFormValues } from '../hooks/useOrderAdjustmentsForm';

type Props = DialogProps & {
  onSubmit: VoidFunction;
};

export const OrderAdjustmentsDialog = ({ open, onClose, onSubmit }: Props) => {
  const { watch, handleSubmit } = useFormContext<OrderAdjustmentsFormValues>();

  const [adjustmentType, impactToBalance] = watch(['adjustmentType', 'impactToBalance']);

  const submitHandler = () => {
    onClose();
    onSubmit();
  };

  return (
    <StyledDialog maxWidth="md" onClose={onClose} open={open}>
      <form onSubmit={handleSubmit(submitHandler as any)}>
        <StyledBox>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Настройка корректировки заказов"
          />
          <CardContent>
            <ReferenceInput reference="adjustment_types" source="adjustmentType">
              <SelectInputShrink
                defaultValue={null}
                fullWidth
                label="Тип корректировки"
                optionText="description"
                size="medium"
                validate={required()}
                variant="outlined"
              />
            </ReferenceInput>
            {adjustmentType && (
              <Box sx={{ mb: '22px' }}>
                <Alert severity="info">
                  {
                    ORDER_ADJUSTMENTS_ALERT_MESSAGE?.[
                      getId(adjustmentType) as OrderAdjustmentsTypes
                    ]
                  }
                </Alert>
              </Box>
            )}
            <SelectInputShrink
              choices={[
                { value: true, label: 'С движением средств по балансу "Игрока"' },
                { value: false, label: 'Без движения средств по балансу "Игрока"' },
              ]}
              fullWidth
              label="Движение по балансу"
              optionText="label"
              optionValue="value"
              size="medium"
              source="impactToBalance"
              validate={required()}
              variant="outlined"
            />
            {impactToBalance !== null && (
              <Box sx={{ mb: '22px' }}>
                <Alert severity="info">
                  {ORDER_IMPACT_TO_BALANCE_ALERT_MESSAGE[Number(impactToBalance)]}
                </Alert>
              </Box>
            )}
          </CardContent>
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="save-button" label="Отправить" type="submit" variant="contained" />,
              <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
            ]}
          />
        </StyledBox>
      </form>
    </StyledDialog>
  );
};
