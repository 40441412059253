import { OrderStatuses } from 'constants/orderStatuses';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { appColors } from 'themes/variables';

export const StyledBox = styled(Box)({
  display: 'flex',
  width: '100%',
  padding: '14px 16px',
  background: 'linear-gradient(0deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), #ED6C02',
  borderRadius: '4px',
});

export const StyledWarningAmberIcon = styled(WarningAmberIcon)({
  width: '20px',
  height: '18px',
  marginTop: '3px',
  color: appColors.orderStatus[OrderStatuses.WaitingConfirmation],
});
