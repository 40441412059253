import { EMPTY_FIELD } from 'constants/emptyField';

import { AppOrderChangeStatusesChips } from 'components/Common/Fields/AppOrderChangeStatusesChips';
import { Datagrid, FunctionField, RaRecord, ReferenceManyField, TextField } from 'react-admin';
import { DateTimeField } from 'shared/react-admin/Fields';
import { Pagination } from 'shared/react-admin/Pagination/Pagination';

import { OrderRequestLogsExpand } from '../components/OrderRequestLogsExpand';

const OrdersShowRequestLogs = (): JSX.Element => {
  return (
    <ReferenceManyField
      label={false}
      pagination={<Pagination />}
      reference="order_events"
      sort={{ field: 'requestAt', order: 'DESC' }}
      target="orderId"
    >
      <Datagrid
        bulkActionButtons={false}
        expand={<OrderRequestLogsExpand />}
        sx={{
          '& .MuiTableCell-root': { padding: '16px' },
          '& .MuiTableCell-head': {
            verticalAlign: 'top',
          },
          '& .RaDatagrid-expandedPanel .MuiTableCell-body': { padding: '0!important' },
          '& .RaDatagrid-expandedPanel td': { padding: '16px 24px' },
        }}
      >
        <DateTimeField label="Дата и время" source="createdAt" />
        <FunctionField
          label="Время ответа"
          render={(record: RaRecord | undefined) =>
            record?.delay ? `~${record?.delay}ms` : EMPTY_FIELD
          }
        />
        <TextField label="ID" source="id" />
        <TextField label="Направление" source="direction" />
        <TextField label="Тип запроса" source="actionType" />
        <FunctionField
          label="Изменение статуса"
          render={(record: RaRecord | undefined) =>
            record?.statusChanges?.length ? (
              <>
                {(record.statusChanges as string[][]).map((idsArray, index) => (
                  <AppOrderChangeStatusesChips
                    key={`${idsArray[0]}${index}}`}
                    statusesIds={idsArray}
                  />
                ))}
              </>
            ) : (
              <div>{EMPTY_FIELD}</div>
            )
          }
        />
        <TextField label="URL" source="requestUrl" sx={{ wordBreak: 'break-all' }} />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default OrdersShowRequestLogs;
