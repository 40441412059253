import { styled } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Typography } from 'shared/mui/Typography';

export const TypographyStyled = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textDecoration: 'underline',
  color: blue[700],
  cursor: 'pointer',
});
