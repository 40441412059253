import { styled } from '@mui/material';
import { Alert } from 'shared/mui/Alert/Alert';

export const OlStyledBold = styled('ol')({
  fontSize: '14px',
  fontWeight: '600',
  paddingLeft: '20px',
});

export const OlStyled = styled('ol')({
  fontSize: '14px',
  paddingLeft: '20px',
});

export const UlStyled = styled('ul')({
  fontSize: '14px',
  fontWeight: '400',
  listStyleType: 'disc',
  paddingLeft: '4px',
});

export const LiStyled = styled('li')({
  fontSize: '14px',
  fontWeight: '500',
});

export const AlertStyled = styled(Alert)({
  margin: '16px 0 16px -18px',
});
