import { useGetList } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';
import { DateTimeRangeTimezonePicker } from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import { FilterDrawerRow } from 'shared/mui/NewFilter/FilterDrawer';
import { AutocompleteInputShrink, EnumAutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency } from 'types';
import { cleanEmpty } from 'utils/cleanEmpty';

export const OrderListFilter: React.FC = () => {
  const { control, watch, trigger, getValues } = useFormContext();

  const { data: orderStatuses } = useGetList('new/order_statuses');

  const triggerAmountLteValidation = () => trigger(['amountPenny.lte']);
  const triggerAmountGteValidation = () => trigger(['amountPenny.gte']);

  const merchantWatched = watch('merchant');
  const providerWatched = watch('provider');
  const gatewayWatched = watch('gateway');

  return (
    <>
      <Controller
        control={control}
        name="appliedDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <DateTimeRangeTimezonePicker
            label="Время создания / период"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            sx={{
              marginBottom: 0,
            }}
            value={value}
          />
        )}
      />
      <ReferenceInput perPage={100} reference="merchants" source="merchant">
        <AutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          helperText={false}
          label="Мерчант"
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        filter={cleanEmpty({ merchantAccounts: { merchants: merchantWatched } })}
        perPage={100}
        reference="providers"
        source="provider"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Провайдер"
          name="provider"
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        filter={cleanEmpty({
          merchantAccounts: { merchants: merchantWatched },
          provider: providerWatched,
        })}
        name="gateway"
        perPage={100}
        reference="gateways"
        source="gateway"
      >
        <AutocompleteMultiselectInputShrink
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Шлюзы"
          limitTags={1}
          name="gateway"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        filter={cleanEmpty({
          gateway: gatewayWatched,
        })}
        name="method"
        perPage={100}
        reference="methods"
        source="method"
      >
        <AutocompleteMultiselectInputShrink
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Методы"
          limitTags={1}
          name="method"
          optionText="name"
        />
      </ReferenceArrayInput>
      <EnumAutocompleteInputShrink
        filterToQuery={(searchText: string) => ({ description: searchText })}
        fullWidth
        helperText={false}
        label="Направление"
        name="direction"
        optionText="description"
        optionValue="value"
        resource="directions"
        source="direction"
        variant="outlined"
      />
      <AutocompleteMultiselectInputShrink
        defaultValue={getValues('status')}
        getOptionLabel={(option, options) =>
          options?.find((opt) => opt.value === option)?.description
        }
        helperText={false}
        label="Статус"
        limitTags={1}
        name="status"
        optionText="description"
        optionValue="value"
        options={orderStatuses || []}
      />
      <ReferenceArrayInput
        filter={{ active: true }}
        name="currency"
        perPage={50}
        reference="currencies"
        source="currency"
      >
        <AutocompleteMultiselectInputShrink
          filterToQuery={(searchText: string) => ({ currency: searchText })}
          helperText={false}
          label="Валюта"
          limitTags={1}
          name="currency"
          optionText="name  "
          renderChipLabel={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
        />
      </ReferenceArrayInput>
      <TextInputShrink
        helperText={false}
        label="Клиент"
        resettable
        source="customer.customerId"
        variant="outlined"
      />
      <FilterDrawerRow>
        <TextInputShrink
          helperText={false}
          label="Сумма от"
          onChange={triggerAmountLteValidation}
          resettable
          source="amountPenny.gte"
          sx={{ minWidth: 'auto' }}
          variant="outlined"
        />
        <TextInputShrink
          helperText={false}
          label="Сумма до"
          onChange={triggerAmountGteValidation}
          resettable
          source="amountPenny.lte"
          sx={{ minWidth: 'auto' }}
          variant="outlined"
        />
      </FilterDrawerRow>
      <TextInputShrink
        helperText={false}
        label="Причина отказа"
        resettable
        source="orderExternalError.reason"
        variant="outlined"
      />
    </>
  );
};
