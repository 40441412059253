import { Typography } from 'shared/mui/Typography';

type Props = {
  text: string;
};

export const TypographyBold: React.FC<Props> = ({ text }) => (
  <Typography component="span" sx={{ fontWeight: 500 }} variant="body2">
    {text}
  </Typography>
);
