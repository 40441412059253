import * as yup from 'yup';

export const deviationFieldSchema = yup
  .number()
  .typeError('Должно быть числом')
  .required('Обязательное поле')
  .positive('Должно быть положительным')
  .min(10, 'Должно быть больше 10')
  .max(90, 'Должно быть меньше 90');

export const minOrdersCountSchema = yup
  .number()
  .typeError('Должно быть числом')
  .required('Обязательное поле')
  .min(0, 'Должно быть положительным');
