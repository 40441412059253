import ArchiveIcon from '@mui/icons-material/Archive';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';

import { useDatagridActionsContext } from '../context/datagridActionsContext';
import { ListItemTextStyled } from '../DatagridActions.styled';

export type DatagridActionsArchiveButtonProps = {
  onClick: VoidFunction;
};

export const DatagridActionsArchiveButton: React.FC<DatagridActionsArchiveButtonProps> = ({
  onClick,
}) => {
  const { onCloseMenu } = useDatagridActionsContext();
  const handleClick = () => {
    onClick();
    onCloseMenu();
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <ArchiveIcon fontSize="small" sx={{ marginTop: '1px' }} />
      </ListItemIcon>
      <ListItemTextStyled primary="Архивировать" sx={{ fontSize: '14px' }} />
    </MenuItem>
  );
};
