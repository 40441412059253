import {
  EXPORT_FORMAT_NAMES,
  ExportFormats,
  ExportJobTypes,
  ExportTransportTypes,
  ReportNames,
} from 'constants/exportFormats';

import { useState } from 'react';

import { useGetCumulativeReport } from 'components/Finance/CumulativeReport/hooks/useGetCumulativeReport';
import { getUserInfo } from 'config/requests';
import { useReportExport } from 'hooks/queries/useReportExport';
import { ListContextProvider, useListController, useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import { ExportFormatsButton } from 'shared/mui/Buttons/ExportFormatsButton/ExportFormatsButton';
import { NotifyDialog } from 'shared/mui/Dialogs';
import { PageHeader } from 'shared/mui/PageHeader';
import { Typography } from 'shared/mui/Typography';
import { ExportEditButton } from 'shared/react-admin/Export/ExportEditButton/ExportEditButton';
import { ReportExportDialogMessage } from 'shared/widgets/ReportExport/ReportExportDialogMessage';
import { ExportFormat } from 'types';

import { EXPORT_FIELDS_CUMULATIVE_REPORT } from './constants/exportFields';
import { CumulativeReportListContent } from './CumulativeReportListContent';

export const CumulativeReportListNew: React.FC = () => {
  const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormats.Excel);
  const [isSendReportOnEmail, setIsSendReportOnEmail] = useState(false);
  const [isOpenNotifyDialog, setIsOpenNotifyDialog] = useState(false);
  const { data: user } = useQuery('me', getUserInfo);
  const notify = useNotify();

  const context = useListController({
    resource: 'v2/cumulative_report',
    queryOptions: { enabled: false },
  });

  const { cumulativeReport, isLoading } = useGetCumulativeReport(context.filterValues);

  const { mutate: exportReportMutate, isLoading: isExportLoading } = useReportExport({
    filters: {
      ...context.filterValues,
      exportType: exportFormat,
      jobType: ExportJobTypes.Cumulative,
      ...(isSendReportOnEmail && { notificationTransport: [ExportTransportTypes.Email] }),
    },
    preferenceKey: ReportNames.Cumulative,
    onSuccess: () => {
      notify(
        `Файл экспорта отчета по нарастающему итогу формируется ${isSendReportOnEmail ? 'и будет отправлен вам на почту' : ''}`,
        {
          type: 'info',
        },
      );
      setIsOpenNotifyDialog(false);
    },
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  return (
    <ListContextProvider value={{ ...context, data: cumulativeReport?.reportItems || [] }}>
      <PageHeader
        actions={[
          <ExportFormatsButton
            disabled={!Object.keys(context.filterValues).length}
            exportFormat={exportFormat}
            key="export-button"
            onChangeFormat={setExportFormat}
            onClick={() => setIsOpenNotifyDialog(true)}
          />,
        ]}
        headerTitle={{
          titleText: 'Отчет по нарастающему итогу',
          tooltipText:
            'Нарастающий итог – это сумма показателей, где к данным текущего периода добавляются суммы предыдущих периодов.',
        }}
        mt={3}
      />
      <CumulativeReportListContent cumulativeReport={cumulativeReport} isLoading={isLoading} />
      <NotifyDialog
        isLoading={isExportLoading}
        message={
          <ReportExportDialogMessage
            exportFormat={EXPORT_FORMAT_NAMES[exportFormat]}
            isSendReportOnEmail={isSendReportOnEmail}
            onChange={() => setIsSendReportOnEmail((prev) => !prev)}
            userEmail={user?.corporateEmail || user?.email || 'почта неизвестна'}
          />
        }
        onClose={() => setIsOpenNotifyDialog(false)}
        onSubmit={exportReportMutate}
        open={isOpenNotifyDialog}
        title={
          <>
            <Typography component="span" sx={{ verticalAlign: 'middle' }} variant="inherit">
              Экспорт отчета
            </Typography>
            <ExportEditButton
              fields={EXPORT_FIELDS_CUMULATIVE_REPORT}
              preferenceKey={ReportNames.Cumulative}
            />
          </>
        }
      />
    </ListContextProvider>
  );
};
