import { Box, styled } from '@mui/material';

export const CardHeaderStyled = styled(Box)({
  width: '100%',
  height: '64px',
  padding: '16px 24px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ActionsStyled = styled(Box)({
  gap: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignSelf: 'flex-start',
});
