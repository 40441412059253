import { Typography } from 'shared/mui/Typography';

import { StyledBox } from './OrderShowCommonInfoNoRecordsStub.styled';

type Props = { label: string };

export const OrderShowCommonInfoNoRecordsStub: React.FC<Props> = ({ label }) => {
  return (
    <StyledBox>
      <Typography variant="body2">{label}</Typography>
    </StyledBox>
  );
};
