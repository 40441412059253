import { styled } from '@mui/material/styles';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';

export const CardContentStyled = styled(CardContent)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  overflowY: 'auto',
});
