import { useEffect, useState } from 'react';

import _ from 'lodash';
import { DefaultValues, useForm, UseFormReturn } from 'react-hook-form';

export const enum FilterIdTypes {
  Id = 'id',
  Merchant = 'merchantReference',
  Provider = 'providerReferenceId',
}

export interface FilterIdsValues {
  merchantReference: string[];
  id: string[];
  providerReferenceId: string[];
}

export type UseIdFiltersReturnValues = {
  onReset: (currentIdFilter: FilterIdTypes) => void;
  onSubmit: (values: FilterIdsValues) => void;
  appliedIdFilters: FilterIdsValues;
  form: UseFormReturn<FilterIdsValues, any, any>;
  setAppliedFilter: (name: string, value: any) => void;
};

type Props = {
  defaultValues: FilterIdsValues;
  resetValues?: DefaultValues<FilterIdsValues>;
};

export function useIdFilters(props: Props): UseIdFiltersReturnValues {
  const form = useForm<FilterIdsValues>({
    defaultValues: props.defaultValues,
  });

  const [appliedIdFilters, setAppliedIdFilters] = useState<FilterIdsValues>(props.defaultValues);

  useEffect(() => {
    setAppliedIdFilters(props.defaultValues);
  }, []);

  const onReset = (currentIdFilter: FilterIdTypes) => {
    form.reset(props?.resetValues || props?.defaultValues || {});
    setAppliedIdFilters((prev) => {
      return (
        (props?.resetValues as FilterIdsValues) || {
          ...(_.set(prev, currentIdFilter, []) as FilterIdsValues),
        }
      );
    });
  };

  const setAppliedFilter = (name: string, value: any) => {
    setAppliedIdFilters((prev) => {
      return { ...(_.set(prev, name, value) as FilterIdsValues) };
    });
  };

  const onSubmit = (values: FilterIdsValues) => {
    setAppliedIdFilters((prev) => ({ ...prev, ...values }) as FilterIdsValues);
  };

  return { appliedIdFilters, onReset, onSubmit, setAppliedFilter, form };
}
