import { Box, styled } from '@mui/material';

export const PopoverToolbarStyled = styled(Box)({
  width: '100%',
  padding: '16px 24px 0 24px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

export const PopoverToolbarDatesStyled = styled(Box)({
  display: 'flex',
  flexFlow: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});
