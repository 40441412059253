import { BalanceReportFilterValues } from 'components/Finance/BalanceReport/BalanceReportList';
import { getBalanceReport } from 'config/requests';
import { useQuery } from 'react-query';
import { BalanceReport } from 'types';
import { cleanEmpty } from 'utils/cleanEmpty';

export function useBalanceReportList(filters: BalanceReportFilterValues): {
  balanceList: BalanceReport | undefined;
  isLoading: boolean;
} {
  const { data: balanceList, isLoading } = useQuery(
    ['balance_list', filters],
    () => getBalanceReport(filters),
    {
      enabled: !!Object.values(cleanEmpty(filters)).length,
    },
  );

  return { balanceList, isLoading };
}
