import { CalendarProps as CalendarComponentProps } from 'react-multi-date-picker';

import { CalendarStyled } from './Calendar.styled';
import { RUSSIAN_LOCALE } from './constants/locale';

export interface CalendarProps<Multiple extends boolean = false, Range extends boolean = false>
  extends CalendarComponentProps<Multiple, Range> {
  gap?: string;
}

export function Calendar<Multiple extends boolean = false, Range extends boolean = false>(
  props: CalendarProps<Multiple, Range>,
) {
  return (
    <CalendarStyled autoFocus={true} locale={RUSSIAN_LOCALE} weekStartDayIndex={1} {...props} />
  );
}
