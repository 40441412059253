import EventNoteIcon from '@mui/icons-material/EventNote';
import { useRecordContext } from 'react-admin';
import { Link, LinkProps } from 'react-router-dom';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';

import { ListItemTextStyled } from '../DatagridActions.styled';

export type DatagridActionsUserActionsButtonProps = {
  entityName: string;
  entityId?: string;
  target?: string;
} & Partial<LinkProps>;

export const DatagridActionsUserActionsButton: React.FC<DatagridActionsUserActionsButtonProps> = ({
  entityName,
  entityId,
  target = '_self',
  ...props
}) => {
  const record = useRecordContext();
  const encodeFilter = encodeURIComponent(
    JSON.stringify({ entityId: entityId || record?.originId, entityName }),
  );

  return (
    <Link
      style={{ textDecoration: 'none' }}
      target={target}
      to={{
        pathname: '/user_actions',
        search: `filter=${encodeFilter}`,
      }}
      {...props}
    >
      <MenuItem>
        <ListItemIcon>
          <EventNoteIcon fontSize="small" style={{ marginTop: '1px' }} />
        </ListItemIcon>
        <ListItemTextStyled primary="Пользовательские действия" />
      </MenuItem>
    </Link>
  );
};
