import { useEffect, useMemo } from 'react';

import { useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { useSyncFilter } from 'shared/mui/NewFilter/hooks/useSyncFilter';
import { SelectColumnButtonFilter } from 'shared/react-admin/Table/SelectColumnButtonFilter/SelectColumnButtonFilter';
import { cleanEmpty } from 'utils/cleanEmpty';

import { MerchantsListFilterChips } from './MerchantsListFilterChips';
import { MerchantsListFilterForm } from './MerchantsListFilterForm';

type MerchantsFilterValues = {
  archive?: boolean | null;
  name: string;
  companies: string[];
  partner: boolean | null;
  platform: string;
};

export const DEFAULT_VALUES = {
  archive: false,
  name: '',
  companies: [],
  partner: null,
  platform: '',
};

export const MerchantsListFilter = (): JSX.Element => {
  const { filterValues } = useListContext();
  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<MerchantsFilterValues>({
      defaultValues: {
        archive: filterValues.archive || null,
        name: filterValues.name || '',
        companies: filterValues.companies || [],
        partner: filterValues.partner || null,
        platform: filterValues.platform || '',
      },
      resetValues: DEFAULT_VALUES,
    });

  useSyncFilter({
    appliedFilters,
  });

  const listFilters = useMemo(() => {
    return cleanEmpty(appliedFilters, false);
  }, [appliedFilters]);

  useEffect(() => {
    form.setValue('archive', false, { shouldDirty: true });
    setAppliedFilter('archive', false);
  }, []);

  const isListFilterExist = Object.keys(listFilters).length > 0;

  return (
    <>
      <FilterToolbar
        leftActionsSlot={[
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          <SelectColumnButtonFilter key="select-column" />,
          <FilterClearButton
            key="clear"
            onClick={() => {
              onReset();
            }}
            visible={isListFilterExist}
          />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent>
              <MerchantsListFilterForm />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters} isSkipBooleanValues={false}>
        <FilterContextProvider appliedFilters={appliedFilters} setAppliedFilter={setAppliedFilter}>
          <FormProvider {...form}>
            <MerchantsListFilterChips />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
    </>
  );
};
