import { useState } from 'react';

import { ImageFieldStyled } from 'components/Orders/OrdersListNew/OrdersListLocalDatagrid/OrdersListLocalDatagrid.styled';
import { usePermissions } from 'hooks/usePermissions';
import { RecordContextProvider, useShowController } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Collapse } from 'shared/mui/Collapse/Collapse';
import { ExpandIconButton } from 'shared/mui/IconButtons';
import { Table, TableBody } from 'shared/mui/Table';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, TextField } from 'shared/react-admin/Fields';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { Gateway, Method, Order } from 'types';

import { EMPTY_FIELD } from '../../../../../constants';
import { OrderShowCommonInfoTableRowWrapper } from '../components/OrderShowCommonInfoTableRowWrapper';
import { StyledCard, StyledCardHeader } from '../OrderShowCommonInfo.styled';

export const OrderShowCommonInfoProvider = (): JSX.Element => {
  const { record: order } = useShowController<Order>();
  const { hasResourceAccess } = usePermissions();

  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <RecordContextProvider value={order}>
      <StyledCard>
        <StyledCardHeader
          actions={
            <ExpandIconButton expanded={expanded} onClick={handleExpandClick} size="small" />
          }
          title="Данные провайдера"
          titleSize="small"
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Table>
            <TableBody>
              <OrderShowCommonInfoTableRowWrapper title="Провайдер:">
                <ReferenceField link={false} reference="gateways" source="gateway">
                  <FunctionField
                    render={(record: Gateway) => {
                      return (
                        <RecordContextProvider value={record}>
                          <ReferenceField link={false} reference="providers" source="provider">
                            <TextField source="name" />
                          </ReferenceField>
                        </RecordContextProvider>
                      );
                    }}
                    source="provider"
                  />
                </ReferenceField>
              </OrderShowCommonInfoTableRowWrapper>
              <OrderShowCommonInfoTableRowWrapper title="Метод:">
                <ReferenceOneField
                  emptyText={EMPTY_FIELD}
                  link={false}
                  reference="methods"
                  resource="methods"
                  sortable={false}
                  source="method"
                  target="id"
                >
                  <FunctionField
                    render={(record: Method) => (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {hasResourceAccess('method_logos') && (
                          <ReferenceOneField reference="method_logos" source="logo" target="id">
                            <ImageFieldStyled source="filePath" />
                          </ReferenceOneField>
                        )}
                        <Typography variant="body2">{record?.name}</Typography>
                      </Box>
                    )}
                  />
                </ReferenceOneField>
              </OrderShowCommonInfoTableRowWrapper>
              <OrderShowCommonInfoTableRowWrapper title="Шлюз:">
                <ReferenceOneField
                  link={false}
                  reference="gateways"
                  sortable={false}
                  source="gateway"
                  target="id"
                >
                  <TextField source="name" />
                </ReferenceOneField>
              </OrderShowCommonInfoTableRowWrapper>
            </TableBody>
          </Table>
        </Collapse>
      </StyledCard>
    </RecordContextProvider>
  );
};
