import { useState } from 'react';

import { Form, useCreate, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { CreateStyled } from './MerchantShowAccountsCreateDialog.styled';
import { MerchantShowAccountsForm } from '../components/MerchantShowAccountsForm/MerchantShowAccountsForm';

type Props = {
  open: boolean;
  onClose: () => void;
  merchantId: string | undefined;
};

interface CreateFormValues extends FieldValues {
  merchant: string;
  provider: string;
  gateway: string;
  name: string;
  credentials: Record<string, any>;
}

export const MerchantShowAccountsCreateDialog: React.FC<Props> = ({
  open,
  onClose,
  merchantId,
}) => {
  const [create] = useCreate();
  const notify = useNotify();
  const [errors, setErrors] = useState<string[]>([]);

  const onSubmit = (formValues: CreateFormValues) => {
    create(
      'merchant_accounts',
      {
        data: {
          ...formValues,
          merchants: [merchantId],
        },
      },
      {
        onSuccess: () => {
          notify('Счет мерчанта успешно создан!', {
            type: 'success',
          });
          onClose();
        },
        onError: (error) => {
          setErrors((error as Error)?.message.split('\n'));
          notify(`Ошибка: ${(error as Error).message}`, { type: 'error' });
        },
      },
    );
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open} transitionDuration={0}>
      <CreateStyled resource="merchant_accounts" sx={{ margin: 0 }}>
        <Form onSubmit={onSubmit as any}>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Создание счёта"
          />
          <MerchantShowAccountsForm errors={errors} merchantId={merchantId} />
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="create" label="Создать" type="submit" variant="contained" />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
          />
        </Form>
      </CreateStyled>
    </Dialog>
  );
};
