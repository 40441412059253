import { SortPayload, useListController } from 'react-admin';
import { ListPage } from 'shared/react-admin/Pages';
import { Order } from 'types';

import { OrderAdjustmentsForm } from './OrderAdjustmentsForm/OrderAdjustmentsForm';

export const OrderAdjustmentsList = (): JSX.Element => {
  const { filterValues } = useListController<Order>({
    resource: 'orders',
    queryOptions: { enabled: false },
  });
  const isListFilterExist = Object.keys(filterValues).length > 0;

  return (
    <ListPage
      empty={false}
      headerTitle={{ titleText: 'Массовая корректировка заказов' }}
      pagination={<></>}
      queryOptions={{ enabled: isListFilterExist }}
      resource="orders"
      sort={{} as SortPayload}
    >
      <OrderAdjustmentsForm />
    </ListPage>
  );
};
