import { DatagridConfigurableProps as RADatagridConfigurableProps } from 'react-admin';

import { DatagridConfigurableStyled } from './DatagridConfigurable.styled';

export type DatagridConfigurableProps = RADatagridConfigurableProps;

const getDefaultRowSx = () => ({ backgroundColor: 'white' });

export const DatagridConfigurable: React.FC<DatagridConfigurableProps> = ({ rowSx, ...props }) => {
  return <DatagridConfigurableStyled rowSx={rowSx || getDefaultRowSx} {...props} />;
};
