import { ApiClientsLastActivityActive } from 'components/ApiClients/components/ApiClientsLastActivityField/ApiClientsLastActivityActive';
import { ApiClientsLastActivityInactive } from 'components/ApiClients/components/ApiClientsLastActivityField/ApiClientsLastActivityInactive';
import { FunctionField } from 'shared/react-admin/Fields';
import { ApiClient } from 'types';

type Props = {
  label: string;
  source?: string;
};

export const ApiClientsLastActivityField: React.FC<Props> = ({ label }) => {
  return (
    <FunctionField
      label={label}
      render={({
        active,
        activityUpdatedAtColor,
        activityUpdatedAtMessage,
        activityUpdatedAt,
      }: ApiClient) => {
        return active ? (
          <ApiClientsLastActivityActive
            activityUpdatedAtColor={activityUpdatedAtColor}
            activityUpdatedAtMessage={activityUpdatedAtMessage}
          />
        ) : (
          <ApiClientsLastActivityInactive activityUpdatedAt={activityUpdatedAt} />
        );
      }}
    />
  );
};
