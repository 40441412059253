import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useNotify } from 'react-admin';

import { IconButtonStyled } from './NotificationPopperListItemOrder.styled';
import { NotificationPopperListItemOrderSecondary } from './NotificationPopperListItemOrderSecondary';
import { appColors } from '../../../../../../themes/variables';
import { Notification, NotificationBodyContentOrderAdjustment } from '../../../../../../types';
import { Box } from '../../../../../mui/Box';
import { ListItemAvatar, ListItemText } from '../../../../../mui/List';
import { Typography } from '../../../../../mui/Typography';
import { useChangeViewedStatus } from '../../../hooks/useChangeViewedStatus';
import { NotificationPopperListItem } from '../NotificationPopperListItem';

type Props = {
  message: string;
  notification: Notification;
  onSuccess: () => void;
};

export const NotificationPopperListItemOrder: React.FC<Props> = ({
  notification,
  onSuccess,
  message,
}) => {
  const notify = useNotify();

  const { changeViewedStatus } = useChangeViewedStatus({
    ids: [notification.id],
    onSuccess,
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  return (
    <NotificationPopperListItem>
      <ListItemAvatar>
        <IconButtonStyled onClick={changeViewedStatus}>
          <CheckIcon htmlColor="#ffffff" />
        </IconButtonStyled>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography component="span">{message}</Typography>
          </>
        }
        primaryTypographyProps={{ component: 'div', sx: { marginBottom: '8px' } }}
        secondary={
          <NotificationPopperListItemOrderSecondary
            createdAt={
              (notification.body.content as NotificationBodyContentOrderAdjustment).createdAt
            }
            requestId={
              (notification.body.content as NotificationBodyContentOrderAdjustment)?.requestId
            }
          />
        }
        secondaryTypographyProps={{ component: 'div' }}
        sx={{ margin: 0 }}
      />
      <Box sx={{ marginTop: '2px' }}>
        {notification.isViewed ? (
          <RadioButtonUncheckedIcon htmlColor={appColors.primary.light} sx={{ fontSize: '8px' }} />
        ) : (
          <CircleIcon color="primary" sx={{ fontSize: '8px' }} />
        )}
      </Box>
    </NotificationPopperListItem>
  );
};
