import { green } from '@mui/material/colors';
import { format, fromUnixTime } from 'date-fns';
import { Link } from 'react-router-dom';

import { createFilterQueryString } from '../../../../../../utils/createFilterQueryString';
import { Stack } from '../../../../../mui/Stack/Stack';
import { Typography } from '../../../../../mui/Typography';

type Props = {
  createdAt: number;
  requestId: string;
};

export const NotificationPopperListItemOrderSecondary: React.FC<Props> = ({
  createdAt,
  requestId,
}) => {
  return (
    <>
      <Stack flexDirection="row" gap={1}>
        <Typography>{format(fromUnixTime(createdAt), 'dd.MM.yyyy HH:mm')}</Typography>
        <Typography>&bull;</Typography>
        <Link
          style={{ color: green[800] }}
          target="_blank"
          to={{
            pathname: '/order_adjustments_report',
            search: createFilterQueryString({ requestId }),
          }}
        >
          <Typography>Отчет по корректировке заказов</Typography>
        </Link>
      </Stack>
    </>
  );
};
