import { ExportFormats } from 'constants/exportFormats';

import { useState } from 'react';

import { ListContextProvider, useListController } from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { ExportFormatsButton } from 'shared/mui/Buttons/ExportFormatsButton/ExportFormatsButton';
import { PageHeader } from 'shared/mui/PageHeader';
import { ExportFormat } from 'types';

import { OrderAdjustmentsReportListContent } from './OrderAdjustmentsReportListContent';
import { OrderAdjustments } from '../../types/orderAdjustments';

export const OrderAdjustmentsReportList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormats.Excel);
  const [isOpenNotifyDialog, setIsOpenNotifyDialog] = useState(false);

  const orderAdjustmentsListContext = useListController<OrderAdjustments>({
    queryOptions: {
      enabled: !!searchParams.size,
    },
    resource: 'order_adjustments',
  });

  return (
    <>
      <ListContextProvider value={orderAdjustmentsListContext}>
        <PageHeader
          actions={[
            <ExportFormatsButton
              exportFormat={exportFormat}
              key="export-button"
              onChangeFormat={setExportFormat}
              onClick={() => setIsOpenNotifyDialog(true)}
            />,
          ]}
          headerTitle={{
            titleText: 'Отчет по корректировкам заказов',
          }}
          mt={3}
        />
        <OrderAdjustmentsReportListContent
          exportFormat={exportFormat}
          isOpenNotifyDialog={isOpenNotifyDialog}
          onCloseNotifyDialog={() => setIsOpenNotifyDialog(false)}
        />
      </ListContextProvider>
    </>
  );
};
