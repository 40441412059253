import ContentCreate from '@mui/icons-material/Create';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';

import { useDatagridActionsContext } from '../context/datagridActionsContext';
import { ListItemTextStyled } from '../DatagridActions.styled';

export type DatagridActionsEditDialogButtonProps = {
  onClick: VoidFunction;
};

export const DatagridActionsEditDialogButton: React.FC<DatagridActionsEditDialogButtonProps> = ({
  onClick,
}) => {
  const { onCloseMenu } = useDatagridActionsContext();
  const handleClick = () => {
    onClick();
    onCloseMenu();
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <ContentCreate fontSize="small" />
      </ListItemIcon>
      <ListItemTextStyled primary="Редактировать" />
    </MenuItem>
  );
};
