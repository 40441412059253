import { Datagrid, ReferenceManyField, TextField } from 'react-admin';
import { JsonCopyToClipboardField } from 'shared/react-admin/Fields';

import { OrdersShowTableCard } from './OrdersShowTableCard';
import { usePermissions } from '../../../hooks/usePermissions';

const OrdersShowCustomData = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();

  return (
    <>
      {hasResourceAccess('order_custom_datas') && (
        <OrdersShowTableCard>
          <ReferenceManyField
            label={false}
            reference="order_custom_datas"
            source="orderCustomData"
            target="id"
          >
            <Datagrid bulkActionButtons={false}>
              <TextField label="ID" source="originId" />
              <JsonCopyToClipboardField collapse={0} label="customData" source="customData" />
            </Datagrid>
          </ReferenceManyField>
        </OrdersShowTableCard>
      )}
    </>
  );
};
export default OrdersShowCustomData;
