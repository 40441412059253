import { useGetOne, useListContext, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { Currency, Order } from 'types';
import { formatCurrency } from 'utils';

import { OrderAdjustmentsFormValues } from '../hooks/useOrderAdjustmentsForm';

type Props = Omit<FunctionFieldProps, 'render'>;

export const OrderAdjustmentsAmountField: React.FC<Props> = (props) => {
  const { watch } = useFormContext<OrderAdjustmentsFormValues>();
  const { data: orders } = useListContext();
  const record = useRecordContext();
  const { data: currency, isLoading } = useGetOne<Currency>(
    'currencies',
    { id: record?.currency },
    { enabled: !!record?.currency },
  );

  const recordIndex = orders.findIndex((order) => order.id === record.id);
  const newAmount = watch(`orderAdjustments.${recordIndex}.newAmount`);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <FunctionField
      render={(record: Order) => {
        if (!newAmount && record.amount) {
          return (
            <Typography variant="body2">
              {formatCurrency(record.amount)}{' '}
              <span style={{ color: appColors.primary.main }}>{currency?.alphaCode}</span>
            </Typography>
          );
        }
        return (
          <Box>
            <Typography sx={{ color: appColors.success.main }} variant="body2">
              {formatCurrency(newAmount ?? 0)} {currency?.alphaCode}
            </Typography>
            <Typography sx={{ textDecoration: 'line-through' }} variant="body2">
              {formatCurrency(record.amount)}{' '}
              <span style={{ color: appColors.primary.main }}>{currency?.alphaCode}</span>
            </Typography>
          </Box>
        );
      }}
      {...props}
    />
  );
};
