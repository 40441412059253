import EditIcon from '@mui/icons-material/Edit';
import MessageIcon from '@mui/icons-material/Message';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { BulkActionsToolbarProps, useListContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { Order } from 'types';

type Props = {
  handleOpenAmountDialog: VoidFunction;
  handleOpenGroupCommentDialog: VoidFunction;
  handleOrdersExclude: (records: Order[]) => void;
} & BulkActionsToolbarProps;

export const OrderAdjustmentsDatagridBulkActionsToolbar = ({
  handleOpenAmountDialog,
  handleOpenGroupCommentDialog,
  handleOrdersExclude,
  selectedIds,
}: Props) => {
  const { data: orders } = useListContext();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Button
        disabled={selectedIds?.length !== 1}
        label="редактировать сумму"
        onClick={handleOpenAmountDialog}
        startIcon={<EditIcon />}
      />
      <Button
        label="комментарий"
        onClick={handleOpenGroupCommentDialog}
        startIcon={<MessageIcon />}
      />
      <Button
        color="error"
        label="исключить"
        onClick={() =>
          handleOrdersExclude(orders.filter((order) => selectedIds?.some((id) => order.id === id)))
        }
        startIcon={<RemoveCircleOutlineIcon />}
      />
    </Box>
  );
};
