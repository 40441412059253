import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { Dialog } from 'shared/mui/Dialogs';

export const StyledDialog = styled(Dialog)({
  '.MuiCardContent-root': {
    paddingBottom: '0!important',
    ':&last-child': {
      paddingBottom: '0!important',
    },
  },
});

export const StyledBox = styled(Box)({
  display: 'flex',
  top: '50%',
  flexDirection: 'column',
  padding: 0,
  width: '600px',
});
