import { useState } from 'react';

import MessageIcon from '@mui/icons-material/Message';
import { useRecordModal } from 'hooks/useRecordModal';
import { RecordContextProvider, ReferenceManyField, WithListContext } from 'react-admin';
import { CancelButton } from 'shared/mui/Buttons';
import { Collapse } from 'shared/mui/Collapse/Collapse';
import { BaseDialog } from 'shared/mui/Dialogs';
import { ExpandIconButton, IconButton } from 'shared/mui/IconButtons';
import {
  AdjustmentAmountField,
  AdjustmentChangeStatusField,
  DateTimeField,
  FunctionField,
  TextField,
  UserProfileField,
} from 'shared/react-admin/Fields';
import { AdjustmentStatusChipField } from 'shared/react-admin/Fields/AdjustmentStatusChipField/AdjustmentStatusChipField';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { AdjustmentStatus } from 'types/adjustmentStatus';
import { OrderAdjustments } from 'types/orderAdjustments';

import { StyledDatagridWithStickyActions } from './OrderShowCommonInfoAdjustments.styled';
import { OrderShowCommonInfoNoRecordsStub } from '../components/OrderShowCommonInfoNoRecordsStub/OrderShowCommonInfoNoRecordsStub';
import { StyledCard, StyledCardHeader } from '../OrderShowCommonInfo.styled';

export const OrderShowCommonInfoAdjustments = (): JSX.Element => {
  const {
    record: recordCommentDialog,
    handleOpenRecordModal: handleOpenCommentDialog,
    openRecordModal: openCommentDialog,
    handleCloseRecordModal: handleCloseCommentDialog,
  } = useRecordModal<OrderAdjustments>();

  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledCard>
      <StyledCardHeader
        actions={<ExpandIconButton expanded={expanded} onClick={handleExpandClick} size="small" />}
        title="Корректировки заказа:"
        titleSize="small"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ReferenceManyField label={false} reference="order_adjustments" target="order">
          <WithListContext
            render={({ data, isLoading }) => {
              if (!data?.length && !isLoading) {
                return <OrderShowCommonInfoNoRecordsStub label="Нет корректировок по заказу" />;
              }
              return (
                <StyledDatagridWithStickyActions bulkActionButtons={false}>
                  <TextField label="ID корректировки" sortable={false} source="originId" />
                  <ReferenceField
                    label="Тип корректировки"
                    link={false}
                    reference="adjustment_types"
                    source="adjustmentType"
                  >
                    <TextField source="description" />
                  </ReferenceField>
                  <FunctionField
                    label="Движение по балансу игрока"
                    render={(record: OrderAdjustments) =>
                      `${record.impactToBalance ? 'Да' : 'Нет'}`
                    }
                    source="impactToBalance"
                  />
                  <ReferenceField
                    label="Статус корректировки"
                    link={false}
                    reference="adjustment_statuses"
                    source="adjustmentStatus"
                  >
                    <FunctionField
                      render={(record: AdjustmentStatus) => (
                        <RecordContextProvider value={record}>
                          <AdjustmentStatusChipField source="description" />
                        </RecordContextProvider>
                      )}
                    />
                  </ReferenceField>
                  <AdjustmentAmountField label="Изменение суммы" source="amount" />
                  <AdjustmentChangeStatusField label="Изменение статуса" source="change-status" />
                  <ReferenceOneField
                    label="Инициатор / Автор"
                    link={false}
                    reference="users"
                    sortable={false}
                    source="user"
                    target="id"
                  >
                    <FunctionField render={() => <UserProfileField />} />
                  </ReferenceOneField>
                  <DateTimeField label="Дата создания" source="createdAt" />
                  <DateTimeField label="Дата применения" source="updatedAt" />
                  <ActionsSlots
                    render={(record: OrderAdjustments) => {
                      return (
                        <IconButton
                          disabled={!record?.comment}
                          onClick={() => handleOpenCommentDialog(record)}
                        >
                          <MessageIcon color={record?.comment ? 'primary' : 'secondary'} />
                        </IconButton>
                      );
                    }}
                  />
                </StyledDatagridWithStickyActions>
              );
            }}
          />
        </ReferenceManyField>
      </Collapse>
      {recordCommentDialog && (
        <BaseDialog
          message={recordCommentDialog?.comment}
          onClose={handleCloseCommentDialog}
          open={openCommentDialog}
          rightActionsSlot={[
            <CancelButton
              key="cancel-button"
              label="Закрыть"
              onClick={handleCloseCommentDialog}
              variant="contained"
            />,
          ]}
          title={`Комментарий к операции #${recordCommentDialog?.originId}`}
        />
      )}
    </StyledCard>
  );
};
