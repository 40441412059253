import { useListContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { TextInput } from 'shared/react-admin/Inputs';
import { Order } from 'types';

import { StyledBox, StyledDialog } from './OrderAdjustmentsCommentDialog.styled';

type Props = DialogProps & {
  record: Order;
};

export const OrderAdjustmentsCommentDialog = ({ record, open, onClose }: Props) => {
  const { data: orders = [] } = useListContext();
  const { setValue } = useFormContext();
  const recordIndex = orders.findIndex((order) => order.id === record.id);

  return (
    <StyledDialog maxWidth="md" onClose={onClose} open={open}>
      <StyledBox>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Комментарий к корректировке"
        />
        <CardContent>
          <TextInput
            InputLabelProps={{ shrink: true }}
            fullWidth
            label="Комментарий"
            multiline
            required
            size="medium"
            source={`orderAdjustments.${recordIndex}.comment`}
            variant="outlined"
          />
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <Button
              key="clear-button"
              label="Очистить"
              onClick={() => setValue(`orderAdjustments.${recordIndex}.comment`, null)}
              type="button"
            />,
          ]}
          rightActionsSlot={[
            <Button
              key="save-button"
              label="Сохранить"
              onClick={onClose}
              type="button"
              variant="contained"
            />,
            <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
          ]}
        />
      </StyledBox>
    </StyledDialog>
  );
};
