import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { OrderChangeStatusesField } from 'shared/react-admin/Fields/OrderChangeStatusesField/OrderChangeStatusesField';

type Props = Omit<FunctionFieldProps, 'render'>;

export const OrderShowLogsChangeStatusesField: React.FC<Props> = (props) => {
  return (
    <FunctionField
      render={() => (
        <Stack alignItems="center" direction="row" gap="8px">
          <Typography color="secondary" sx={{ lineHeight: 1 }} variant="body2">
            Изменение статуса:
          </Typography>
          <OrderChangeStatusesField />
        </Stack>
      )}
      sortable={false}
      {...props}
    />
  );
};
