import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { appColors } from 'themes/variables';

export const StyledBox = styled(Box)({
  height: '56px',
  margin: '16px',
  border: `1px dashed ${appColors.divider}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
