import { useEffect } from 'react';

import { useExistingChildren } from 'hooks/useExistingChildren';
import { usePermissions } from 'hooks/usePermissions';
import {
  BulkDeleteButton,
  DatagridProps as RaDatagridProps,
  useListContext,
  useRemoveFromStore,
} from 'react-admin';
import { Permission } from 'types';

import { StyledDatagrid } from './Datagrid.styled';

export type DatagridProps = RaDatagridProps;

const getDefaultRowSx = () => ({ backgroundColor: 'white' });

export const Datagrid: React.FC<DatagridProps> = ({ children, rowSx, ...props }) => {
  const { resource, data = [] } = useListContext();
  const { hasResourcePermissions } = usePermissions();

  const existingFields: string[] | undefined = data?.reduce((fields, record) => {
    return Array.from(new Set([...fields, ...Object.keys(record)]));
  }, []);

  const filteredChildren = useExistingChildren(children, existingFields);

  const remove = useRemoveFromStore();

  useEffect(() => {
    return () => {
      remove(`${resource}.selectedIds`);
      remove(`${resource}.listParams`);
    };
  }, []);

  return (
    <StyledDatagrid
      bulkActionButtons={
        hasResourcePermissions(resource, [Permission.Full, Permission.Delete]) && (
          <BulkDeleteButton />
        )
      }
      {...props}
      rowSx={rowSx || getDefaultRowSx}
    >
      {filteredChildren}
    </StyledDatagrid>
  );
};
