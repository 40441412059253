import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField } from 'shared/react-admin/Fields';
import { Customer } from 'types';

import { ReactComponent as PersonTestIcon } from '../../../../../images/personTest.svg';

export const OrderShowCommonInfoMerchantClientField = (): JSX.Element => {
  return (
    <FunctionField
      render={(record: Customer) => {
        return (
          <Stack direction="row" gap="4px">
            <Typography variant="body2">{record?.customerId}</Typography>
            {record?.isTest && <PersonTestIcon />}
          </Stack>
        );
      }}
    />
  );
};
