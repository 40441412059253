import { queryClient } from 'App';
import { useGoBack } from 'hooks/useGoBack';
import { DeleteDialog, DeleteDialogProps } from 'shared/mui/Dialogs';

import { truncateString } from '../../../utils/truncateString';

export const CommissionsDeleteDialog: React.FC<Omit<DeleteDialogProps, 'details' | 'title'>> = (
  props,
) => {
  const { goBack } = useGoBack();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(['commissions']);
    goBack();
  };

  return (
    <DeleteDialog
      {...props}
      details={`Комиссия ${truncateString(props.record?.name || '', 50)} будет удалена без возможности восстановления.`}
      onSuccess={onSuccess}
      title="Удаление комиссии"
    />
  );
};
