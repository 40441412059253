import VisibilityIcon from '@mui/icons-material/Visibility';
import { useRecordContext, useResourceContext } from 'react-admin';
import { Link, LinkProps } from 'react-router-dom';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';

import { useDatagridActionsContext } from '../context/datagridActionsContext';
import { ListItemTextStyled } from '../DatagridActions.styled';

export type DatagridActionsShowButtonProps = Partial<LinkProps>;

export const DatagridActionsShowButton: React.FC<DatagridActionsShowButtonProps> = (props) => {
  const resourceCurrent = useResourceContext(props);
  const record = useRecordContext(props);
  const { onCloseMenu } = useDatagridActionsContext();

  return (
    <Link
      style={{ textDecoration: 'none' }}
      target={props.target}
      to={`/${props.resource || resourceCurrent}/${encodeURIComponent(record.id)}/show`}
      {...props}
    >
      <MenuItem onClick={onCloseMenu}>
        <ListItemIcon>
          <VisibilityIcon fontSize="small" />
        </ListItemIcon>
        <ListItemTextStyled primary="Просмотр" />
      </MenuItem>
    </Link>
  );
};
