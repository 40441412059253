import { styled } from '@mui/material';
import { Calendar } from 'react-multi-date-picker';

export const CalendarStyled = styled(Calendar)<{ gap?: string | undefined }>(({ gap }) => ({
  '&.rmdp-wrapper': {
    padding: '15px',
    boxSizing: 'border-box',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '12px',
    boxShadow: 'none',
  },
  '& .rmdp-day-picker': {
    gap: gap || 0,
  },
  '& .rmdp-header': {
    fontSize: '16px',
    fontWeight: '500',
  },
  '& .rmdp-week-day': {
    fontWeight: '400',
    fontSize: '12px',
    color: '#00000099',
  },
  '& .rmdp-arrow': {
    borderColor: 'rgba(0, 0, 0, 0.54)',
  },
  '& .rmdp-arrow-container:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    boxShadow: 'none',
  },
  '& .rmdp-arrow-container:hover .rmdp-arrow': {
    borderColor: 'rgba(0, 0, 0, 0.54)',
  },
  '& .rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
    color: 'inherit',
  },
  '& .rmdp-range': {
    backgroundColor: '#1976D2',
    boxShadow: 'none',
  },
  '&.rmdp-wrapper, .rmdp-container .custom-calendar.ep-arrow::after': {
    backgroundColor: '#ffffff',
  },
  '& .rmdp-day.rmdp-today span': {
    backgroundColor: 'rgba(255,255,255,0.5)',
    color: '#000000',
    border: '1px solid rgba(0, 0, 0, 0.3)',
  },
}));
