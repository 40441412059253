import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { TIMEZONES } from 'shared/mui/DatePicker/constants/timezones';

const DATE_FORMAT = 'dd/MM/yyyy HH:mm';

export const getOrdersReportListAlertTitle = ({
  dateGte,
  dateLte,
  timezone,
}: {
  dateGte: number;
  dateLte: number;
  timezone?: string;
}) => {
  const startDate = formatInTimeZone(
    fromUnixTime(dateGte),
    timezone || DEFAULT_TIMEZONE,
    DATE_FORMAT,
  );
  const endDate = formatInTimeZone(
    fromUnixTime(dateLte),
    timezone || DEFAULT_TIMEZONE,
    DATE_FORMAT,
  );
  const currentTimezone = `${TIMEZONES.find((tz) => tz?.name === timezone)?.offset || '+3 UTC'}`;

  return `За период отчета ${startDate} - ${endDate} (${currentTimezone}) есть неисполненные транзакции в следующих статусах:`;
};
