import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { LocationDescriptor } from 'react-admin';
import { CircularProgress } from 'shared/mui/CircularProgress/CircularProgress';
import { getLinkParams } from 'utils';

interface Props {
  isLoading?: boolean;
  to?: string | LocationDescriptor;
  target?: string;
  label?: string;
}

export type ButtonProps = Props & MuiButtonProps;

export const Button = ({
  label,
  color = 'primary',
  size = 'medium',
  to: locationDescriptor,
  isLoading,
  startIcon,
  ...rest
}: ButtonProps) => {
  const linkParams = getLinkParams(locationDescriptor);

  return (
    <MuiButton
      color={color}
      size={size}
      {...rest}
      {...linkParams}
      disabled={rest.disabled || isLoading}
      startIcon={startIcon || (isLoading && <CircularProgress color="secondary" size={16} />)}
    >
      {label}
    </MuiButton>
  );
};
