import { styled } from '@mui/material/styles';
import { DatagridConfigurableWithStickyActions } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

export const StyledDatagridWithStickyActions = styled(DatagridConfigurableWithStickyActions)({
  border: 'none',
  '& .MuiTableCell-root': { padding: '8px 16px', verticalAlign: 'middle' },
  '& .MuiTableCell-head': {
    verticalAlign: 'middle',
    padding: '5px 16px',
  },
  td: {
    '&:last-child': {
      backgroundColor: '#fff',
    },
  },
  '& .column-originId': getColumnStyle(136),
  '& .column-adjustmentType': getColumnStyle(240),
  '& .column-impactToBalance': getColumnStyle(140),
  '& .column-adjustmentStatus': getColumnStyle(280),
  '& .column-amount': getColumnStyle(168),
  '& .column-change-status': getColumnStyle(280),
  '& .column-user': getColumnStyle(280),
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updateddAt': getColumnStyle(120),
});
