import { TableRowWrapper } from 'shared/mui/Table';
import { appColors } from 'themes/variables';
import { getColumnStyle } from 'utils';

type Props = {
  children: React.ReactNode;
  title: string;
};
export const OrderShowCommonInfoStateTableRowWrapper = ({
  children,
  title,
}: Props): JSX.Element => (
  <TableRowWrapper
    contentStyle={{ borderBottom: 'none', padding: '8px 8px 8px 0' }}
    headerStyle={{
      ...getColumnStyle(190),
      borderBottom: 'none',
      padding: '8px 8px 8px 16px',
      fontWeight: 400,
      color: appColors.text.secondary,
    }}
    title={title}
  >
    {children}
  </TableRowWrapper>
);
