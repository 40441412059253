import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

const defaultColumnStyle = getColumnStyle(250);

export const StyledDatagrid = styled(Datagrid)({
  border: 'none',
  '& .MuiTableCell-root': { padding: '8px 16px', verticalAlign: 'middle' },
  '& .MuiTableCell-head': {
    verticalAlign: 'middle',
    padding: '10px 16px',
    backgroundColor: grey[50],
  },
  '& .column-originId': defaultColumnStyle,
  '& .column-createdAt': defaultColumnStyle,
  '& .column-status': defaultColumnStyle,
});
