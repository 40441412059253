import { styled } from '@mui/material/styles';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

export const StyledDatagrid = styled(Datagrid)({
  '& th.MuiTableCell-paddingCheckbox': {
    paddingTop: '0 !important',
    verticalAlign: 'middle',
  },
  '& td.MuiTableCell-paddingCheckbox': {
    paddingTop: '0 !important',
    verticalAlign: 'middle',
  },
  '& .MuiTableCell-root': {
    verticalAlign: 'middle',
  },
  '& .column-merchantReference': getColumnStyle(128),
  '& .column-amount': { ...getColumnStyle(172), textAlign: 'right' },
  '& .column-provider': getColumnStyle(165),
});
