import { ReactElement } from 'react';

import { RecordContextProvider } from 'react-admin';
import { FunctionField, TextField } from 'shared/react-admin/Fields';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';

import { Order } from '../../../types';

type Props = {
  label?: string | ReactElement | boolean;
  source: string;
};

export const OrderAdjustmentsReportGatewayField: React.FC<Props> = ({ label, source }) => {
  return (
    <FunctionField
      label={label}
      render={() => {
        return (
          <ReferenceOneField
            label="Шлюз"
            link={false}
            reference="orders"
            sortable={false}
            source="order"
            target="id"
          >
            <FunctionField
              render={(record: Order) => {
                return (
                  <RecordContextProvider value={record}>
                    <ReferenceField link={false} reference="gateways" source="gateway">
                      <TextField source="name" />
                    </ReferenceField>
                  </RecordContextProvider>
                );
              }}
            />
          </ReferenceOneField>
        );
      }}
      source={source}
    />
  );
};
