import { Box } from 'shared/mui/Box';
import { ExportFormatsButton } from 'shared/mui/Buttons/ExportFormatsButton/ExportFormatsButton';
import { ExportFormat } from 'types';

type OrdersListActionsProps = {
  exportFormat: ExportFormat;
  setExportFormat: (exportFormat: ExportFormat) => void;
  onExportFormatsButtonClick: () => void;
};

export const OrdersListActions = ({
  exportFormat,
  setExportFormat,
  onExportFormatsButtonClick,
}: OrdersListActionsProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
      }}
    >
      <ExportFormatsButton
        exportFormat={exportFormat}
        key="export-button"
        onChangeFormat={setExportFormat}
        onClick={onExportFormatsButtonClick}
      />
    </Box>
  );
};
