import { useEffect } from 'react';

// Требуется для первоначального обновления списка полей в LocalStorage. Из-за того, что названия полей изменились в процессе разработки, появился баг
// из-за рассинхрона значений в localStorage.
// Описания полей по настройке колонок хранятся в localStorage.
// Для того, чтобы они обновились в localStorage и отображались клиенту корректно, требуется при первой загрузке пользователем
// данного раздела - очистить поля в LS, после этого устанавливается флаг firstRenderKey = true в LS
// и больше данная функция не вызывается никогда.

export const useClearLSTemporary = (
  firstRenderKey: string,
  preferenceKey: string,
  extraFn?: VoidFunction,
) => {
  useEffect(() => {
    const isNotFirstOrdersListRender = localStorage.getItem(firstRenderKey);

    if (!isNotFirstOrdersListRender) {
      localStorage.removeItem(`RaStore.preferences.${preferenceKey}.availableColumns`);
      localStorage.removeItem(`RaStore.preferences.${preferenceKey}.columns`);

      extraFn?.();
    }

    return () => {
      localStorage.setItem(firstRenderKey, 'true');
    };
  }, []);
};
