import VisibilityIcon from '@mui/icons-material/Visibility';
import { RecordContextProvider } from 'react-admin';
import { Button, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton, UserEntityPermissionsIconButton } from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import {
  BooleanField,
  ChipField,
  DateTimeField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';

import { EMPTY_FIELD } from '../../../../../constants';
import { usePermissions } from '../../../../../hooks/usePermissions';
import { MerchantAccount, Permission } from '../../../../../types';
import { MerchantShowAccountsActivityField } from '../components/MerchantShowAccountsActivityField.tsx/MerchantShowAccountsActivityField';

type Props = {
  open: boolean;
  onEdit: (record: MerchantAccount) => void;
  onDelete: (record: MerchantAccount) => void;
  handleOpenPermissionsDialog: (record: MerchantAccount) => void;
  handleOpenCredentialsDialog: (record: MerchantAccount) => void;
  onClose: () => void;
  record?: MerchantAccount;
};

export const MerchantShowAccountsShowDialog: React.FC<Props> = ({
  open,
  onClose,
  onEdit,
  onDelete,
  handleOpenPermissionsDialog,
  handleOpenCredentialsDialog,
  record,
}) => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={record}>
        <CardHeader
          actions={[
            hasResourceAccess('user_entity_permissions') && (
              <UserEntityPermissionsIconButton
                key="user-action-link"
                onClick={() => {
                  record && handleOpenPermissionsDialog(record);
                  onClose();
                }}
              />
            ),
            hasResourceAccess('user_actions') && (
              <UserActionsIconButton entityName="merchant_account" key="user-action-link" />
            ),
            <CloseIconButton key="close-button" onClose={onClose} />,
          ]}
          title="Просмотр счета"
        />
        <CardTable sx={{ tableLayout: 'fixed' }}>
          <TableBody>
            <TableRowWrapper headerStyle={{ width: '140px' }} title="ID">
              <TextField source="originId" />
            </TableRowWrapper>
            <TableRowWrapper title="Активен">
              <BooleanField source="active" />
            </TableRowWrapper>
            <TableRowWrapper title="Название">
              <TextField source="name" />
            </TableRowWrapper>
            <TableRowWrapper headerStyle={{ padding: '4px 24px' }} title="Последняя активность">
              <MerchantShowAccountsActivityField label="Последняя активность" />
            </TableRowWrapper>
            <TableRowWrapper
              contentStyle={{ padding: '4px 16px' }}
              headerStyle={{ padding: '4px 24px' }}
              title="Доступ к счету {json}"
            >
              <Button
                label="Показать"
                onClick={() => {
                  record && handleOpenCredentialsDialog(record);
                  onClose();
                }}
                startIcon={<VisibilityIcon />}
              />
            </TableRowWrapper>
            <TableRowWrapper title="Мерчант">
              <ReferenceArrayField label="Мерчант" reference="merchants" source="merchants">
                <SingleFieldList linkType={false}>
                  <TextField emptyText={EMPTY_FIELD} label="Мерчант" source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
            </TableRowWrapper>
            <TableRowWrapper title="Провайдер">
              <ReferenceOneField
                label="Провайдер"
                reference="providers"
                source="provider"
                target="id"
              >
                <TextField emptyText={EMPTY_FIELD} label="Провайдер" source="name" />
              </ReferenceOneField>
            </TableRowWrapper>
            <TableRowWrapper title="Использовать сквозной ID">
              <BooleanField source="merchantIdEnabled" />
            </TableRowWrapper>
            <TableRowWrapper title="Валюта">
              <ReferenceOneField
                label="Валюта"
                reference="currencies"
                source="currency"
                target="id"
              >
                <TextField emptyText={EMPTY_FIELD} label="Валюта" source="alphaCode" />
              </ReferenceOneField>
            </TableRowWrapper>

            <TableRowWrapper title="Шлюзы">
              <ReferenceArrayField label="Шлюзы" reference="gateways" source="gateways">
                <SingleFieldList linkType={false}>
                  <ChipField color="primary" source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
            </TableRowWrapper>
            <TableRowWrapper contentStyle={{ padding: '4px 16px' }} title="Дата создания">
              <DateTimeField source="createdAt" />
            </TableRowWrapper>
            <TableRowWrapper contentStyle={{ padding: '4px 16px' }} title="Дата редактир.">
              <DateTimeField source="updatedAt" />
            </TableRowWrapper>
          </TableBody>
        </CardTable>
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Update]) &&
            !record?.locked && (
              <Button
                label="Редактировать"
                onClick={() => {
                  onClose();
                  record && onEdit(record);
                }}
                variant="outlined"
              />
            )
          }
          rightActionsSlot={
            hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Delete]) && (
              <DeleteButton
                onClick={() => {
                  onClose();
                  record && onDelete(record);
                }}
                variant="outlined"
              />
            )
          }
        />
      </RecordContextProvider>
    </Dialog>
  );
};
