import { JsonCopyToClipboardField } from 'shared/react-admin/Fields';

import { OrderRequestLogsExpandTable } from './OrderRequestLogsExpandTable';

export const OrderRequestLogsExpand = (): JSX.Element => {
  return (
    <OrderRequestLogsExpandTable>
      <tbody>
        <tr>
          <td>Запрос</td>
          <td>Ответ</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>
            <JsonCopyToClipboardField collapse={60} label="Запрос" source="requestData" />
          </td>
          <td style={{ verticalAlign: 'top' }}>
            <JsonCopyToClipboardField collapse={60} label="Ответ" source="responseData" />
          </td>
        </tr>
      </tbody>
    </OrderRequestLogsExpandTable>
  );
};
