import { useListContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { NumberInput } from 'shared/react-admin/Inputs';

import { StyledBox, StyledDialog } from './OrderAdjustmentsAmountDialog.styled';

export const OrderAdjustmentsAmountDialog = ({ open, onClose }: DialogProps) => {
  const { data: orders = [], selectedIds: [selectedId] = [] } = useListContext();
  const { setValue } = useFormContext();
  const recordIndex = orders.findIndex((order) => order.id === selectedId);

  return (
    <StyledDialog maxWidth="md" onClose={onClose} open={open}>
      <StyledBox>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Изменить сумму для заказа"
        />
        <CardContent>
          <NumberInput
            InputLabelProps={{ shrink: true }}
            fullWidth
            label="Сумма"
            multiline
            required
            size="medium"
            source={`orderAdjustments.${recordIndex}.newAmount`}
            variant="outlined"
          />
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <Button
              key="clear-button"
              label="Очистить"
              onClick={() => setValue(`orderAdjustments.${recordIndex}.newAmount`, null)}
              type="button"
            />,
          ]}
          rightActionsSlot={[
            <Button
              key="save-button"
              label="Сохранить"
              onClick={onClose}
              type="button"
              variant="contained"
            />,
            <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
          ]}
        />
      </StyledBox>
    </StyledDialog>
  );
};
