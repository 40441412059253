import qs from 'qs';
import { ListButtonProps as RaListButtonProps, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { Button } from 'shared/mui/Buttons';

import { Commission } from '../../../../types';

export type LinkTextButtonProps = {
  pathname: string;
} & RaListButtonProps;

export const CommissionsNewVersionButton: React.FC<LinkTextButtonProps> = ({
  label = 'Создать новую версию',
  variant = 'contained',
  color = 'primary',
  pathname,
  ...restProps
}) => {
  const record = useRecordContext<Commission>();

  const search = qs.stringify(
    {
      parent: record.id,
      provider: record?.provider,
      merchant: record?.merchant,
      transactionTypes: record?.transactionTypes,
      currency: record?.currency,
    },
    { indices: false, encode: true },
  );

  return (
    <Button
      color={color}
      component={Link}
      label={label}
      to={{
        pathname,
        search,
      }}
      variant={variant}
      {...restProps}
    />
  );
};
