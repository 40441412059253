import { styled } from '@mui/material/styles';
import { DatagridWithStickyActions } from 'shared/react-admin/Datagrids/DatagridWithStickyActions/DatagridWithStickyActions';

import { Card } from '../../../../shared/mui/Card';
import { getColumnStyle } from '../../../../utils';

export const CardStyled = styled(Card)({
  width: '100%',
  background: 'transparent',
});

const columnStyle = {
  width: '200px',
  maxWidth: '200px',
  minWidth: '200px',
  textWrap: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const StyledDatagrid = styled(DatagridWithStickyActions)({
  '& .column-name': columnStyle,
  '& .column-provider': columnStyle,
  '& .column-comment': {
    width: '100%',
    maxWidth: '236px',
    minWidth: '236px',
  },
  '& .column-credentials': getColumnStyle(160),
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});
