import SyncIcon from '@mui/icons-material/Sync';
import { useNotify } from 'react-admin';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { ClickAwayListener } from 'shared/mui/ClickAwayListener/ClickAwayListener';
import { IconButton } from 'shared/mui/IconButtons';
import { List } from 'shared/mui/List/List';
import { Popper } from 'shared/mui/Popper/Popper';
import { appColors } from 'themes/variables';

import {
  NotificationsPopperCard,
  NotificationsPopperCardContent,
} from './NotificationPopperCard.styled';
import { NotificationPopperListItemOrder } from './NotificationPopperListItem/NotificationPopperListItemOrder/NotificationPopperListItemOrder';
import { NotificationPopperListItemReport } from './NotificationPopperListItem/NotificationPopperListItemReport/NotificationPopperListItemReport';
import { NotificationPopperMenuButton } from './NotificationPopperMenuButton';
import { NotificationsPopperAlert } from './NotificationsPopperAlert';
import { AdjustmentStatuses } from '../../../../constants/adjustmentStatuses';
import { NotificationTypes } from '../../../../constants/notifications';
import { NotificationBodyContent } from '../../../../types';
import { getId } from '../../../../utils';
import { useGetNotifications } from '../hooks/useGetNotifications';

type NotificationsPopperProps = {
  anchorEl?: Element | null;
  onClose: () => void;
  refetchCounter: () => void;
};

export const NotificationsPopper = ({
  anchorEl,
  onClose,
  refetchCounter,
}: NotificationsPopperProps): JSX.Element => {
  const { notifications, refetch: refetchNotifications } = useGetNotifications(Boolean(anchorEl));
  const notify = useNotify();

  return (
    <Popper
      anchorEl={anchorEl}
      open={!!anchorEl}
      placement="bottom-end"
      sx={{
        zIndex: 100,
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <NotificationsPopperCard>
          <CardHeader
            actions={
              <CardActionsButtons
                leftActionsSlot={
                  <IconButton onClick={refetchNotifications}>
                    <SyncIcon />
                  </IconButton>
                }
                rightActionsSlot={
                  notifications?.length ? (
                    <NotificationPopperMenuButton
                      notifications={notifications}
                      onSuccess={() => {
                        refetchCounter();
                        refetchNotifications();
                        notify('Все уведомления отмечены как прочитанные', { type: 'success' });
                      }}
                    />
                  ) : undefined
                }
                sx={{ flex: 1, padding: 0 }}
              />
            }
            sx={{
              borderBottom: `1px solid ${appColors.divider}`,
            }}
            title="Уведомления"
          />
          <NotificationsPopperCardContent>
            <List sx={{ padding: 0 }}>
              {notifications?.length ? (
                notifications.map((notification) => {
                  const notificationType = getId(notification.notificationType);

                  if (notificationType === NotificationTypes.OrderAdjustment) {
                    if (notification.body.status === AdjustmentStatuses.Completed) {
                      return (
                        <NotificationPopperListItemOrder
                          key={notification.id}
                          message={
                            'Запрос для массовой корректировки заказов с типом "Корректировка суммы заказа" успешно завершен.'
                          }
                          notification={notification}
                          onSuccess={() => {
                            refetchCounter();
                            refetchNotifications();
                          }}
                        />
                      );
                    }
                    return (
                      <NotificationPopperListItemOrder
                        key={notification.id}
                        message={
                          'Запрос для массовой корректировки заказов с типом "Корректировка суммы заказа" успешно подготовлен к обработке.'
                        }
                        notification={notification}
                        onSuccess={() => {
                          refetchCounter();
                          refetchNotifications();
                        }}
                      />
                    );
                  }

                  if (notificationType === NotificationTypes.Report) {
                    return (
                      <NotificationPopperListItemReport
                        key={notification.id}
                        notification={notification}
                        onSuccess={() => {
                          window.open(
                            (notification.body.content as NotificationBodyContent).fileUrl,
                            '_blank',
                          );
                          refetchNotifications();
                        }}
                      />
                    );
                  }

                  return null;
                })
              ) : (
                <NotificationsPopperAlert />
              )}
            </List>
          </NotificationsPopperCardContent>
        </NotificationsPopperCard>
      </ClickAwayListener>
    </Popper>
  );
};
