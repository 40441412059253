import { ReferenceManyField, TextField } from 'react-admin';
import { Stack } from 'shared/mui/Stack/Stack';
import { DateTimeField, WrapperField } from 'shared/react-admin/Fields';
import { Pagination } from 'shared/react-admin/Pagination/Pagination';

import { OrderShowLogsChangeStatusesField } from './OrderShowLogsChangeStatusesField/OrderShowLogsChangeStatusesField';
import { OrderShowLogsDelayField } from './OrderShowLogsDelayField/OrderShowLogsDelayField';
import { OrderShowLogsDirectionField } from './OrderShowLogsDirectionField/OrderShowLogsDirectionField';
import { OrderRequestLogsExpand } from './OrderShowLogsExpand/OrderShowLogsExpand';
import { OrderShowLogsStatusField } from './OrderShowLogsStatusField/OrderShowLogsStatusField';
import { OrderShowLogsTypeField } from './OrderShowLogsTypeField/OrderShowLogsTypeField';
import { StyledDatagrid } from './OrdersShowLogs.styled';

export const OrdersShowLogs = (): JSX.Element => {
  return (
    <ReferenceManyField
      label={false}
      pagination={<Pagination />}
      reference="order_events"
      sort={{ field: 'requestAt', order: 'DESC' }}
      target="orderId"
    >
      <StyledDatagrid bulkActionButtons={false} expand={<OrderRequestLogsExpand />}>
        <DateTimeField source="createdAt" timeMask="HH:mm:sss" />
        <OrderShowLogsDirectionField cellClassName="column-direction" />
        <WrapperField>
          <Stack gap="4px">
            <Stack alignItems="center" direction="row" gap="8px">
              <OrderShowLogsStatusField />
              <OrderShowLogsDelayField />
              <TextField source="requestUrl" />
            </Stack>
            <OrderShowLogsTypeField />
            <OrderShowLogsChangeStatusesField />
          </Stack>
        </WrapperField>
      </StyledDatagrid>
    </ReferenceManyField>
  );
};
