import { styled } from '@mui/material/styles';
import { DatagridWithStickyActions } from 'shared/react-admin/Datagrids/DatagridWithStickyActions/DatagridWithStickyActions';
import { getColumnStyle } from 'utils';

const trimmedStrStyle = {
  p: {
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const columnStyle136 = getColumnStyle(136);

export const StyledDatagridWithStickyActions = styled(DatagridWithStickyActions)({
  '& .column-originId': getColumnStyle(88),
  '& .column-firstName': {
    ...getColumnStyle(280),
    ...trimmedStrStyle,
  },
  '& .column-roleEntities': getColumnStyle(308),
  '& .column-enabled': getColumnStyle(96),
  '& .column-lastActivity': columnStyle136,
  '& .column-createdAt': columnStyle136,
  '& .column-updatedAt': columnStyle136,
  '& .column-reddyNumber': getColumnStyle(128),
  '& .column-corporateEmail': getColumnStyle(168),
  '& .column-twoFactor': getColumnStyle(96),
});
