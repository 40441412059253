import { Role } from 'constants/roles';

import React, { useEffect, useState } from 'react';

import { BalanceReportSuccessAlert } from 'components/Finance/BalanceReport/components/BalanceReportAlerts/BalanceReportSuccessAlert';
import { useApiContext } from 'context/apiContext';
import { useListContext } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';
import { CompanyBalanceReport } from 'types';
import { cleanEmpty } from 'utils/cleanEmpty';

import { StyledBox, StyledRowBox } from './BalanceReportDatagrid.styled';
import { BalanceReportFilterValues } from '../../BalanceReportList';
import { BalanceReportContextProvider } from '../../context/BalanceReportContext';
import { BalanceReportAccordion } from '../BalanceReportAccordion/BalanceReportAccordion';
import { BalanceReportBoxCell } from '../BalanceReportBoxCell';
import { BalanceReportMerchantAccountRow } from '../BalanceReportMerchantAccountRow';
import { BalanceReportRow } from '../BalanceReportRow';

export const BalanceReportDatagrid: React.FC<{ filters: BalanceReportFilterValues }> = ({
  filters,
}) => {
  const { data } = useListContext<CompanyBalanceReport>();
  const [showSuccessAlert, setShowSuccessAlert] = useState(true);

  const isFilterApplied = Object.keys(cleanEmpty(filters)).length > 0;
  const { user } = useApiContext();
  const isFinanceUser = user?.roles.includes(Role.Finance);

  useEffect(() => {
    setShowSuccessAlert(true);
  }, [data]);

  if (!data?.length && isFilterApplied && isFinanceUser) {
    return (
      <Alert severity="warning">
        {`Не удалось сформировать отчет по указанным критериям. 
        Возможно, для пользователя ${user.firstName} ${user.lastName} не настроена "Рабочая группа" и "Личные кабинеты", обратитесь к руководителю вашего подразделения для настроек доступа.`}
      </Alert>
    );
  }

  if (!data?.length) {
    return (
      <Alert severity="info">
        <Typography fontWeight={500}>Здесь пока нет отчета по балансам.</Typography>
        <Typography>
          Чтобы сформировать отчет, выберите параметры в фильтре и нажмите кнопку “Применить”.
        </Typography>
      </Alert>
    );
  }

  return (
    <>
      {data?.length && showSuccessAlert && (
        <BalanceReportSuccessAlert filters={filters} onClose={() => setShowSuccessAlert(false)} />
      )}
      <BalanceReportContextProvider value={{ filters }}>
        <StyledBox border={`1px solid ${appColors.divider}`} borderRadius={1}>
          <StyledBox key="box" maxWidth="100%" minWidth="fit-content">
            <StyledRowBox borderBottom={`1px solid ${appColors.divider}`} firstCellWidth={400}>
              <BalanceReportBoxCell header sx={{ marginLeft: '36px' }}>
                Компания / Мерчант / Провайдер / Счет
              </BalanceReportBoxCell>
              <BalanceReportBoxCell header>Валюта счета</BalanceReportBoxCell>
              <BalanceReportBoxCell header>Сумма на начало</BalanceReportBoxCell>
              <BalanceReportBoxCell header>Пополнения</BalanceReportBoxCell>
              <BalanceReportBoxCell header>Выводы</BalanceReportBoxCell>
              <BalanceReportBoxCell header>Рефанды</BalanceReportBoxCell>
              <BalanceReportBoxCell header>Чарджбеки</BalanceReportBoxCell>
              <BalanceReportBoxCell header>Комиссии</BalanceReportBoxCell>
              <BalanceReportBoxCell header>Итого</BalanceReportBoxCell>
              <BalanceReportBoxCell header>Сумма на конец</BalanceReportBoxCell>
              <BalanceReportBoxCell header />
            </StyledRowBox>
            {data?.map((company) => {
              return (
                <BalanceReportAccordion
                  key={company.id}
                  paddingFirstCell={8}
                  row={
                    <BalanceReportRow
                      amountData={company.amountData}
                      name={company.name}
                      paddingFirstCell={12}
                    />
                  }
                  sx={{ borderLeft: `4px solid ${appColors.primary.main}` }}
                >
                  {company.merchants.map((merchant) => {
                    return (
                      <BalanceReportAccordion
                        key={merchant.id}
                        paddingFirstCell={48}
                        row={
                          <BalanceReportRow
                            amountData={merchant.amountData}
                            name={merchant.name}
                            paddingFirstCell={48}
                          />
                        }
                      >
                        {merchant.providers.map((provider) => {
                          return (
                            <BalanceReportAccordion
                              key={provider.id}
                              paddingFirstCell={80}
                              row={
                                <BalanceReportRow
                                  amountData={provider.amountData}
                                  name={provider.name}
                                  paddingFirstCell={80}
                                />
                              }
                            >
                              {provider.merchantAccounts.map((merchantAccount) => {
                                return (
                                  <BalanceReportMerchantAccountRow
                                    company={company}
                                    key={merchantAccount.id}
                                    merchant={merchant}
                                    merchantAccount={merchantAccount}
                                    paddingFirstCell={80}
                                    provider={provider}
                                  />
                                );
                              })}
                            </BalanceReportAccordion>
                          );
                        })}
                      </BalanceReportAccordion>
                    );
                  })}
                </BalanceReportAccordion>
              );
            })}
          </StyledBox>
        </StyledBox>
      </BalanceReportContextProvider>
    </>
  );
};
