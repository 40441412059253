import { EXPORT_REPORT_NAMES, ExportJobTypes } from 'constants/exportFormats';

import { format, fromUnixTime } from 'date-fns';
import { Typography } from 'shared/mui/Typography';

type NotificationPopperListItemPrimaryTextProps = {
  exportType: ExportJobTypes;
  periodFrom: number;
  periodTo: number;
};

export const NotificationPopperListItemReportPrimary: React.FC<
  NotificationPopperListItemPrimaryTextProps
> = ({ exportType, periodFrom, periodTo }) => (
  <>
    <Typography component="span">{EXPORT_REPORT_NAMES[exportType]}</Typography>
    {periodFrom && periodTo && (
      <Typography component="span">
        {` ${format(fromUnixTime(periodFrom), 'dd.MM.yyyy')} — ${format(fromUnixTime(periodTo), 'dd.MM.yyyy')}`}
      </Typography>
    )}
  </>
);
