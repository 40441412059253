import React from 'react';

import { getUnixTime } from 'date-fns';
import { Link } from 'react-router-dom';
import { Chip } from 'shared/mui/Chips';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { IconButton } from 'shared/mui/IconButtons';
import { appColors } from 'themes/variables';
import {
  MerchantAccountBalanceReport,
  MerchantBalanceReport,
  ProviderBalanceReport,
  CompanyBalanceReport,
} from 'types';
import { convertReactAdminIdToHydraId } from 'utils';
import { createFilterQueryString } from 'utils/createFilterQueryString';

import { AmountDataField } from './BalanceReportAmountField';
import { BalanceReportBoxCell } from './BalanceReportBoxCell';
import { StyledBoxCell, StyledRowBox } from './BalanceReportDatagrid/BalanceReportDatagrid.styled';
import { ReactComponent as CompletedReport } from '../../../../images/completedReport.svg';
import { ReactComponent as CumulativeReport } from '../../../../images/cumulativeReport.svg';
import { useBalanceReportContext } from '../context/BalanceReportContext';

type Props = {
  merchantAccount: MerchantAccountBalanceReport;
  paddingFirstCell: number;
  merchant: MerchantBalanceReport;
  provider: ProviderBalanceReport;
  company: CompanyBalanceReport;
};

export const BalanceReportMerchantAccountRow: React.FC<Props> = ({
  paddingFirstCell,
  merchantAccount,
  provider,
  merchant,
  company,
}) => {
  const { filters } = useBalanceReportContext();

  return (
    <StyledRowBox
      firstCellWidth={400}
      sx={{
        borderBottom: `1px solid ${appColors.divider}`,
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.04)',
        },
      }}
    >
      <StyledBoxCell
        sx={{
          paddingLeft: `${paddingFirstCell}px`,
        }}
      >
        <Chip color="primary" label={merchantAccount.name} sx={{ color: 'white' }} />
      </StyledBoxCell>
      <BalanceReportBoxCell>{`${merchantAccount.currencyCode}, ${merchantAccount.currency}`}</BalanceReportBoxCell>
      <StyledBoxCell>
        <AmountDataField
          color={appColors.text.dark}
          convertedValue={`${merchantAccount.amountData.convertedBeginPeriodBalance} ${filters?.currency}`}
          originalValue={`${merchantAccount.amountData.beginPeriodBalance} ${merchantAccount.currencyCode}`}
        />
      </StyledBoxCell>
      <StyledBoxCell>
        <AmountDataField
          color={appColors.success.main}
          convertedValue={`${merchantAccount.amountData.convertedPaymentAmount} ${filters?.currency}`}
          originalValue={`${merchantAccount.amountData.paymentAmount} ${merchantAccount.currencyCode}`}
        />
      </StyledBoxCell>
      <StyledBoxCell>
        <AmountDataField
          color={appColors.error.main}
          convertedValue={`${merchantAccount.amountData.convertedPayoutAmount} ${filters?.currency}`}
          originalValue={`${merchantAccount.amountData.payoutAmount} ${merchantAccount.currencyCode}`}
        />
      </StyledBoxCell>
      <StyledBoxCell>
        <AmountDataField
          color={appColors.text.dark}
          convertedValue={`${merchantAccount.amountData.convertedRefundAmount} ${filters?.currency}`}
          originalValue={`${merchantAccount.amountData.refundAmount} ${merchantAccount.currencyCode}`}
        />
      </StyledBoxCell>
      <StyledBoxCell>
        <AmountDataField
          color={appColors.text.dark}
          convertedValue={`${merchantAccount.amountData.convertedChargebackAmount} ${filters?.currency}`}
          originalValue={`${merchantAccount.amountData.chargebackAmount} ${merchantAccount.currencyCode}`}
        />
      </StyledBoxCell>
      <StyledBoxCell>
        <AmountDataField
          color={appColors.text.dark}
          convertedValue={`${merchantAccount.amountData.convertedCommissionAmount} ${filters?.currency}`}
          originalValue={`${merchantAccount.amountData.commissionAmount} ${merchantAccount.currencyCode}`}
        />
      </StyledBoxCell>
      <StyledBoxCell>
        <AmountDataField
          color={appColors.text.dark}
          convertedValue={`${merchantAccount.amountData.convertedTotal} ${filters?.currency}`}
          originalValue={`${merchantAccount.amountData?.total} ${merchantAccount?.currencyCode}`}
        />
      </StyledBoxCell>
      <StyledBoxCell>
        <AmountDataField
          color={appColors.text.dark}
          convertedValue={`${merchantAccount.amountData.convertedEndPeriodBalance} ${filters?.currency}`}
          originalValue={`${merchantAccount.amountData.endPeriodBalance} ${merchantAccount.currencyCode}`}
        />
      </StyledBoxCell>
      <StyledBoxCell
        sx={{
          display: 'flex',
          gap: '12px',
          alignItems: 'center',
        }}
      >
        <IconButton
          component={Link}
          target="_blank"
          to={{
            pathname: '/orders_report',
            search: createFilterQueryString({
              merchantAccount: [
                convertReactAdminIdToHydraId(merchantAccount.id, 'merchant_accounts'),
              ],
              provider: convertReactAdminIdToHydraId(provider.id, 'providers'),
              merchant: convertReactAdminIdToHydraId(merchant.id, 'merchants'),
              company: convertReactAdminIdToHydraId(company.id, 'companies'),
              transactions: {
                createdAt: {
                  gte:
                    filters?.appliedDate && filters?.appliedDate[0]
                      ? getUnixTime(filters?.appliedDate[0])
                      : filters.periodFrom,
                  lte:
                    filters?.appliedDate && filters?.appliedDate[1]
                      ? getUnixTime(filters?.appliedDate[1])
                      : filters.periodTo,
                },
              },
              timezone: filters?.appliedDate?.[2] || DEFAULT_TIMEZONE,
            }),
          }}
        >
          <CompletedReport />
        </IconButton>
        <IconButton
          component={Link}
          target="_blank"
          to={{
            pathname: '/cumulative_report',
            search: createFilterQueryString({
              company: convertReactAdminIdToHydraId(company.id, 'companies'),
              merchantAccounts: [
                convertReactAdminIdToHydraId(merchantAccount.id, 'merchant_accounts'),
              ],
              provider: convertReactAdminIdToHydraId(provider.id, 'providers'),
              merchant: convertReactAdminIdToHydraId(merchant.id, 'merchants'),
              currency: merchantAccount?.currencyCode,
              periodFrom:
                filters?.appliedDate && filters?.appliedDate[0]
                  ? getUnixTime(filters?.appliedDate[0])
                  : filters?.periodFrom?.toString(),
              periodTo:
                filters?.appliedDate && filters?.appliedDate[1]
                  ? getUnixTime(filters?.appliedDate[1])
                  : filters?.periodTo?.toString(),
              timezone: filters?.appliedDate?.[2] || DEFAULT_TIMEZONE,
            }),
          }}
        >
          <CumulativeReport />
        </IconButton>
      </StyledBoxCell>
    </StyledRowBox>
  );
};
