import { EMPTY_FIELD } from 'constants/emptyField';

import { DirectionField } from 'components/Finance/OrdersReport/components/DirectionField';
import { useLocalDatagrid } from 'hooks/useLocalDatagrid';
import { useLocalDatagridPagination } from 'hooks/useLocalDatagridPagination';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { TablePagination } from 'shared/mui/Table';
import { Typography } from 'shared/mui/Typography';
import {
  ChipField,
  TextField,
  FunctionField,
  NumberField,
  DateTimeField,
  CopyToClipboardLinkField,
  CopyToClipboardField,
} from 'shared/react-admin/Fields';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ReferenceLink } from 'shared/react-admin/ReferenceFields/ReferenceLink/ReferenceLink';
import { CommissionShowDialog } from 'shared/widgets/CommissionShowDialog/CommissionShowDialog';
import { MerchantShowAccountsShowDialog } from 'shared/widgets/MerchantAccountShowDialog/MerchantAccountShowDialog';
import { appColors } from 'themes/variables';
import { Commission, MerchantAccount, Method, Order } from 'types';
import { formatCurrency, getId } from 'utils';

import { DatagridConfigurableStyled, ImageFieldStyled } from './OrdersReportLocalDatagrid.styled';
import { OrdersReportListRuleField } from '../OrdersReportListRuleField/OrdersReportListRuleField';
import { OrdersReportListTotalAmountField } from '../OrdersReportListTotalAmountField/OrdersReportListTotalAmountField';

type Props = {
  orders: Order[];
  total: number;
  isLoading: boolean;
  timezone?: string;
};

const getCommissionValue = (record: RaRecord, fieldName: string) => {
  if (!record?.[fieldName]) {
    return EMPTY_FIELD;
  }

  return (
    <>
      <Typography component="span" variant="body2">
        {formatCurrency(record?.[fieldName]?.value)}
      </Typography>{' '}
      <Typography component="span" sx={{ fontWeight: 500 }} variant="body2">
        {record?.[fieldName]?.currency?.alphaCode}
      </Typography>
    </>
  );
};

export const OrdersReportLocalDatagrid: React.FC<Props> = ({
  total,
  isLoading,
  orders = [],
  timezone = DEFAULT_TIMEZONE,
}) => {
  const { hasResourceAccess } = usePermissions();
  const {
    record: merchantAccount,
    handleOpenRecordModal: handleOpenMerchantAccountModal,
    handleCloseRecordModal: handleCloseMerchantAccountModal,
    openRecordModal: openMerchantAccountModal,
  } = useRecordModal<MerchantAccount>();

  const { record, handleOpenRecordModal, handleCloseRecordModal, openRecordModal } =
    useRecordModal<Commission>();

  const { sortedData, sort, setSort } = useLocalDatagrid({
    data: orders,
    defaultSort: {
      field: 'createdAt',
      order: 'ASC',
    },
  });

  const { dataPerPage, ...paginationProps } = useLocalDatagridPagination<Order>({
    data: sortedData,
    defaultPage: 0,
    defaultRowsPerPage: 10,
  });

  return (
    <>
      {record && (
        <CommissionShowDialog
          commission={record}
          onClose={handleCloseRecordModal}
          open={openRecordModal}
        />
      )}
      <DatagridConfigurableStyled
        bulkActionButtons={false}
        data={dataPerPage}
        empty={
          <Alert severity="info">
            <Typography fontWeight={500} mb="5px">
              Здесь пока нет отчета по исполненным транзакциям.
            </Typography>
            <Typography fontSize={14}>
              Чтобы сформировать отчет, выберите параметры в фильтре и нажмите кнопку “Применить”.
            </Typography>
          </Alert>
        }
        isLoading={isLoading}
        setSort={setSort}
        sort={sort}
        sx={{ width: '100%' }}
      >
        <CopyToClipboardLinkField
          getLink={(record: RaRecord) => `/orders/${encodeURIComponent(record.id)}/show`}
          label="Kubera ID"
          sortBy="id"
          source="originId"
          target="_blank"
        />
        <DateTimeField label="Создан" resource="orders" source="createdAt" timezone={timezone} />
        <DateTimeField
          emptyText={EMPTY_FIELD}
          label="Исполнен"
          resource="orders"
          source="completedAt"
          timezone={timezone}
        />
        <NumberField
          label="Сумма заказа"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          resource="orders"
          source="amount"
        />
        {hasResourceAccess('currencies') && (
          <ReferenceOneField
            label="Валюта"
            link={false}
            reference="currencies"
            resource="currencies"
            sortable={false}
            source="currency"
            target="id"
          >
            <FunctionField
              render={(record?: RaRecord) => record?.alphaCode}
              resource="orders"
              sx={{ fontWeight: 500 }}
            />
          </ReferenceOneField>
        )}
        <FunctionField
          label="Комиссия по заказу"
          render={(record: RaRecord) =>
            getCommissionValue(record, 'commissionValueByCurrencyOrder')
          }
          source="commissionValueByCurrencyOrder"
        />
        <FunctionField
          label="Комиссия по правилу"
          render={(record: RaRecord) =>
            getCommissionValue(record, 'commissionValueByCurrencyCommission')
          }
          source="commissionValueByCurrencyCommission"
        />
        {hasResourceAccess('commissions') && (
          <OrdersReportListRuleField
            label="Правило, %"
            onClick={handleOpenRecordModal}
            source="commission"
          />
        )}
        <OrdersReportListTotalAmountField label="Итого по заказу" source="totalAmount" />
        <DirectionField label="Направление" source="direction" />
        <ReferenceOneField
          label="Мерчант"
          link={false}
          reference="merchants"
          resource="orders"
          sortable={false}
          source="merchant"
          target="id"
        >
          <ReferenceLink type="show">
            <ChipField source="name" />
          </ReferenceLink>
        </ReferenceOneField>
        <ReferenceField
          label="Счет мерчанта"
          link={false}
          reference="merchant_accounts"
          resource="merchant_accounts"
          sortable={false}
          source="merchantAccount"
        >
          <FunctionField
            render={(record: MerchantAccount) => (
              <Typography
                color="secondary"
                onClick={() => handleOpenMerchantAccountModal(record)}
                sx={{
                  color: appColors.primary.main,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                variant="body2"
              >
                {record.name}
              </Typography>
            )}
          />
        </ReferenceField>
        <ReferenceOneField
          label="Шлюз"
          link={false}
          reference="gateways"
          resource="orders"
          sortable={false}
          source="gateway"
          target="id"
        >
          <TextField source="name" />
        </ReferenceOneField>
        <ReferenceOneField
          emptyText={EMPTY_FIELD}
          label="Метод"
          link={false}
          reference="methods"
          resource="methods"
          sortable={false}
          source="method"
          target="id"
        >
          <FunctionField
            render={(record: Method) => (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {hasResourceAccess('method_logos') && (
                  <ReferenceOneField reference="method_logos" source="logo" target="id">
                    <ImageFieldStyled source="filePath" />
                  </ReferenceOneField>
                )}
                <Typography variant="body2">{record?.name}</Typography>
              </Box>
            )}
          />
        </ReferenceOneField>
        <CopyToClipboardField
          getValue={getId}
          isTrimValue
          label="Мерчант ID"
          source="merchantReference"
        />
        <CopyToClipboardField
          getValue={getId}
          isTrimValue
          label="Провайдер ID"
          source="providerReferenceId"
        />
      </DatagridConfigurableStyled>
      {total > 0 && (
        <TablePagination
          count={total}
          sx={{ '.MuiToolbar-root': { maxHeight: 0 } }}
          {...paginationProps}
        />
      )}
      {merchantAccount && (
        <MerchantShowAccountsShowDialog
          merchantAccount={merchantAccount}
          onClose={handleCloseMerchantAccountModal}
          open={openMerchantAccountModal}
        />
      )}
    </>
  );
};
