import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { CumulativeReportFilterValuesNew } from 'components/Finance/CumulativeReport/CumulativeReportFilterNew';
import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Button } from 'shared/mui/Buttons';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';

import { StyledCumulativeReportSummaryPeriod } from './CumulativeReportSummaryPeriod.styled';

type CumulativeReportSummaryPeriodProps = {
  filters: CumulativeReportFilterValuesNew;
  toggleIsOpen: () => void;
  isOpen: boolean;
};

export const CumulativeReportSummaryPeriod: React.FC<CumulativeReportSummaryPeriodProps> = ({
  filters,
  toggleIsOpen,
  isOpen,
}) => {
  const alertText = `Итого за период ${formatInTimeZone(
    fromUnixTime(filters?.periodFrom || 0),
    filters?.timezone || DEFAULT_TIMEZONE,
    'dd.MM.yyyy',
  )} — ${formatInTimeZone(fromUnixTime(filters?.periodTo || 0), filters?.timezone || DEFAULT_TIMEZONE, 'dd.MM.yyyy')}`;

  return (
    <StyledCumulativeReportSummaryPeriod isOpen={isOpen}>
      <div>{alertText}</div>
      <Button
        endIcon={isOpen ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        label={isOpen ? 'Свернуть итог' : 'Развернуть итог'}
        onClick={toggleIsOpen}
        size="small"
        sx={{ minWidth: 'fit-content' }}
      />
    </StyledCumulativeReportSummaryPeriod>
  );
};
