import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';

import { appColors } from '../../../../themes/variables';

export const BoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '8px',
  padding: '16px 0',
  borderBottom: `1px solid ${appColors.divider}`,

  '&:last-child': {
    borderBottom: 'none',
  },
});

export const AvatarWrapperStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'flex-start',
});
