import {
  createGatewayConversionSettings,
  CreateGatewayConversionSettingsParams,
} from 'config/requests';
import { useNotify } from 'react-admin';
import { useMutation } from 'react-query';

export function useCreateGatewayConversionSettings() {
  const notify = useNotify();

  const mutation = useMutation(
    (data: CreateGatewayConversionSettingsParams) => createGatewayConversionSettings(data),
    {
      onSuccess: async () => {
        notify('Настройки успешно созданы', { type: 'success' });
      },
      onError: (e) => {
        throw e;
      },
    },
  );

  return { createGatewayConversionSettings: mutation };
}
