import { useState } from 'react';

import { CumulativeReportFilterValuesNew } from 'components/Finance/CumulativeReport/CumulativeReportFilterNew';

import { CumulativeReportSummaryPeriod } from './CumulativeReportPeriods/CumulativeReportSummaryPeriod';
import { CumulativeReportSummaryDatagrid } from './CumulativeReportSummaryDatagrid';

export type CumulativeReportSummaryCollapsibleProps = {
  filters: CumulativeReportFilterValuesNew;
};

export function CumulativeReportSummaryCollapsible({
  filters,
}: CumulativeReportSummaryCollapsibleProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);

  function toggleIsOpen(): void {
    setIsOpen((prev) => !prev);
  }

  if (isOpen) {
    return (
      <div style={{ marginBottom: '16px' }}>
        <CumulativeReportSummaryPeriod
          filters={filters}
          isOpen={isOpen}
          toggleIsOpen={toggleIsOpen}
        />
        <CumulativeReportSummaryDatagrid />
      </div>
    );
  }
  return (
    <div style={{ marginBottom: '16px' }}>
      <CumulativeReportSummaryPeriod
        filters={filters}
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
      />
    </div>
  );
}
