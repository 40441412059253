import { useState } from 'react';

import { ArrowBack } from '@mui/icons-material';
import ContentAdd from '@mui/icons-material/Add';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { blue } from '@mui/material/colors';
import { ListContextProvider, useListController } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { LoaderCircleMain } from 'shared/mui/LoaderCircleMain/LoaderCircleMain';
import { TooltipOverflow } from 'shared/mui/Tooltip/TooltipOverflow/TooltipOverflow';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsUserActionsButton,
  DatagridActionsUserEntityPermissionsDialogButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import { DatagridActionsEditDialogButton } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActionsEditDialogButton/DatagridActionsEditDialogButton';
import { DatagridActionsShowDialogButton } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActionsShowDialogButton/DatagridActionsShowDialogButton';
import { DateTimeField, FunctionField, TextField } from 'shared/react-admin/Fields';
import { Pagination } from 'shared/react-admin/Pagination/Pagination';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { Toolbar } from 'shared/react-admin/Toolbars/Toolbar';
import { ProviderShowDialog } from 'shared/widgets/ProviderShowDialog/ProviderShowDialog';
import { UserEntityPermissionsNames } from 'shared/widgets/UserEntityPermissionsDialog/constants/userEntityPermissionsNames';
import { UserEntityPermissionsDialog } from 'shared/widgets/UserEntityPermissionsDialog/UserEntityPermissionsDialog';

import { CardStyled, StyledDatagrid } from './MerchantsShowCredentials.styled';
import { MerchantsShowCredentialsCreateDialog } from './MerchantsShowCredentialsCreateDialog/MerchantsShowCredentialsCreateDialog';
import { MerchantsShowCredentialsDeleteDialog } from './MerchantsShowCredentialsDeleteDialog/MerchantsShowCredentialsDeleteDialog';
import { MerchantsShowCredentialsDialog } from './MerchantsShowCredentialsDialog/MerchantsShowCredentialsDialog';
import { MerchantsShowCredentialsEditDialog } from './MerchantsShowCredentialsEditDialog/MerchantsShowCredentialsEditDialog';
import { MerchantsShowCredentialsFilters } from './MerchantsShowCredentialsFilters/MerchantsShowCredentialsFilters';
import { MerchantsShowCredentialsShowDialog } from './MerchantsShowCredentialsShowDialog/MerchantsShowCredentialsShowDialog';
import { EMPTY_FIELD } from '../../../../constants';
import { usePermissions } from '../../../../hooks/usePermissions';
import { useRecordModal } from '../../../../hooks/useRecordModal';
import { MerchantCredentials, Permission, Provider } from '../../../../types';

type Props = {
  merchantId?: string;
  merchantName?: string;
};

export const MerchantsShowCredentials: React.FC<Props> = ({ merchantId, merchantName }) => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();

  const listContext = useListController({
    resource: 'merchant_credentials',
    filter: {
      merchant: merchantId,
    },
    sort: { field: 'id', order: 'DESC' },
    disableSyncWithLocation: true,
  });

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const {
    record: provider,
    handleCloseRecordModal: handleCloseRecordModalProvider,
    openRecordModal: openRecordModalProvider,
    handleOpenRecordModal: handleOpenRecordModalProvider,
  } = useRecordModal<Provider>();

  const {
    record: recordCredentialsDialog,
    handleCloseRecordModal: handleCloseCredentialsDialog,
    openRecordModal: openCredentialsDialog,
    handleOpenRecordModal: handleOpenCredentialsDialog,
  } = useRecordModal<MerchantCredentials>();

  const {
    record: recordShowDialog,
    handleCloseRecordModal: handleCloseShowDialog,
    openRecordModal: openShowDialog,
    handleOpenRecordModal: handleOpenShowDialog,
  } = useRecordModal<MerchantCredentials>();

  const {
    record: recordEditDialog,
    handleCloseRecordModal: handleCloseEditDialog,
    openRecordModal: openEditDialog,
    handleOpenRecordModal: handleOpenEditDialog,
  } = useRecordModal<MerchantCredentials>();

  const {
    record: recordDeleteDialog,
    handleCloseRecordModal: handleCloseDeleteDialog,
    openRecordModal: openDeleteDialog,
    handleOpenRecordModal: handleOpenDeleteDialog,
  } = useRecordModal<MerchantCredentials>();

  const {
    record: recordPermissionsDialog,
    handleCloseRecordModal: handleClosePermissionsDialog,
    openRecordModal: openPermissionsDialog,
    handleOpenRecordModal: handleOpenPermissionsDialog,
  } = useRecordModal<MerchantCredentials>();

  const isShowAlert =
    (listContext?.data?.length <= 0 || !listContext?.data) &&
    !Object.keys(listContext.filterValues).length;

  if (listContext?.isLoading) {
    return <LoaderCircleMain sx={{ position: 'absolute', top: '52%', left: '52%' }} />;
  }

  return (
    <ListContextProvider value={listContext}>
      <CardStyled>
        <CardHeader
          actions={[
            hasResourcePermissions('merchant_credentials', [
              Permission.Full,
              Permission.Create,
            ]) && (
              <Button
                key="create"
                label="Добавить"
                onClick={() => setOpenCreateDialog(true)}
                startIcon={<ContentAdd />}
                sx={{ marginTop: '8px', padding: '3px 16px' }}
                variant="contained"
              />
            ),
          ]}
          sx={{ padding: '0 0 16px 0' }}
          title="Список реквизитов доступа"
          titleSize="small"
        />
        {isShowAlert ? (
          <Alert severity="info">Мерчанту {merchantName} не добавлены реквизиты доступа.</Alert>
        ) : (
          <>
            <MerchantsShowCredentialsFilters />
            <StyledDatagrid bulkActionButtons={false}>
              <TextField label="ID" sortBy="id" source="originId" />
              <FunctionField
                label="Название"
                render={(record?: MerchantCredentials) => (
                  <TextField
                    onClick={() => handleOpenShowDialog(record)}
                    source="name"
                    sx={{
                      cursor: 'pointer',
                      color: blue[700],
                      textDecoration: 'underline',
                    }}
                  />
                )}
                source="name"
              />
              <ReferenceOneField
                label="Провайдер"
                reference="providers"
                sortable={false}
                source="provider"
                target="id"
              >
                <FunctionField
                  render={(record: Provider | undefined) => (
                    <TextField
                      onClick={() => handleOpenRecordModalProvider(record)}
                      source="name"
                      sx={{
                        cursor: 'pointer',
                        color: blue[700],
                        textDecoration: 'underline',
                      }}
                    />
                  )}
                />
              </ReferenceOneField>
              <FunctionField
                label="Комментарий"
                render={({ comment }: MerchantCredentials) => {
                  if (!comment) {
                    return EMPTY_FIELD;
                  }

                  return <TooltipOverflow>{comment}</TooltipOverflow>;
                }}
                sortable={false}
                source="comment"
              />
              <FunctionField
                label="Реквизиты доступа {json}"
                render={(record: MerchantCredentials) =>
                  record.credentials ? (
                    <Button
                      label="Показать"
                      onClick={() => handleOpenCredentialsDialog(record)}
                      size="small"
                      startIcon={<DataObjectIcon />}
                    />
                  ) : (
                    <Button
                      disabled
                      label="НЕТ ДОСТУПА"
                      size="small"
                      startIcon={<DataObjectIcon />}
                    />
                  )
                }
                sortable={false}
                source="credentials"
              />
              <DateTimeField label="Дата создания" source="createdAt" />
              <DateTimeField label="Дата редактир." source="updatedAt" />
              <DatagridActions
                render={(record: MerchantCredentials) => (
                  <>
                    {hasResourceAccess('user_entity_permissions') && (
                      <DatagridActionsUserEntityPermissionsDialogButton
                        onClick={() => handleOpenPermissionsDialog(record)}
                      />
                    )}
                    {hasResourceAccess('user_actions') && (
                      <DatagridActionsUserActionsButton
                        entityName="merchant_credentials"
                        key="user-action-link"
                        target="_blank"
                      />
                    )}
                    {hasResourcePermissions('merchant_credentials', [
                      Permission.Full,
                      Permission.Read,
                    ]) && (
                      <DatagridActionsShowDialogButton
                        onClick={() => handleOpenShowDialog(record)}
                      />
                    )}
                    {hasResourcePermissions('merchant_credentials', [
                      Permission.Full,
                      Permission.Update,
                    ]) &&
                      !record?.locked && (
                        <DatagridActionsEditDialogButton
                          onClick={() => handleOpenEditDialog(record)}
                        />
                      )}
                    {hasResourcePermissions('merchant_credentials', [
                      Permission.Full,
                      Permission.Delete,
                    ]) && (
                      <DatagridActionsDeleteButton onClick={() => handleOpenDeleteDialog(record)} />
                    )}
                  </>
                )}
              />
            </StyledDatagrid>
            <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Pagination sx={{ width: '100%' }} />
            </Toolbar>
          </>
        )}
        {provider && (
          <ProviderShowDialog
            onClose={handleCloseRecordModalProvider}
            open={openRecordModalProvider}
            provider={provider}
          />
        )}
        <MerchantsShowCredentialsDeleteDialog
          onClose={handleCloseDeleteDialog}
          open={openDeleteDialog}
          record={recordDeleteDialog}
        />
        <MerchantsShowCredentialsEditDialog
          merchantId={merchantId}
          onClose={handleCloseEditDialog}
          onDelete={handleOpenDeleteDialog}
          open={openEditDialog}
          record={recordEditDialog}
        />
        <MerchantsShowCredentialsCreateDialog
          merchantId={merchantId}
          onClose={() => setOpenCreateDialog(false)}
          open={openCreateDialog}
        />
        <MerchantsShowCredentialsShowDialog
          handleOpenCredentialsDialog={handleOpenCredentialsDialog}
          handleOpenPermissionsDialog={handleOpenPermissionsDialog}
          id={recordShowDialog?.id}
          onClose={handleCloseShowDialog}
          onDelete={handleOpenDeleteDialog}
          onEdit={handleOpenEditDialog}
          open={openShowDialog}
        />
        <MerchantsShowCredentialsDialog
          actions={
            <CardActionsButtons
              leftActionsSlot={
                <Button
                  label="К просмотру"
                  onClick={() => {
                    handleCloseCredentialsDialog();
                    handleOpenShowDialog(recordCredentialsDialog);
                  }}
                  startIcon={<ArrowBack />}
                  variant="outlined"
                />
              }
              rightActionsSlot={
                <Button
                  label="Редактировать"
                  onClick={() => {
                    handleCloseCredentialsDialog();
                    handleOpenEditDialog(recordCredentialsDialog);
                  }}
                  variant="outlined"
                />
              }
            />
          }
          onClose={() => handleCloseCredentialsDialog()}
          open={openCredentialsDialog}
          record={recordCredentialsDialog}
        />
        {recordPermissionsDialog && openPermissionsDialog && (
          <UserEntityPermissionsDialog
            entityName={UserEntityPermissionsNames.MerchantCredentials}
            onClose={handleClosePermissionsDialog}
            open={openPermissionsDialog}
            record={recordPermissionsDialog}
            titleCreate="Включение доступа к реквизитам"
            titleEdit="Редактирование доступа к реквизитам"
          />
        )}
      </CardStyled>
    </ListContextProvider>
  );
};
