import { useState } from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { Form, RecordContextProvider, useNotify, useUpdate } from 'react-admin';
import { Button, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { MerchantAccount, Permission } from 'types';

import { MerchantShowAccountsForm } from '../components/MerchantShowAccountsForm/MerchantShowAccountsForm';

type Props = {
  open: boolean;
  onClose: () => void;
  onDelete: (record: MerchantAccount) => void;
  record?: MerchantAccount;
  merchantId?: string;
};

type EditFormValues = {
  merchant: string;
  provider: string;
  gateway: string;
  name: string;
  credentials: Record<string, any>;
};

export const MerchantShowAccountsEditDialog: React.FC<Props> = ({
  open,
  onClose,
  onDelete,
  merchantId,
  record,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const { hasResourcePermissions } = usePermissions();
  const [update] = useUpdate();
  const notify = useNotify();

  const onSubmit = (formValues: EditFormValues) => {
    update(
      'merchant_accounts',
      {
        id: record?.id,
        data: {
          ...formValues,
          merchants: [merchantId],
        },
      },
      {
        onSuccess: () => {
          notify('Счет мерчанта успешно отредактирован!', {
            type: 'success',
          });
          onClose();
        },
        onError: (error) => {
          setErrors((error as Error)?.message.split('\n'));
          notify(`Ошибка: ${(error as Error).message}`, { type: 'error' });
        },
      },
    );
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={record}>
        <Form onSubmit={onSubmit as any}>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Редактирование счёта"
          />
          <MerchantShowAccountsForm errors={errors} merchantId={merchantId} />
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="save" label="Сохранить" type="submit" variant="contained" />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
            rightActionsSlot={
              hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Delete]) && (
                <DeleteButton
                  onClick={() => {
                    onClose();
                    record && onDelete(record);
                  }}
                  variant="outlined"
                />
              )
            }
          />
        </Form>
      </RecordContextProvider>
    </Dialog>
  );
};
