import { formatToMask } from 'shared/mui/DatePicker/utils/formatToMask';

import { DEFAULT_DATE_MASK, DEFAULT_TIMEZONE } from '../config/defaultValues';

export function fromTimestampToString(
  date: number | null,
  mask: string = DEFAULT_DATE_MASK,
  timezone: string = DEFAULT_TIMEZONE,
): string {
  const placeholder = mask.replaceAll(/([a-zA-Z])/g, '_');

  return date === null ? placeholder : formatToMask(date, mask, timezone);
}
