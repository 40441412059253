import { useState } from 'react';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import { IconButton } from 'shared/mui/IconButtons';
import { Tooltip } from 'shared/mui/Tooltip';

import { useGetOrderCopyMainData } from './hooks/useGetOrderCopyMainData';

export const OrderShowCommonInfoMainCopyButton = (): JSX.Element => {
  const { copyData } = useGetOrderCopyMainData();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState('');

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(copyData);
      setTooltipText('Cкопировано');
      setTooltipOpen(true);
    } catch (e: unknown) {
      setTooltipText('Копирование не удалось');
      setTooltipOpen(true);
    }
  };

  return (
    <Tooltip disableHoverListener open={tooltipOpen} title={tooltipText}>
      <IconButton onClick={handleCopy} size="medium">
        <CopyAllIcon sx={{ fontSize: 'inherit' }} />
      </IconButton>
    </Tooltip>
  );
};
