import { EMPTY_FIELD } from 'constants/emptyField';

import { MerchantShowAccountsActivityField } from 'components/Merchants/MerchantsShow/MerchantShowAccounts/components/MerchantShowAccountsActivityField.tsx/MerchantShowAccountsActivityField';
import { usePermissions } from 'hooks/usePermissions';
import { RecordContextProvider } from 'react-admin';
import { CardHeader } from 'shared/mui/Card';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import {
  BooleanField,
  ChipField,
  DateTimeField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { MerchantAccount } from 'types';

type Props = {
  open: boolean;
  onClose: () => void;
  merchantAccount?: MerchantAccount;
};

export const MerchantShowAccountsShowDialog: React.FC<Props> = ({
  open,
  onClose,
  merchantAccount,
}) => {
  const { hasResourceAccess } = usePermissions();

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={merchantAccount}>
        <CardHeader
          actions={[
            hasResourceAccess('user_actions') && (
              <UserActionsIconButton entityName="merchant_account" key="user-action-link" />
            ),
            <CloseIconButton key="close-button" onClose={onClose} />,
          ]}
          title="Просмотр счета"
        />
        <CardTable sx={{ tableLayout: 'fixed' }}>
          <TableBody>
            <TableRowWrapper headerStyle={{ width: '140px' }} title="ID">
              <TextField source="originId" />
            </TableRowWrapper>
            <TableRowWrapper title="Активен">
              <BooleanField source="active" />
            </TableRowWrapper>
            <TableRowWrapper title="Название">
              <TextField source="name" />
            </TableRowWrapper>
            <TableRowWrapper headerStyle={{ padding: '4px 24px' }} title="Последняя активность">
              <MerchantShowAccountsActivityField
                label="Последняя активность"
                merchantId={merchantAccount?.merchants.at(-1)}
              />
            </TableRowWrapper>
            <TableRowWrapper title="Мерчант">
              <ReferenceArrayField label="Мерчант" reference="merchants" source="merchants">
                <SingleFieldList linkType={false}>
                  <TextField emptyText={EMPTY_FIELD} label="Мерчант" source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
            </TableRowWrapper>
            <TableRowWrapper title="Провайдер">
              <ReferenceOneField
                label="Провайдер"
                reference="providers"
                source="provider"
                target="id"
              >
                <TextField emptyText={EMPTY_FIELD} label="Провайдер" source="name" />
              </ReferenceOneField>
            </TableRowWrapper>
            <TableRowWrapper title="Использовать сквозной ID">
              <BooleanField source="merchantIdEnabled" />
            </TableRowWrapper>
            <TableRowWrapper title="Валюта">
              <ReferenceOneField
                label="Валюта"
                reference="currencies"
                source="currency"
                target="id"
              >
                <TextField emptyText={EMPTY_FIELD} label="Валюта" source="alphaCode" />
              </ReferenceOneField>
            </TableRowWrapper>

            <TableRowWrapper title="Шлюзы">
              <ReferenceArrayField label="Шлюзы" reference="gateways" source="gateways">
                <SingleFieldList linkType={false}>
                  <ChipField color="primary" source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
            </TableRowWrapper>
            <TableRowWrapper contentStyle={{ padding: '4px 16px' }} title="Дата создания">
              <DateTimeField source="createdAt" />
            </TableRowWrapper>
            <TableRowWrapper contentStyle={{ padding: '4px 16px' }} title="Дата редактир.">
              <DateTimeField source="updatedAt" />
            </TableRowWrapper>
          </TableBody>
        </CardTable>
      </RecordContextProvider>
    </Dialog>
  );
};
