import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { RaRecord } from 'react-admin';
import { Link } from 'shared/mui/Link/Link';
import { Stack } from 'shared/mui/Stack/Stack';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';

import { EMPTY_FIELD } from '../../../../constants';

export type ExternalLinkFieldProps = {
  label?: string | React.ReactElement;
  source: string;
} & Omit<FunctionFieldProps, 'render'>;

export const ExternalLinkField: React.FC<ExternalLinkFieldProps> = ({ label, source, ...rest }) => {
  return (
    <FunctionField
      label={label}
      render={(record: RaRecord) => {
        if (!record[source]) {
          return EMPTY_FIELD;
        }
        return (
          <Stack alignItems="center" direction="row" gap="8px">
            <Link
              href={record[source]}
              rel="noreferrer"
              sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              target="_blank"
            >
              {record[source]}
            </Link>
            <InsertLinkIcon color="secondary" />
          </Stack>
        );
      }}
      {...rest}
    />
  );
};
