import { secondsToMilliseconds } from 'date-fns';

import { convertReactAdminIdToHydraId } from '../../../utils';
import { OrderAdjustmentsReportListFilterFormValues } from '../OrderAdjustmentsReportListFilter/OrderAdjustmentsReportListFilter';

export function getDefaultFormValues(
  filterValues: Record<string, any>,
): OrderAdjustmentsReportListFilterFormValues {
  return {
    adjustmentType:
      (filterValues?.adjustmentType as string[])?.map((type) =>
        convertReactAdminIdToHydraId(type, 'adjustment_types'),
      ) || [],
    adjustmentStatus:
      (filterValues?.adjustmentStatus as string[])?.map((status) =>
        convertReactAdminIdToHydraId(status, 'adjustment_statuses'),
      ) || [],
    impactToBalance: filterValues?.impactToBalance || null,
    requestId: filterValues?.requestId || null,
    user:
      (filterValues?.user as string[])?.map((user) =>
        convertReactAdminIdToHydraId(user, 'users'),
      ) || [],
    appliedDate: [
      filterValues?.['createdAt[gte]']
        ? secondsToMilliseconds(Number(filterValues['createdAt[gte]']))
        : null,
      filterValues?.['createdAt[lte]']
        ? secondsToMilliseconds(Number(filterValues['createdAt[lte]']))
        : null,
    ],
  };
}
