import { styled } from '@mui/material';
import { JsonCopyToClipboardField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';

export const JsonCopyToClipboardFieldStyled = styled(JsonCopyToClipboardField)({
  background: '#fff !important',
  border: `1px dotted ${appColors.inputBorder}`,
  maxWidth: '707px !important',
  height: '151px',
  overflow: 'auto',
  marginBottom: '12px',
});
