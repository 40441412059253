import { Directions } from 'constants/directions';

import { usePermissions } from 'hooks/usePermissions';
import { RecordContextProvider, useShowController } from 'react-admin';
import { Table, TableBody } from 'shared/mui/Table';
import {
  BooleanField,
  DateTimeField,
  FunctionField,
  NumberField,
  OrderStatusChipField,
  TextField,
} from 'shared/react-admin/Fields';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { Currency, Order } from 'types';

import { OrderShowCommonInfoMainCopyButton } from './OrderShowCommonInfoMainCopyButton';
import { OrderShowCommonInfoTableRowWrapper } from '../components/OrderShowCommonInfoTableRowWrapper';
import { StyledCard, StyledCardHeader } from '../OrderShowCommonInfo.styled';

export const OrderShowCommonInfoMain = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();
  const { record: order } = useShowController<Order>();
  const isWithdrawal = order?.direction.endsWith(Directions.Payout);

  return (
    <RecordContextProvider value={order}>
      <StyledCard>
        <StyledCardHeader
          actions={<OrderShowCommonInfoMainCopyButton />}
          actionsProps={{ sx: { alignSelf: 'center' } }}
          title="Основная информация"
          titleSize="small"
        />
        <Table>
          <TableBody>
            <OrderShowCommonInfoTableRowWrapper title="ID Kubera:">
              <TextField source="originId" />
            </OrderShowCommonInfoTableRowWrapper>
            <OrderShowCommonInfoTableRowWrapper title="ID Мерчанта:">
              <TextField source="merchantReference" />
            </OrderShowCommonInfoTableRowWrapper>
            <OrderShowCommonInfoTableRowWrapper title="ID провайдера:">
              <TextField emptyText="Нет" source="providerReferenceId" />
            </OrderShowCommonInfoTableRowWrapper>
            <OrderShowCommonInfoTableRowWrapper title="Время создания:">
              <DateTimeField source="createdAt" />
            </OrderShowCommonInfoTableRowWrapper>
            <OrderShowCommonInfoTableRowWrapper title="Статус:">
              <ReferenceField link={false} reference="new/order_statuses" source="status">
                <OrderStatusChipField source="description" />
              </ReferenceField>
            </OrderShowCommonInfoTableRowWrapper>
            <OrderShowCommonInfoTableRowWrapper title="Сумма:">
              <NumberField
                options={{ minimumFractionDigits: 2 }}
                source="amount"
                sx={{
                  fontWeight: 700,
                }}
              />
            </OrderShowCommonInfoTableRowWrapper>
            {hasResourceAccess('currencies') && (
              <OrderShowCommonInfoTableRowWrapper title="Валюта:">
                <ReferenceOneField
                  link={false}
                  reference="currencies"
                  sortable={false}
                  source="currency"
                  target="id"
                >
                  <FunctionField
                    render={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
                  />
                </ReferenceOneField>
              </OrderShowCommonInfoTableRowWrapper>
            )}
            <OrderShowCommonInfoTableRowWrapper title="Направление:">
              <ReferenceField reference="directions" source="direction">
                <TextField source="description" />
              </ReferenceField>
            </OrderShowCommonInfoTableRowWrapper>
            {isWithdrawal && (
              <OrderShowCommonInfoTableRowWrapper title="Одобрен:">
                <BooleanField source="capture" />
              </OrderShowCommonInfoTableRowWrapper>
            )}
            {order?.orderExternalError && (
              <OrderShowCommonInfoTableRowWrapper title="Причина отказа:">
                <ReferenceField
                  link={false}
                  reference="orderExternalError"
                  source="orderExternalError"
                >
                  <TextField source="reason" />
                </ReferenceField>
              </OrderShowCommonInfoTableRowWrapper>
            )}
          </TableBody>
        </Table>
      </StyledCard>
    </RecordContextProvider>
  );
};
