import { ReactElement } from 'react';

import { FunctionField } from 'shared/react-admin/Fields';
import { OrderAdjustments } from 'types/orderAdjustments';

import { AdjustmentAmountFieldContent } from './AdjustmentAmountFieldContent';

export type AdjustmentAmountFieldProps = {
  label?: string | ReactElement | boolean;
  source?: string;
};

export const AdjustmentAmountField: React.FC<AdjustmentAmountFieldProps> = ({ label }) => {
  return (
    <FunctionField
      label={label}
      render={(record: OrderAdjustments) => {
        return <AdjustmentAmountFieldContent record={record} />;
      }}
    />
  );
};
