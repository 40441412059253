import MessageIcon from '@mui/icons-material/Message';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { useDatagridActionsContext } from 'shared/react-admin/Datagrids/DatagridActions/context/datagridActionsContext';
import { ListItemTextStyled } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActions.styled';
import { OrderAdjustments } from 'types/orderAdjustments';

export type DatagridActionsCommentButtonProps = {
  onClick: VoidFunction;
  record: OrderAdjustments;
};

export const DatagridActionsCommentButton: React.FC<DatagridActionsCommentButtonProps> = ({
  onClick,
  record,
}) => {
  const { onCloseMenu } = useDatagridActionsContext();
  const handleClick = () => {
    onClick();
    onCloseMenu();
  };

  return (
    <MenuItem disabled={!record?.comment} onClick={handleClick} sx={{ width: '100%' }}>
      <ListItemIcon>
        <MessageIcon color={record?.comment ? 'primary' : 'secondary'} />
      </ListItemIcon>
      <ListItemTextStyled
        primary={record?.comment ? 'Посмотреть комментарий' : 'Добавить комментарий'}
      />
    </MenuItem>
  );
};
