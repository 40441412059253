import { styled } from '@mui/material/styles';

import { Box } from '../../../mui/Box';

export const WrapperStyled = styled(Box)({
  display: 'flex',
  gap: '4px',
});

export const ContentStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

export const RowStyled = styled(Box)({
  display: 'flex',
  width: '100%',
});
