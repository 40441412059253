import { useState } from 'react';

import { RecordContextProvider, useShowController } from 'react-admin';
import { Collapse } from 'shared/mui/Collapse/Collapse';
import { ExpandIconButton } from 'shared/mui/IconButtons';
import { Table, TableBody } from 'shared/mui/Table';
import { ExternalLinkField } from 'shared/react-admin/Fields/ExternalLinkField/ExternalLinkField';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';
import { Order } from 'types';

import { OrderShowCommonInfoTableRowWrapper } from '../components/OrderShowCommonInfoTableRowWrapper';
import { StyledCard, StyledCardHeader } from '../OrderShowCommonInfo.styled';

export const OrderShowCommonInfoResources = (): JSX.Element => {
  const { record: order } = useShowController<Order>();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <RecordContextProvider value={order}>
      <StyledCard>
        <StyledCardHeader
          actions={
            <ExpandIconButton expanded={expanded} onClick={handleExpandClick} size="small" />
          }
          title="Ресурсы"
          titleSize="small"
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Table>
            <TableBody>
              <OrderShowCommonInfoTableRowWrapper title="URL возврата:">
                <ReferenceField link={false} reference="order_action_urls" source="orderActionUrls">
                  <ExternalLinkField source="returnUrl" />
                </ReferenceField>
              </OrderShowCommonInfoTableRowWrapper>
              <OrderShowCommonInfoTableRowWrapper title="URL в процессе:">
                <ReferenceField link={false} reference="order_action_urls" source="orderActionUrls">
                  <ExternalLinkField source="pendingUrl" />
                </ReferenceField>
              </OrderShowCommonInfoTableRowWrapper>
              <OrderShowCommonInfoTableRowWrapper title="URL успеха:">
                <ReferenceField link={false} reference="order_action_urls" source="orderActionUrls">
                  <ExternalLinkField source="successUrl" />
                </ReferenceField>
              </OrderShowCommonInfoTableRowWrapper>
              <OrderShowCommonInfoTableRowWrapper title="URL провала:">
                <ReferenceField link={false} reference="order_action_urls" source="orderActionUrls">
                  <ExternalLinkField source="failureUrl" />
                </ReferenceField>
              </OrderShowCommonInfoTableRowWrapper>
              <OrderShowCommonInfoTableRowWrapper title="URL провала:">
                <ReferenceField
                  link={false}
                  reference="order_notification_urls"
                  source="orderNotificationUrl"
                >
                  <ExternalLinkField source="notificationUrl" />
                </ReferenceField>
              </OrderShowCommonInfoTableRowWrapper>
            </TableBody>
          </Table>
        </Collapse>
      </StyledCard>
    </RecordContextProvider>
  );
};
