import { EMPTY_FIELD } from 'constants/emptyField';

import { useGetOne, useRecordContext } from 'react-admin';
import { Typography } from 'shared/mui/Typography';
import { formatCurrency } from 'utils';

type Props = {
  label?: string;
  source?: string;
};

export const OrdersReportListTotalAmountField: React.FC<Props> = () => {
  const record = useRecordContext();
  const { data: currency } = useGetOne(
    'currencies',
    { id: record?.currency },
    { enabled: !!record },
  );

  if (!record.totalAmount) {
    return EMPTY_FIELD;
  }

  return (
    <>
      <Typography component="span" variant="body2">
        {formatCurrency(record?.totalAmount)}
      </Typography>{' '}
      <Typography component="span" sx={{ fontWeight: 500 }} variant="body2">
        {currency?.alphaCode}
      </Typography>
    </>
  );
};
