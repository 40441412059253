import { useState } from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Chip } from 'shared/mui/Chips';
import { Permission } from 'types';

import { EditPayoutControlTestClientsExceptionsAddDialog } from './EditPayoutControlTestClientsExceptionsAddDialog/EditPayoutControlTestClientsExceptionsAddDialog';
import { EditPayoutControlTestClientsExceptionsCreateDialog } from './EditPayoutControlTestClientsExceptionsCreateDialog/EditPayoutControlTestClientsExceptionsCreateDialog';
import { EditPayoutControlTestClientsExceptionsDeleteDialog } from './EditPayoutControlTestClientsExceptionsDeleteDialog/EditPayoutControlTestClientsExceptionsDeleteDialog';
import { useEditPayoutControlContext } from '../context/EditPayoutControlContext';

export const EditPayoutControlTestClientsExceptions: React.FC = () => {
  const [isOpenAddExceptionDialog, setIsOpenAddExceptionDialog] = useState(false);
  const [isOpenCreateExceptionDialog, setIsOpenCreateExceptionDialog] = useState(false);
  const { hasResourcePermissions } = usePermissions();
  const { payoutControl } = useEditPayoutControlContext();

  const {
    record: testClient,
    handleCloseRecordModal: handleCloseTestClientDeleteModal,
    openRecordModal: openTestClientDeleteModal,
    handleOpenRecordModal: handleOpenTestClientDeleteModal,
  } = useRecordModal<{ id: string; customerId: string }>();

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        border: '1px solid #EBEBEB',
        borderRadius: '4px',
      }}
    >
      <CardHeader
        actions={[
          hasResourcePermissions('merchant_payout_controls', [
            Permission.Full,
            Permission.Patch,
          ]) && (
            <Button
              key="add-test-clients-exceptions"
              label="Добавить"
              onClick={() => setIsOpenAddExceptionDialog(true)}
              size="small"
              variant="outlined"
            />
          ),
        ]}
        description="Не будет распространяться контроль выводов. "
        sx={{ height: '79px' }}
        title="Тестовые клиенты исключения"
        titleSize="small"
      />
      {!!payoutControl?.customers?.length && (
        <CardContent sx={{ paddingTop: '0!important', display: 'flex', gap: 1 }}>
          {payoutControl.customers.map(({ customerId, id }) => (
            <Chip
              key={customerId}
              label={customerId}
              {...(hasResourcePermissions('merchant_payout_controls', [
                Permission.Full,
                Permission.Patch,
              ]) && { onDelete: () => handleOpenTestClientDeleteModal({ id, customerId }) })}
            />
          ))}
        </CardContent>
      )}
      {isOpenAddExceptionDialog && (
        <EditPayoutControlTestClientsExceptionsAddDialog
          onClose={() => setIsOpenAddExceptionDialog(false)}
          onCreate={() => {
            setIsOpenCreateExceptionDialog(true);
            setIsOpenAddExceptionDialog(false);
          }}
          open={isOpenAddExceptionDialog}
        />
      )}
      {isOpenCreateExceptionDialog && (
        <EditPayoutControlTestClientsExceptionsCreateDialog
          onClose={() => setIsOpenCreateExceptionDialog(false)}
          open={isOpenCreateExceptionDialog}
        />
      )}
      {openTestClientDeleteModal && (
        <EditPayoutControlTestClientsExceptionsDeleteDialog
          onClose={handleCloseTestClientDeleteModal}
          onSuccess={handleCloseTestClientDeleteModal}
          open={openTestClientDeleteModal}
          record={testClient}
        />
      )}
    </Box>
  );
};
