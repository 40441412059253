import { styled } from '@mui/material/styles';

import { Box, BoxProps } from '../../Box';

export type FilterRowProps = BoxProps;

export const FilterRow = styled(Box)<FilterRowProps>({
  alignItems: 'center',
  display: 'grid',
  gap: '0 16px ',
  gridTemplateColumns: '3fr 1fr',
});
