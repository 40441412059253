import { Fragment } from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { CreateButton } from 'shared/mui/Buttons';
import { ExportButton } from 'shared/react-admin/Buttons';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsEditButton,
  DatagridActionsShowButton,
  DatagridActionsUserActionsButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import { DateTimeField, ImageField, TextField, WrapperField } from 'shared/react-admin/Fields';
import { TooltipTextLinkField } from 'shared/react-admin/Fields/TooltipTextLinkField/TooltipTextLinkField';
import { ListPage } from 'shared/react-admin/Pages/ListPage/ListPage';
import { MethodLogo, Permission } from 'types';

import { StyledDatagrid } from './MethodLogosList.styled';
import { MethodLogosDeleteDialog } from '../components/MethodLogosDeleteDialog/MethodLogosDeleteDialog';
import { MethodLogosFilters } from '../MethodLogosFilters';

export const MethodLogosList: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record, handleOpenRecordModal, openRecordModal, handleCloseRecordModal } =
    useRecordModal<MethodLogo>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('method_logos', [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal={true} key="create_method_logos" />
        ),
        <ExportButton key="export_method_logos" />,
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Логотипы методов',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <MethodLogosFilters />
      <StyledDatagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        <TooltipTextLinkField label="Название" resource="method_logos" source="name" type="show" />
        <WrapperField>
          <Fragment />
        </WrapperField>
        <ImageField
          label="Логотип"
          sortable={false}
          source="filePath"
          sx={{
            '& .RaImageField-image': {
              maxWidth: 60,
              maxHeight: 60,
              minWidth: 60,
              minHeight: 60,
              objectFit: 'contain',
            },
          }}
        />

        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <DatagridActions
          render={(record: MethodLogo) => (
            <>
              {hasResourceAccess('user_actions') && (
                <DatagridActionsUserActionsButton entityName="method_logo" />
              )}
              <DatagridActionsShowButton />
              {hasResourcePermissions('method_logos', [Permission.Full, Permission.Update]) && (
                <DatagridActionsEditButton />
              )}
              {hasResourcePermissions('method_logos', [Permission.Full, Permission.Delete]) && (
                <DatagridActionsDeleteButton onClick={() => handleOpenRecordModal(record)} />
              )}
            </>
          )}
        />
      </StyledDatagrid>
      {openRecordModal && (
        <MethodLogosDeleteDialog
          onClose={handleCloseRecordModal}
          open={openRecordModal}
          record={record}
        />
      )}
    </ListPage>
  );
};
