import { usePermissions } from 'hooks/usePermissions';
import { Stack } from 'shared/mui/Stack/Stack';
import { Tooltip } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { MerchantAccount, Permission } from 'types';

import { MerchantAccountsNameFieldActiveSwitch } from './MerchantAccountsNameFieldActiveSwitch';
import { TypographyStyled } from './MerchantShowAccountsNameField.styled';

type Props = {
  label: string;
  onClick: (record: MerchantAccount) => void;
} & Omit<FunctionFieldProps, 'render' | 'onClick'>;

export const MerchantShowAccountsNameField: React.FC<Props> = ({ label, onClick, ...rest }) => {
  const { hasResourcePermissions } = usePermissions();

  return (
    <FunctionField
      label={label}
      render={(record: MerchantAccount) => {
        return (
          <Stack alignItems="start" direction="row">
            {hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Update]) && (
              <MerchantAccountsNameFieldActiveSwitch />
            )}
            <Tooltip title={<Typography fontSize={12}>{record.name}</Typography>}>
              <TypographyStyled onClick={() => onClick(record)} variant="body2">
                {record.name}
              </TypographyStyled>
            </Tooltip>
          </Stack>
        );
      }}
      {...rest}
    />
  );
};
