import CircleIcon from '@mui/icons-material/Circle';
import DownloadIcon from '@mui/icons-material/Download';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ReportIcon from '@mui/icons-material/Report';
import { useNotify } from 'react-admin';

import {
  FailureIconButtonStyled,
  SuccessIconButtonStyled,
} from './NotificationPopperListItemReport.styled';
import { NotificationPopperListItemReportPrimary } from './NotificationPopperListItemReportPrimary';
import { NotificationPopperListItemReportSecondary } from './NotificationPopperListItemReportSecondary';
import { NotificationStatuses } from '../../../../../../constants/notifications';
import { appColors } from '../../../../../../themes/variables';
import { Notification, NotificationBodyContent } from '../../../../../../types';
import { Box } from '../../../../../mui/Box';
import { ListItemAvatar, ListItemText } from '../../../../../mui/List';
import { useChangeViewedStatus } from '../../../hooks/useChangeViewedStatus';
import { NotificationPopperListItem } from '../NotificationPopperListItem';

type Props = {
  notification: Notification;
  onSuccess: () => void;
};

export const NotificationPopperListItemReport: React.FC<Props> = ({ notification, onSuccess }) => {
  const { body: notificationBody } = notification;
  const { status, content } = notificationBody;
  const isSuccess = status === NotificationStatuses.Done;
  const isFailure = status === NotificationStatuses.Failure;

  const notify = useNotify();

  const { changeViewedStatus } = useChangeViewedStatus({
    ids: [notification.id],
    onSuccess,
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  return (
    <NotificationPopperListItem>
      <ListItemAvatar>
        <>
          {isSuccess && (
            <SuccessIconButtonStyled onClick={changeViewedStatus}>
              <DownloadIcon htmlColor={appColors.background} />
            </SuccessIconButtonStyled>
          )}
          {isFailure && (
            <FailureIconButtonStyled>
              <ReportIcon color="error" />
            </FailureIconButtonStyled>
          )}
        </>
      </ListItemAvatar>
      <ListItemText
        primary={
          <NotificationPopperListItemReportPrimary
            exportType={(content as NotificationBodyContent).exportType}
            periodFrom={(content as NotificationBodyContent).reportPeriod.from}
            periodTo={(content as NotificationBodyContent).reportPeriod.to}
          />
        }
        primaryTypographyProps={{ component: 'div', sx: { marginBottom: '8px' } }}
        secondary={
          <NotificationPopperListItemReportSecondary
            createdAt={(content as NotificationBodyContent).createdAt}
            fileExtension={(content as NotificationBodyContent).fileExtension}
            isFailure={isFailure}
            isSuccess={isSuccess}
            status={status}
          />
        }
        secondaryTypographyProps={{ component: 'div' }}
        sx={{ margin: 0 }}
      />
      <Box sx={{ marginTop: '2px' }}>
        {notification.isViewed ? (
          <RadioButtonUncheckedIcon htmlColor={appColors.primary.light} sx={{ fontSize: '8px' }} />
        ) : (
          <CircleIcon color="primary" sx={{ fontSize: '8px' }} />
        )}
      </Box>
    </NotificationPopperListItem>
  );
};
