import { OrderAdjustmentsTypes } from 'constants/orderAdjustmentTypes';

import { useEffect } from 'react';

import { useListContext } from 'react-admin';
import { useForm, UseFormReturn } from 'react-hook-form';
import { Order } from 'types';

export type OrderAdjustment = {
  orderId: string;
  newAmount: number | null;
  comment: string | null;
};
export type OrderAdjustmentsFormValues = {
  adjustmentType: OrderAdjustmentsTypes | null;
  impactToBalance: boolean | null;
  orderAdjustments: OrderAdjustment[];
};

type UseOrderAdjustmentsFormReturnValues = {
  orderAdjustmentsForm: UseFormReturn<OrderAdjustmentsFormValues>;
  resetOrderAdjustmentsForm: VoidFunction;
};

const DEAFAULT_VALUES = {
  adjustmentType: null,
  impactToBalance: null,
  orderAdjustments: [],
};

export function useOrderAdjustmentsForm(): UseOrderAdjustmentsFormReturnValues {
  const { data: orders, filterValues } = useListContext<Order>();

  const orderAdjustmentsForm = useForm<OrderAdjustmentsFormValues>({
    defaultValues: DEAFAULT_VALUES,
  });

  useEffect(() => {
    if (orders?.length) {
      const currentAdjustments = orderAdjustmentsForm.getValues('orderAdjustments');
      const updatedAdjustments = orders.map((order) => {
        const existingAdjustment = currentAdjustments.find(
          (adjustment) => adjustment.orderId === order.id,
        );
        return existingAdjustment || { orderId: order.id, comment: null, newAmount: null };
      });

      orderAdjustmentsForm.setValue('orderAdjustments', updatedAdjustments);
    }
  }, [orders, filterValues]);

  const resetOrderAdjustmentsForm = () => {
    orderAdjustmentsForm.reset(DEAFAULT_VALUES);
  };

  return {
    orderAdjustmentsForm,
    resetOrderAdjustmentsForm,
  };
}
