import { OrderStatuses } from 'constants/orderStatuses';

import { ChipFieldProps, useRecordContext } from 'react-admin';
import { StatusIcon } from 'shared/mui/Icons';

import { ConfirmationChipField, OrderStatusChipFieldStyled } from './OrderStatusChipField.styled';

export type OrderStatusChipFieldProps = ChipFieldProps;

export const OrderStatusChipField = (props: OrderStatusChipFieldProps): JSX.Element => {
  const record = useRecordContext();

  if (record?.value === OrderStatuses.WaitingConfirmation) {
    return <ConfirmationChipField {...props} />;
  }

  return (
    <OrderStatusChipFieldStyled icon={<StatusIcon color={record?.value} size="m" />} {...props} />
  );
};
