import {
  EXPORT_FORMAT_NAMES,
  ExportJobTypes,
  ExportTransportTypes,
  ReportNames,
} from 'constants/exportFormats';

import { useState } from 'react';

import { getUserInfo } from 'config/requests';
import { useReportExport } from 'hooks/queries/useReportExport';
import { FilterPayload, useNotify } from 'react-admin';
import { useQuery } from 'react-query';
import { NotifyDialog } from 'shared/mui/Dialogs';
import { Typography } from 'shared/mui/Typography';
import { ExportEditButton } from 'shared/react-admin/Export/ExportEditButton/ExportEditButton';
import { ReportExportDialogMessage } from 'shared/widgets/ReportExport/ReportExportDialogMessage';
import { ExportFormat } from 'types';

import { EXPORT_FIELDS_ADJUSTMENTS_REPORT } from '../constants/exportFields';

type Props = {
  exportFormat: ExportFormat;
  closeNotifyDialog: VoidFunction;
  open: boolean;
  isLoadingData: boolean;
  listFilters: FilterPayload;
};

export const OrderAdjustmentsReportNotifyDialog: React.FC<Props> = ({
  exportFormat,
  closeNotifyDialog,
  listFilters,
  open,
  isLoadingData,
}) => {
  const notify = useNotify();
  const [isSendReportOnEmail, setIsSendReportOnEmail] = useState(false);
  const { data: user } = useQuery('me', getUserInfo);

  const { mutate: exportMutate, isLoading: isExportLoading } = useReportExport({
    filters: {
      ...listFilters,
      exportType: exportFormat,
      jobType: ExportJobTypes.Adjustments,
      ...(isSendReportOnEmail && { notificationTransport: ExportTransportTypes.Email }),
    },
    preferenceKey: ReportNames.Order_adjustment_report,
    onSuccess: () => {
      notify(
        `Файл экспорта отчета по корректировкам заказов формируется${isSendReportOnEmail ? ' и будет отправлен вам на почту' : ''}`,
        {
          type: 'info',
        },
      );
      closeNotifyDialog();
    },
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  return (
    <NotifyDialog
      isLoading={isLoadingData || isExportLoading}
      message={
        <ReportExportDialogMessage
          exportFormat={EXPORT_FORMAT_NAMES[exportFormat]}
          isSendReportOnEmail={isSendReportOnEmail}
          onChange={() => setIsSendReportOnEmail((prev) => !prev)}
          userEmail={user?.corporateEmail || user?.email || 'почта неизвестна'}
        />
      }
      onClose={closeNotifyDialog}
      onSubmit={exportMutate}
      open={open}
      title={
        <>
          <Typography component="span" sx={{ verticalAlign: 'middle' }} variant="inherit">
            Экспорт отчета
          </Typography>
          <ExportEditButton
            fields={EXPORT_FIELDS_ADJUSTMENTS_REPORT}
            preferenceKey={ReportNames.Order_adjustment_report}
          />
        </>
      }
    />
  );
};
