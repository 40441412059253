import { useEffect, useMemo } from 'react';

import { useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { SelectColumnButtonFilter } from 'shared/react-admin/Table/SelectColumnButtonFilter/SelectColumnButtonFilter';
import { cleanEmpty } from 'utils/cleanEmpty';

import { MerchantShowAccountsFiltersChips } from './MerchantShowAccountsFiltersChips';
import { MerchantShowAccountsFiltersTable } from './MerchantShowAccountsFiltersTable';

export type MerchantsShowAccountsFilterValues = {
  name: string;
  provider: string;
  currency: string;
  gateways: string;
};

const INITIAL_FILTER_VALUES = {
  name: '',
  provider: '',
  currency: '',
  gateways: '',
};

export const MerchantShowAccountsFilters = () => {
  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<MerchantsShowAccountsFilterValues>({ defaultValues: INITIAL_FILTER_VALUES });

  const { setFilters, displayedFilters } = useListContext();

  useEffect(() => {
    setFilters(appliedFilters, displayedFilters);
  }, [appliedFilters]);

  const isListFilterExist = useMemo(
    () => Object.keys(cleanEmpty(appliedFilters)).length > 0,
    [appliedFilters],
  );

  return (
    <>
      <FilterToolbar
        leftActionsSlot={[
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          <SelectColumnButtonFilter key="select-column" />,
          <FilterClearButton
            key="clear"
            onClick={() => {
              onReset();
            }}
            visible={isListFilterExist}
          />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent>
              <MerchantShowAccountsFiltersTable />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters}>
        <FilterContextProvider appliedFilters={appliedFilters} setAppliedFilter={setAppliedFilter}>
          <FormProvider {...form}>
            <MerchantShowAccountsFiltersChips />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
    </>
  );
};
