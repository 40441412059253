import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { ChipField, TextField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';

import { DatagridConfigurableWithStickyActions } from '../../../../shared/react-admin/Datagrids';
import { getColumnStyle } from '../../../../utils';

export const StyledDatagrid = styled(DatagridConfigurableWithStickyActions)({
  '& .MuiTableCell-head': {
    backgroundColor: appColors.tableHead,
  },
  '& .column-name': {
    width: '25%',
    maxWidth: '240px',
    minWidth: '240px',
  },
  '& .column-merchants': {
    width: '25%',
    maxWidth: '200px',
    minWidth: '200px',
  },
  '& .column-provider': {
    width: '25%',
    maxWidth: '200px',
    minWidth: '200px',
  },
  '& .column-balanceUpdatedAtMessage': getColumnStyle(200),
  '& .column-credentials': getColumnStyle(160),
  '& .column-merchantCredentials': getColumnStyle(200),
  '& .column-currency': getColumnStyle(120),
  '& .column-merchantIdEnabled': getColumnStyle(120),
  '& .column-gateways': {
    width: '25%',
    maxWidth: '200px',
    minWidth: '200px',
    a: {
      whiteSpace: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});

export const StyledChipFieldLink = styled(ChipField)({
  '& .MuiChip-label': {
    color: appColors.primary.main,
  },
});

export const TextFieldStyled = styled(TextField)({
  cursor: 'pointer',
  color: blue[700],
  textDecoration: 'underline',
});
