import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { Divider } from 'shared/mui/Divider/Divider';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

import {
  AlertStyled,
  LiStyled,
  OlStyled,
  OlStyledBold,
  UlStyled,
} from './MerchantsShowNotificationsReferenceDialog.styled';

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export const MerchantsShowNotificationsReferenceDialog: React.FC<Props> = ({ open, onClose }) => (
  <Dialog onClose={onClose} open={open} transitionDuration={0}>
    <Box sx={{ width: 600 }}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        sx={{ height: 'auto' }}
        title="Справка по настройке уведомлений в системе"
      />
      <CardContent
        sx={{ paddingTop: '0!important', overflow: 'auto', maxHeight: 'calc(100vh - 160px)' }}
      >
        <Typography sx={{ marginBottom: '8px' }} variant="body2">
          Система позволяет создавать и настраивать уведомления для мерчанта. Для настройки
          уведомлений выполните следующие шаги:
        </Typography>
        <OlStyledBold>
          <LiStyled>Откройте форму &quot;Создание и настройка уведомлений&quot;.</LiStyled>
          <LiStyled>Выберите тип уведомления:</LiStyled>
          <UlStyled>
            <li>Канал корпоративного мессенджера.</li>
            <li>Адрес электронной почты.</li>
          </UlStyled>
          <LiStyled>Определите события, по которым будут отправляться уведомления.</LiStyled>
          <AlertStyled severity="info">
            <Typography variant="body2">
              Перечень доступных событий может обновляться, добавляя новые варианты для настройки.
            </Typography>
          </AlertStyled>
          <LiStyled>Сохраните изменения.</LiStyled>
        </OlStyledBold>
        <Divider
          orientation="horizontal"
          sx={{ marginTop: 2, marginBottom: 2, borderColor: appColors.divider }}
        />
        <Typography sx={{ fontWeight: 500, marginBottom: '8px' }} variant="body1">
          Проверка работоспособности уведомлений:
        </Typography>
        <OlStyled>
          <li>Перейдите в форму просмотра или чтения уведомлений.</li>
          <li>
            Выберите функцию{' '}
            <Typography component="span" sx={{ fontWeight: 500 }} variant="body2">
              &quot;Отправить сообщение&quot;
            </Typography>
            .
          </li>
          <li>Убедитесь, что уведомление поступило на указанный канал.</li>
        </OlStyled>
        <Typography variant="body2">Если уведомление не пришло:</Typography>
        <UlStyled sx={{ marginLeft: '18px' }}>
          <li>Проверьте корректность настроек и при необходимости измените их.</li>
          <li>Если проблема не устраняется, обратитесь к администратору системы.</li>
        </UlStyled>
        <Typography variant="body2">
          Этот процесс помогает обеспечить оперативное информирование мерчанта по важным событиям.
        </Typography>
        <Button
          label="Вернуться"
          onClick={onClose}
          sx={{ margin: '24px 440px 8px' }}
          variant="outlined"
        />
      </CardContent>
    </Box>
  </Dialog>
);
