import { NUMBER_BOOLEAN_CHOICES } from 'constants/numberBooleanChoices';

import { Controller, useFormContext } from 'react-hook-form';
import { DateRangePickerValue } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { DateTimeRangePicker } from 'shared/mui/DatePicker/DateTimeRangePicker/DateTimeRangePicker';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

export type UsersListFilterFormValues = {
  appliedDate: [DateRangePickerValue, DateRangePickerValue];
  lastName: string;
  firstName: string;
  roleEntities: string[];
  email: string;
  twoFactor: string | null;
  enabled: string | null;
  reddyNumber: string;
};

export const UsersListFilter: React.FC = () => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name="appliedDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <DateTimeRangePicker
            label="Последняя активность / период"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <TextInputShrink
        helperText={false}
        key="lastName"
        label="Фамилия"
        name="lastName"
        source="lastName"
        variant="outlined"
      />
      <TextInputShrink
        helperText={false}
        key="firstName"
        label="Имя"
        name="firstName"
        source="firstName"
        variant="outlined"
      />
      <ReferenceArrayInput
        isFilter={true}
        name="roleEntities"
        perPage={100}
        reference="roles"
        source="roleEntities"
      >
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Роль"
          limitTags={1}
          name="roleEntities"
          optionText="name"
          size="small"
        />
      </ReferenceArrayInput>
      <AutocompleteInputShrink
        choices={NUMBER_BOOLEAN_CHOICES}
        fullWidth
        helperText={false}
        key="enabled"
        label="Активность"
        name="enabled"
        optionText="label"
        source="enabled"
        variant="outlined"
      />
      <TextInputShrink
        helperText={false}
        key="reddyNumber"
        label="Reddy"
        name="reddyNumber"
        source="reddyNumber"
        variant="outlined"
      />
      <TextInputShrink
        helperText={false}
        key="email"
        label="E-mail"
        name="email"
        source="email"
        variant="outlined"
      />
      <AutocompleteInputShrink
        choices={NUMBER_BOOLEAN_CHOICES}
        fullWidth
        helperText={false}
        key="twoFactor"
        label="2FA"
        name="twoFactor"
        optionText="label"
        source="twoFactor"
        variant="outlined"
      />
    </>
  );
};
