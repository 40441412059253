import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { OrderLog } from 'types';

type Props = Omit<FunctionFieldProps, 'render'>;

export const OrderShowLogsIdField: React.FC<Props> = (props) => {
  return (
    <FunctionField
      render={(record: OrderLog) => (
        <Stack alignItems="center" direction="row" gap="8px">
          <Typography color="secondary" sx={{ lineHeight: 1 }} variant="body2">
            ID события:
          </Typography>
          <Typography>{record?.id}</Typography>
        </Stack>
      )}
      sortable={false}
      {...props}
    />
  );
};
