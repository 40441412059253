import React from 'react';

import { RecordContextProvider } from 'react-admin';
import { CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { JsonCopyToClipboardField } from 'shared/react-admin/Fields';
import { MerchantAccount } from 'types';

type Props = {
  open: boolean;
  onClose: () => void;
  actions?: React.ReactNode;
  record?: MerchantAccount;
};

export const MerchantShowAccountsCredentialsDialog: React.FC<Props> = ({
  open,
  onClose,
  record,
  actions,
}) => {
  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <CardHeader
        actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
        title="Доступ к счету {json}"
        titleSize="small"
      />
      <RecordContextProvider value={record || {}}>
        <CardContent sx={{ minWidth: '552px' }}>
          <JsonCopyToClipboardField label="Доступ к счету" source="credentials" />
        </CardContent>
      </RecordContextProvider>
      {actions}
    </Dialog>
  );
};
