import { CumulativeReportFilterValuesNew } from 'components/Finance/CumulativeReport/CumulativeReportFilterNew';
import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';

import { StyledCumulativeReportPeriod } from './CumulativeReportPeriod.styled';

type CumulativeReportPeriodProps = {
  filters: CumulativeReportFilterValuesNew;
};

export const CumulativeReportPeriod: React.FC<CumulativeReportPeriodProps> = ({ filters }) => {
  const alertText = `Нарастающий итог по всем счетам за период ${formatInTimeZone(
    fromUnixTime(filters?.periodFrom as number),
    filters?.timezone || DEFAULT_TIMEZONE,
    'dd.MM.yyyy',
  )} — ${formatInTimeZone(
    fromUnixTime(filters?.periodTo as number),
    filters?.timezone || DEFAULT_TIMEZONE,
    'dd.MM.yyyy',
  )}`;

  return <StyledCumulativeReportPeriod>{alertText}</StyledCumulativeReportPeriod>;
};
