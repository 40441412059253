import { useEffect } from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { Box } from 'shared/mui/Box';
import {
  AutocompleteArrayInputShrink,
  AutocompleteInputShrink,
  BooleanInput,
} from 'shared/react-admin/Inputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency, Permission } from 'types';

import { CardContentStyled, JsonInputStyled } from './MerchantShowAccountsForm.styled';
import { MerchantShowAccountsMerchantCredentialsField } from '../MerchantShowAccountsMerchantCredentialsField/MerchantShowAccountsMerchantCredentialsField';
import { MerchantShowAccountsNameInput } from '../MerchantShowAccountsNameInput/MerchantShowAccountsNameInput';

type Props = {
  merchantId?: string;
  errors?: string[];
};

export const MerchantShowAccountsForm: React.FC<Props> = ({ errors, merchantId }) => {
  const { hasResourcePermissions } = usePermissions();
  const { setValue, watch, setError } = useFormContext();
  const formValues = watch();

  useEffect(() => {
    errors?.forEach((error: string) => {
      const [field, message] = error.split(':');
      setError(field, { type: 'custom', message });
    });
  }, [errors]);

  useEffect(() => {
    if (!formValues.provider) {
      setValue('gateways', []);
    }
  }, [formValues.provider]);

  return (
    <>
      {hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Update]) && (
        <Box sx={{ paddingLeft: '28px', paddingBottom: '12px', width: 'fit-content' }}>
          <BooleanInput label="Активен" source="active" />
        </Box>
      )}
      <Box sx={{ display: 'flex', width: '100%' }}>
        <CardContentStyled sx={{ width: '396px' }}>
          <Alert severity="info" sx={{ marginBottom: '12px' }}>
            Вы можете сгенерировать название счета, после заполнения полей: провайдер и валюта
          </Alert>
          <Alert severity="info" sx={{ marginBottom: '16px' }}>
            При включенном признаке «Использовать сквозной ИД» заказы, создаваемые на стороне
            провайдера, будут создаваться с использованием идентификатора мерчанта
          </Alert>
          <MerchantShowAccountsNameInput
            label="Название"
            merchantId={merchantId}
            size="medium"
            source="name"
            validate={required()}
            variant="outlined"
          />
          <ReferenceInput perPage={200} reference="providers" source="provider">
            <AutocompleteInputShrink
              filterToQuery={(searchText: string) => ({ name: searchText })}
              fullWidth
              label="Провайдер"
              optionText="name"
              size="medium"
              source="provider"
              sx={{ marginTop: '0px' }}
              validate={required()}
              variant="outlined"
            />
          </ReferenceInput>
          {formValues?.provider && (
            <ReferenceInput
              perPage={200}
              reference="currencies"
              sort={{ field: 'id', order: 'ASC' }}
              source="currency"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ currency: searchText })}
                fullWidth
                isRequired
                label="Валюта"
                optionText={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
                size="medium"
                validate={required()}
                variant="outlined"
              />
            </ReferenceInput>
          )}
          {formValues?.provider && (
            <ReferenceArrayInput
              filter={{ provider: formValues?.provider }}
              perPage={200}
              reference="gateways"
              source="gateways"
            >
              <AutocompleteArrayInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                label="Шлюзы"
                optionText="name"
                size="medium"
                source="gateways"
                validate={required()}
                variant="outlined"
              />
            </ReferenceArrayInput>
          )}
          <BooleanInput label="Использовать сквозной ID" source="merchantIdEnabled" />
        </CardContentStyled>
        <CardContentStyled sx={{ width: '707px' }}>
          <ReferenceInput
            perPage={200}
            reference="merchant_credentials"
            source="merchantCredentials"
          >
            <AutocompleteInputShrink
              filterToQuery={(searchText: string) => ({ name: searchText })}
              helperText={false}
              label="Реквизиты доступа к мерчанту"
              optionText="name"
              size="medium"
              sx={{
                marginBottom: '16px',
                '.MuiFormControl-root': {
                  marginTop: 0,
                },
              }}
              variant="outlined"
            />
          </ReferenceInput>
          <MerchantShowAccountsMerchantCredentialsField />
          <JsonInputStyled label="Редактор доступа к счету {json}" source="credentials" />
        </CardContentStyled>
      </Box>
    </>
  );
};
