import { Delete } from '@mui/icons-material';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';

import { useDatagridActionsContext } from '../context/datagridActionsContext';
import { ListItemTextStyled } from '../DatagridActions.styled';

export type DatagridActionsDeleteButtonProps = {
  onClick: VoidFunction;
};

export const DatagridActionsDeleteButton: React.FC<DatagridActionsDeleteButtonProps> = ({
  onClick,
}) => {
  const { onCloseMenu } = useDatagridActionsContext();
  const handleClick = () => {
    onClick();
    onCloseMenu();
  };

  return (
    <MenuItem onClick={handleClick} sx={{ width: '100%' }}>
      <ListItemIcon>
        <Delete fontSize="medium" />
      </ListItemIcon>
      <ListItemTextStyled primary="Удалить" />
    </MenuItem>
  );
};
