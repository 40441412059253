import { useState, MouseEvent, useCallback } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useShowController } from 'react-admin';
import { Link } from 'react-router-dom';
import { Box } from 'shared/mui/Box';
import { IconButton } from 'shared/mui/IconButtons';
import { ListItemIcon, ListItemText } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { MenuStyled } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActions.styled';
import { Order } from 'types';
import { createFilterQueryString } from 'utils/createFilterQueryString';

export type Props = {
  source?: string;
  children?: React.ReactNode;
};

export const OrderShowCommonInfoStateCardHeaderActions = (): JSX.Element => {
  const { record: order } = useShowController<Order>();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon color="primary" />
      </IconButton>
      <MenuStyled
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={onCloseMenu}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Link
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
          to={{
            pathname: '/order_adjustments',
            search: createFilterQueryString({
              id: [order?.originId],
            }),
          }}
        >
          <MenuItem onClick={onCloseMenu}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Корректировка заказа</ListItemText>
          </MenuItem>
        </Link>
      </MenuStyled>
    </Box>
  );
};
