import { useState } from 'react';

import { DataObject } from '@mui/icons-material';
import { UserActionsFilters } from 'components/UserActions/UserActionsFilters';
import { useFilters } from 'hooks/useFilters';
import { useListController } from 'react-admin';
import { Link } from 'react-router-dom';
import { IconButton } from 'shared/mui/IconButtons';
import { Link as MuiLink } from 'shared/mui/Link/Link';
import { ExportButton } from 'shared/react-admin/Buttons';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { FunctionField, TextField } from 'shared/react-admin/Fields';
import { DateTimeField } from 'shared/react-admin/Fields/DateTimeField/DateTimeField';
import { ListPage } from 'shared/react-admin/Pages';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { appColors } from 'themes/variables';
import { UserAction } from 'types/userAction';
import { getDateRangeDefaultValues } from 'utils';

import { UserActionJsonSchema } from './components/UserActionJsonSchema/UserActionJsonSchema';
import { UserActionsTypeChipField } from './components/UserActionTypeChipField/UserActionTypeChipField';
import { UserActionUserField } from './components/UserActionUserField/UserActionUserField';

export type UserActionsFiltersType = {
  user?: string[];
  entityId?: string;
  entityName?: string[];
  roleEntities?: string[];
  eventType?: string[];
  createdAt: {
    gte?: Date;
    lte?: Date;
  };
};

export const filtersResetValues = {
  user: [],
  entityId: '',
  entityName: [],
  roleEntities: [],
  createdAt: {
    gte: undefined,
    lte: undefined,
  },
};

export const UserActionsList: React.FC = () => {
  const list = useListController();
  const [currentRecord, setCurrentRecord] = useState<UserAction>();
  const [open, setOpen] = useState(false);

  const { form } = useFilters<UserActionsFiltersType>(filtersResetValues, {
    ...list.filterValues,
    createdAt: getDateRangeDefaultValues(
      list.filterValues?.createdAt || {
        gte: '',
        lte: '',
      },
    ),
  });

  const setEntityIdFilters = (record: UserAction) => {
    list.setFilters(
      {
        entityId: record?.entityId,
        entityName: record?.entityName,
      },
      list.displayedFilters,
    );
    form.reset();
    form.setValue('entityId', record?.entityId, { shouldDirty: true });
    form.setValue('entityName', [record?.entityName], { shouldDirty: true });
  };

  return (
    <ListPage
      actions={[<ExportButton key="export_user_actions" />]}
      empty={false}
      headerTitle={{
        titleText: 'Пользовательские действия',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <UserActionJsonSchema
        onClose={() => {
          setOpen(false);
          setCurrentRecord(undefined);
        }}
        open={open}
        record={currentRecord}
      />
      <UserActionsFilters form={form} resetValues={filtersResetValues} />
      <Datagrid
        bulkActionButtons={false}
        sx={{
          '.MuiTableCell-head': { zIndex: 9 },
          '.MuiTableCell-root': { verticalAlign: 'top' },
        }}
      >
        <FunctionField
          label="ID"
          render={(record: UserAction) => (
            <Link
              style={{ textDecoration: 'none', color: appColors.primary.main }}
              to={{
                pathname: `/user_actions/${encodeURIComponent(record.id)}/show`,
              }}
            >
              {record?.originId}
            </Link>
          )}
          sortBy="id"
        />
        <DateTimeField label="Дата редактир." sortable={false} source="createdAt" />
        <TextField label="Сущность" sortable={false} source="translatedEntityName" />
        <FunctionField
          label="ID сущности"
          render={(record: UserAction) => {
            return (
              <MuiLink
                onClick={() => setEntityIdFilters(record)}
                sx={{ cursor: 'pointer' }}
                underline="none"
              >
                {record?.entityId}
              </MuiLink>
            );
          }}
          sortable={false}
        />
        <ReferenceField
          label="Пользователь"
          link={false}
          reference="users"
          sortable={false}
          source="user"
        >
          <UserActionUserField />
        </ReferenceField>
        <TextField label="IP адрес" sortable={false} source="ip" />
        <ReferenceField
          label="Тип события"
          reference="user_event_types"
          sortable={false}
          source="eventType"
        >
          <UserActionsTypeChipField source="description" />
        </ReferenceField>
        <ActionsSlots
          render={(record: UserAction) => (
            <IconButton
              onClick={() => {
                setOpen(true);
                setCurrentRecord(record);
              }}
            >
              <DataObject />
            </IconButton>
          )}
        />
      </Datagrid>
    </ListPage>
  );
};
