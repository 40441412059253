import { styled } from '@mui/material/styles';
import { TextInput as AdminTextInput, TextInputProps as AdminTextInputProps } from 'react-admin';

import { fieldWidth } from '../../../../../themes/variables';

export type TextInputProps = AdminTextInputProps & { readOnly?: boolean };

export const TextInput = styled(AdminTextInput)({
  '&.MuiFormControl-root': {
    marginTop: 0,
  },
  '& .MuiFormHelperText-root': {
    marginTop: 0,
  },
  ...fieldWidth.medium,
});
