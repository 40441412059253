import { useState } from 'react';

import { ReferenceManyField, WithListContext } from 'react-admin';
import { Collapse } from 'shared/mui/Collapse/Collapse';
import { ExpandIconButton } from 'shared/mui/IconButtons';
import { DateTimeField, OrderStatusChipField, TextField } from 'shared/react-admin/Fields';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';

import { StyledDatagrid } from './OrderShowCommonInfoStatuses.styled';
import { OrderShowCommonInfoNoRecordsStub } from '../components/OrderShowCommonInfoNoRecordsStub/OrderShowCommonInfoNoRecordsStub';
import { StyledCard, StyledCardHeader } from '../OrderShowCommonInfo.styled';

export const OrderShowCommonInfoStatuses = (): JSX.Element => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledCard>
      <StyledCardHeader
        actions={<ExpandIconButton expanded={expanded} onClick={handleExpandClick} size="small" />}
        title="История статусов:"
        titleSize="small"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ReferenceManyField label={false} reference="order_status_histories" target="order">
          <WithListContext
            render={({ data, isLoading }) => {
              if (!data?.length && !isLoading) {
                return <OrderShowCommonInfoNoRecordsStub label="Нет статусов" />;
              }
              return (
                <StyledDatagrid bulkActionButtons={false}>
                  <TextField label="ID статуса" source="originId" />
                  <ReferenceField
                    label="Статус заказа"
                    reference="new/order_statuses"
                    source="status"
                  >
                    <OrderStatusChipField source="description" />
                  </ReferenceField>
                  <DateTimeField label="Время изменения" source="createdAt" />
                </StyledDatagrid>
              );
            }}
          />
        </ReferenceManyField>
      </Collapse>
    </StyledCard>
  );
};
