import { styled } from '@mui/material/styles';

import { appColors } from '../../../../../../themes/variables';
import { IconButton } from '../../../../../mui/IconButtons';

export const IconButtonStyled = styled(IconButton)({
  height: '40px',
  width: '40px',
  backgroundColor: appColors.primary.main,
  '&:hover': {
    backgroundColor: appColors.primary.main,
  },
});
