import { styled } from '@mui/material/styles';
import { Pagination } from 'react-admin';
import { DatagridWithStickyActions } from 'shared/react-admin/Datagrids/DatagridWithStickyActions/DatagridWithStickyActions';
import { getColumnStyle } from 'utils';

const commonStyle = getColumnStyle();

const columnStyle = {
  width: '100%',
  maxWidth: '240px',
  minWidth: '240px',
  textWrap: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const StyledDatagrid = styled(DatagridWithStickyActions)({
  '& .column-id': getColumnStyle(80),
  '& .column-name': columnStyle,
  '& .column-createdAt': commonStyle,
  '& .column-updatedAt': commonStyle,
  '& .RaDatagrid-rowCell': { padding: '16px' },
});

export const StyledPagination = styled(Pagination)({
  display: 'block',
  marginBottom: '24px',
});
